import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';

import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import MainSidebar from '../components/layout/MainSidebar/MainSidebar';
import MainFooter from '../components/layout/MainFooter';

const NoSidebarLayout = ({ children, noNavbar, noFooter }) => (
    <Container fluid>
        <Row>
            <Col
                className="main-content p-0 main-content p-0"
                xl={{ size: 10, offset: 1 }}
                lg={{ size: 12, offset: 0 }}
                md={{ size: 12, offset: 0 }}
                // lg={{ size: 10, offset: 2 }}
                // md={{ size: 9, offset: 3 }}
                sm="12"
                tag="main"
            >
                {!noNavbar && <MainNavbar />}
                {children}
                {!noFooter && <MainFooter />}
            </Col>
        </Row>
    </Container>
);

NoSidebarLayout.propTypes = {
    children: PropTypes.node,
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool,
};

NoSidebarLayout.defaultProps = {
    noNavbar: true,
    noFooter: true,
};

export default NoSidebarLayout;
