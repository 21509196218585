import React, { ReactChild, RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import { Button, Card, CardHeader, CardBody, Col, Container, Row } from 'shards-react';
import styled from 'styled-components';
import PageTitle from '../components/common/PageTitle';
import { Cell, Dataset, User, Wsi } from './../models/BackendModels';
import DatasetSelect from '../components/wsi/DatasetSelect';
import config from './../config';
import { auth_fetch, checkSuccess } from '../utils/ajax';
import WsiSelect, {WsiCarousel} from '../components/wsi/WsiSelect';
import WsiDisplay from '../components/wsi/WsiDisplay';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
    ClassificationModelInference,
    LoadingIndicator,
    ModelInference,
    ObjectDetectionInference,
    retrieveClassificationModels,
    retrieveModels,
} from './SegmentationAnnotation';
import './AnalysisDemo02.css';
import { ChromaStatic } from 'chroma-js';
import distinctColors from 'distinct-colors';
import PageMap from 'react-pagemap';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { UserContext } from 'context/UserContext';
import { useDraggable } from "react-use-draggable-scroll";
import ScrollContainer from 'react-indiana-drag-scroll'

const PIXEL_DIAMETER_IN_MIKROMETER_DEFAULT = 0.0680272108843537;

const retrieveAllWsi = (): Promise<Wsi[]> => auth_fetch(`${config.backend}/api/wsi/`).then(checkSuccess);
const retrieveDatasetWsi = (): Promise<Wsi[]> =>
    auth_fetch(`${config.backend}/api/dataset_wsi/999/`).then(checkSuccess);

const retrieveCellsFromSlices = (wsi: Wsi, cellSlices: number[][]) =>
    auth_fetch(`${config.backend}/api/cells_from_slices/`, {
        method: 'POST',
        body: JSON.stringify({
            cellSlices: cellSlices,
            wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not create cell images: ${error}`));

const detectionInference = (wsi: Wsi, detectionModelName: string) =>
    auth_fetch(`${config.backend}/api/model_inference/`, {
        method: 'POST',
        body: JSON.stringify({
            modelName: detectionModelName,
            targetShape: 'rect', //TODO unused => remove
            wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not detect cells: ${error}`));

const classificationInference = (wsi: Wsi, cellSlices: number[][], classificationModelName: string) =>
    auth_fetch(`${config.backend}/api/classification_inference/`, {
        method: 'POST',
        body: JSON.stringify({
            modelName: classificationModelName,
            cellSlices: cellSlices,
            wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not do classification: ${error}`));

const saveCellSlices = (user: User, wsi: Wsi, cellSlices: CellSlice[]) =>
    localStorage.setItem(`cell_slices_${wsi.id}`, JSON.stringify(cellSlices));

/*     auth_fetch(`${config.backend}/api/cell_slices/${wsi.id}/${user.id}/`, {
        method: 'PUT',
        body: JSON.stringify(cellSlices),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not create cell images: ${error}`)); */

const loadCellSlices = (user: User, wsi: Wsi): Promise<CellSlice[]> => {
    if (wsi !== undefined) {
        const cellSlices = localStorage.getItem(`cell_slices_${wsi.id}`);
        if (cellSlices !== null) {
            return Promise.resolve(JSON.parse(cellSlices));
        }
    }
    return Promise.resolve([]);
}
/*     auth_fetch(`${config.backend}/api/cell_slices/${wsi.id}/${user.id}/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .then((cellSlices) =>
            cellSlices.map((s: any) => {
                s.bbox = JSON.parse(s.bbox);
                return s;
            }),
        )
        .catch((error) => console.log(`Could not create cell images: ${error}`)); */

const saveCheckedLabelGroups = (user: User, wsi: Wsi, checkedLabelGroups: number[]) => {
    localStorage.setItem(`checked_labelgroups_${wsi.id}`, JSON.stringify(checkedLabelGroups));
}

const loadCheckedLabelGroups = (user: User, wsi?: Wsi): number[] => {
    if (wsi !== undefined) {
        const labelGroups = localStorage.getItem(`checked_labelgroups_${wsi.id}`);
        if (labelGroups !== null) {
            return JSON.parse(labelGroups);
        }
    }
    return [];
}

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
} as React.CSSProperties;

const Success = styled.span`
    color: green;
`;

const Failed = styled.span`
    color: red;
`;

const Pending = styled.span`
    color: yellow;
`;

const DropzoneArea = styled.p`
    padding: 3rem;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 8px;
`;

type PreviewFile = File & { preview: string };
type UploadResponseStatus = { success: boolean; message: string };

interface FullSizedImageSvgOverlayProps {
    src: string;
    containerRef: RefObject<HTMLDivElement>;
    onLoad?: () => void;
    cellSlices: CellSlice[];
    visibleIndices: Map<number, boolean>;
}

export const FullSizedImageSvgOverlay: React.FC<FullSizedImageSvgOverlayProps> = ({
    src,
    containerRef,
    onLoad,
    cellSlices,
    visibleIndices,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const innerRef = useRef<HTMLImageElement>(null);
    const { events } = useDraggable(containerRef as any, {
        isMounted: loaded, 
      }); // Now we pass the reference to the useDraggable hook:
 
    useEffect(() => {
        if (typeof innerRef !== 'undefined' && innerRef.current !== null && loaded) {
            setHeight(innerRef.current.clientHeight);
            setWidth(innerRef.current.clientWidth);
        }
    }, [innerRef, loaded]);

    const ellipsesSlices = useMemo(
        () =>
            cellSlices.reduce((ellipses: Record<number, number[]>, slice) => {
                ellipses[slice.idx] = bbox2ellipse(slice.bbox);
                return ellipses;
            }, {}),
        [cellSlices],
    );

    return (
        <div className="full-size-img-container styled-scrollbars" ref={containerRef} {...events}>
            <img
                ref={innerRef}
                className="full-size-img"
                src={src}
                onLoad={() => {
                    setLoaded(true);
                    if (typeof onLoad !== 'undefined') {
                        onLoad();
                    }
                }}
            ></img>
            <svg width={width} height={height}>
                {cellSlices.map((slice) => {
                    const e = ellipsesSlices[slice.idx];
                    const cellColor = typeof slice.label !== 'undefined' ? labels[slice.label].color : '#000';
                    const strokeOpacity = visibleIndices.has(slice.idx) ? 1 : 0;
                    return (
                        <ellipse
                            key={slice.idx}
                            id={`cell-ellipse-${slice.idx}`}
                            onClick={() => {
                                const slicesViewClosed = document.querySelector('.cell-label-correction')?.classList.contains('toggle-cell-label-corrections');
                                if(!slicesViewClosed) {
                                    document.querySelector(`#cell-slice-${slice.idx}`)?.scrollIntoView();
                                }
                            }}
                            className="cell-slice"
                            strokeWidth={3}
                            cx={e[0]}
                            cy={e[1]}
                            rx={e[2]}
                            ry={e[3]}
                            stroke={cellColor}
                            strokeOpacity={strokeOpacity}
                            fillOpacity={0}
                            data-tip
                            data-for={slice.idx}
                        ></ellipse>
                    );
                })}
            </svg>
            <div className="cell-tooltips">
                {cellSlices.map((slice) => {
                    const e = ellipsesSlices[slice.idx];
                    return (
                        <ReactTooltip
                            key={slice.idx}
                            id={String(slice.idx)}
                            aria-haspopup="true"
                            disable={!visibleIndices.has(slice.idx)}
                        >
                            <div className="cell-tooltip__head">Cell {slice.idx + 1}</div>
                            <div className="cell-tooltip__data">
                                <span>Label:</span>
                                <span>
                                    {typeof slice.label !== 'undefined' ? labels[slice?.label].name : 'unlabeled'}
                                </span>
                                <span>Diameter A:</span>
                                <span>{(2 * e[2]).toFixed(2)}&#181;m</span>
                                <span>Diameter B:</span>
                                <span>{(2 * e[3]).toFixed(2)}&#181;m</span>
                                <span>Perimeter:</span>
                                <span>
                                    {(
                                        Math.PI *
                                        (3 * (e[2] + e[3]) - Math.sqrt((3 * e[2] + e[3]) * (e[2] + 3 * e[3])))
                                    ).toFixed(2)}
                                    &#181;m
                                </span>
                                <span>Area:</span>
                                <span>
                                    {(e[2] * e[3] * Math.PI * PIXEL_DIAMETER_IN_MIKROMETER_DEFAULT).toFixed(2)}&#181;m²
                                </span>
                            </div>
                        </ReactTooltip>
                    );
                })}
            </div>
        </div>
    );
};

export const bbox2rect = (bbox: number[]) => [bbox[0], bbox[1], bbox[2] - bbox[0], bbox[3] - bbox[1]];

export const bbox2ellipse = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const x_radius = (bbox[3] - bbox[1]) / 2;
    const y_radius = (bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, y_radius, x_radius];
};

export const bbox2maxcircle = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const radius = Math.max(bbox[3] - bbox[1], bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, radius];
};

export const bbox2mincircle = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const radius = Math.min(bbox[3] - bbox[1], bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, radius];
};

export type CellSlice = {
    idx: number;
    bbox: number[];
    label?: number;
    imageName?: string;
    userCorrected?: boolean;
};

// type CellSlices = {
//     slices: CellSlices[],
//     shape: string
// };
enum CellCorrectionArea {
    LEFT = 'cell-drop-area-left',
    RIGHT = 'cell-drop-area-right',
}

const labelNames = [
    'Band n. gran.',
    'Basophil',
    'Blast',
    'Eosinophil',
    'Erythroblast',
    'Immature lymph.',
    'Lymphocyte',
    'Metamyelocyte',
    'Monocyte',
    'Myelocyte',
    'Plasma cell',
    'Promyelocyte',
    'Segmented n. gran.',
];

const colors = distinctColors({ count: labelNames.length });
const labels = labelNames.map((l, idx) => {
    return { name: l, color: colors[idx].hex() };
});

const cellLabelDropdownOptions = labelNames.map((l, idx) => {
    return { label: l, value: String(idx) };
});

const emptyCellSlices = [[]];
for (let i = 0; i < labelNames.length; i++) {
    emptyCellSlices[i] = [];
}

enum DraggingState {
    NOT_STARTED,
    STARTED,
    ENTERED_TARGET, //, DROPPED
}

type CellLabelArea = {
    ref: React.RefObject<HTMLDivElement>;
    label: number;
};

type CellCorrectionDraggingState = {
    draggedCellIdx?: number;
    draggingState: DraggingState;
    targetLabelArea?: CellLabelArea;
    targetLabelIdx?: number;
};

const DEFAULT_DRAGGING_STATE = { draggingState: DraggingState.NOT_STARTED };

const AnalysisDemo02: React.FC = () => {
    const { user } = useContext(UserContext);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [analyzed, setAnalyzed] = useState<boolean>(false);
    const [files, setFiles] = useState<Array<PreviewFile>>([]);
    const [currentWsi, setCurrentWsi] = useState<Wsi>();
    const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [cells, setCells] = useState<Cell[]>([]);
    const [cellImages, setCellImages] = useState<string[]>([]);
    const [displayShape, setDisplayShape] = useState<string>('ellipse');
    const [cellSlices, setCellSlices] = useState<CellSlice[]>([]);
    const [userCellSlices, setUserCellSlices] = useState<CellSlice[]>([]);
    const [availableClassificationInferenceOptions, setAvailableClassificationInferenceOptions] = useState([]);
    const [availableModelInferenceOptions, setAvailableModelInferenceOptions] = useState([]);
    const [uploadStatus, setUploadStatus] = useState<Record<string, UploadResponseStatus>>({});
    const [cellImagesToShow, setCellImagesToShow] = useState<number>(999);
    const [selectedCellLabel, setSelectedCellLabel] = useState<number>(-1);
    const [selectedCellLabelLeftSide, setSelectedCellLabelLeftSide] = useState<number>(-1);
    const [selectedCellLabelRightSide, setSelectedCellLabelRightSide] = useState<number>(-1);
    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);
    const [slideImageLoaded, setSlideImageLoaded] = useState<boolean>(false);
    const [cellCorrectionDraggingState, setCellCorrectionDraggingState] =
        useState<CellCorrectionDraggingState>(DEFAULT_DRAGGING_STATE);
    const dropEnterExitCount = useRef<number>(0);
    const cellDropAreaLeft = useRef<HTMLDivElement>(null);
    const cellDropAreaRight = useRef<HTMLDivElement>(null);
    const cellDragArea = useRef<HTMLDivElement>(null);
    const cellDropArea = useRef<HTMLDivElement>(null);
    
    const [checkedLabelGroups, setCheckedLabelGroups] = useState<number[]>([]);

    useEffect(() => {
        setCheckedLabelGroups(loadCheckedLabelGroups(user, currentWsi));
    }, [user, currentWsi]);

    useEffect(() => {
        if (typeof currentWsi !== 'undefined') {
            saveCheckedLabelGroups(user, currentWsi, checkedLabelGroups);
        }
    }, [user, currentWsi, checkedLabelGroups]);

    const cellLabelAreaLeft = useMemo(() => {
        return { ref: cellDropAreaLeft, label: selectedCellLabelLeftSide };
    }, [cellDropAreaLeft, selectedCellLabelLeftSide]);
    const cellLabelAreaRight = useMemo(() => {
        return { ref: cellDropAreaRight, label: selectedCellLabelRightSide };
    }, [cellDropAreaRight, selectedCellLabelRightSide]);

    const slideImageRef = useRef<HTMLImageElement>(null);
    const cellTableRef = useRef<HTMLDivElement>(null);
  

    const labelCounts = useMemo(
        () =>
            cellSlices.reduce((counts: Record<number, number>, slice) => {
                if (typeof slice.label !== 'undefined') {
                    if (typeof counts[slice.label] === 'undefined') {
                        counts[slice.label] = 0;
                    }
                    counts[slice.label]++;
                }
                return counts;
            }, {}),
        [cellSlices],
    );

    const cellSlicesByLabel = useMemo(
        () =>
            cellSlices.reduce((slicesByLabels: Map<number, number[]>, slice) => {
                if (!slicesByLabels.has(slice.label!)) {
                    slicesByLabels.set(slice.label!, []);
                }
                slicesByLabels.set(slice.label!, [...slicesByLabels.get(slice.label!)!, slice.idx]);
                return slicesByLabels;
            }, new Map<number, number[]>()),
        [cellSlices],
    );

    const cellSlicesIndicesOfSelectedLabel = useMemo(
        () =>
            cellSlices.reduce((indices: Map<number, boolean>, slice) => {
                if (selectedCellLabel === -1 || slice.label === selectedCellLabel) {
                    indices.set(slice.idx, true);
                }
                return indices;
            }, new Map<number, boolean>()),
        [cellSlices, selectedCellLabel],
    );

    // const cellSlicesOfSelectedLabel = useMemo(
    //     () => cellSlices.filter((slice, idx) => cellSlicesIndicesOfSelectedLabel.has(slice.idx)),
    //     [cellSlices, selectedCellLabel],
    // );

    const cellSlicesOfLeftSide = useMemo(
        () =>
            cellSlices.filter((slice) => selectedCellLabelLeftSide === -1 || slice.label === selectedCellLabelLeftSide),
        [cellSlices, selectedCellLabelLeftSide],
    );

    // Hide right side until cell type is selected
    const cellSlicesOfRightSide = useMemo(
        () => cellSlices.filter((slice) => slice.label === selectedCellLabelRightSide),
        [cellSlices, selectedCellLabelRightSide],
    );

    // const paginatedCellSlices = useMemo(
    //     () => cellSlicesOfSelectedLabel.slice(0, cellImagesToShow).slice(0, cellImagesToShow),
    //     [cellSlicesOfSelectedLabel],
    // );

    const DEMO_DATASET = 999;

    const onDrop = (acceptedFiles: Array<File>) => {
        setFiles(
            acceptedFiles.map((file: File) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        );

        const promises: Promise<Record<string, any>>[] = [];
        acceptedFiles.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', new Blob([file]));
            promises.push(
                auth_fetch(`${config.backend}/upload/demo/${DEMO_DATASET}/${file.name}`, {
                    method: 'PUT',
                    // headers: { 'Content-Type': 'application/json' },
                    body: formData,
                })
                    .then((response: Response) => response.json())
                    .then((json) => {
                        return { [file.name]: json };
                    }),
            );
        });
        Promise.all(promises).then((values) => {
            const uploadStatus: Record<string, UploadResponseStatus> = {};
            values.forEach((perFileJson) => {
                Object.keys(perFileJson).forEach((filename) => {
                    const json = perFileJson[filename];
                    if (typeof json.error !== 'undefined') {
                        uploadStatus[filename] = { success: false, message: json.error };
                    } else {
                        uploadStatus[filename] = { success: true, message: json.success };
                    }
                });
            });
            setUploadStatus(uploadStatus);
        });

        // POST to a test endpoint for demo purposes
        // const req = request.post('https://httpbin.org/post');
        // files.forEach(file => {
        //   req.attach(file.name, file);
        // });
        // req.end();
    };
    const uploadStatusMessage = (status?: UploadResponseStatus) => {
        if (typeof status !== 'undefined') {
            if (status.success) {
                return <Success>Success</Success>;
            } else if (typeof status.message !== 'undefined') {
                return <Failed>Failed ({status.message})</Failed>;
            }
        }
        return <Pending>Pending</Pending>;
    };

    const thumbs = files.map((file: PreviewFile) => (
        <li key={file.name}>
            Upload of {file.name}&nbsp;
            {uploadStatusMessage(uploadStatus[file.name])}
        </li>
    ));

    useEffect(() => {
        // setLoaded(false);
        retrieveDatasetWsi().then(async (wsi: Wsi[]) => {
            setAvailableWsi(wsi);
            if (wsi.length > 0) {
                setCurrentWsi(wsi[wsi.length - 1]);
            }
        });
    }, [uploadStatus]);

    useEffect(() => {
        if (typeof currentWsi !== 'undefined') {
            /* setLoaded(true); */
            loadCellSlices(user, currentWsi).then(setUserCellSlices);
            setLoadingIndicatorActive(false);
        }
    }, [currentWsi]);

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file: PreviewFile) => URL.revokeObjectURL(file.preview));
        },
        [files],
    );
    useEffect(() => {
        retrieveModels()
            .then(checkSuccess)
            .then((models) => {
                const available = models.map((element: string /*idx: number*/) => {
                    return { value: element, label: element };
                });
                setAvailableModelInferenceOptions(available);
            })
            .catch((/*error*/) => console.log('No existing models, yet'));
    }, []);

    useEffect(() => {
        retrieveClassificationModels()
            .then(checkSuccess)
            .then((models) => {
                const available = models.map((element: string /*idx: number*/) => {
                    return { value: element, label: element };
                });
                setAvailableClassificationInferenceOptions(available);
            })
            .catch((/*error*/) => console.log('No existing models, yet'));
    }, []);

    // useEffect(() => {
    //     if(cellSlices.length > 0) {
    //         addShapes(displayShape, cellSlices);
    //     }

    // }, [cellSlices, displayShape])

    // useEffect(() => {
    //     console.log(cellImages);
    // }, [cellImages]);

    const addCircles = (cellSlices: CellSlice[]) => {
        const svgContainer = document.querySelector('#wsi-svg-container');
    };
    const addRectangles = (cellSlices: CellSlice[]) => {
        const svgns = 'http://www.w3.org/2000/svg';
        // TODO pass svg container
        const svgContainer = document.querySelector('.full-size-img-container svg');
        // make a simple rectangle
        for (const slice of cellSlices) {
            const e = bbox2rect(slice.bbox);
            const newRect = document.createElementNS(svgns, 'rect');
            newRect.setAttribute('x', String(e[0]));
            newRect.setAttribute('y', String(e[1]));
            newRect.setAttribute('width', String(e[2]));
            newRect.setAttribute('height', String(e[3]));
            const cellColor = typeof slice.label !== 'undefined' ? labels[slice.label].color : '#000';
            newRect.setAttribute('stroke', cellColor);
            newRect.setAttribute('fill-opacity', '0');
            svgContainer?.appendChild(newRect);
        }
    };

    const addEllipses = (cellSlices: CellSlice[]) => {
        const svgns = 'http://www.w3.org/2000/svg';
        // TODO pass svg container
        const svgContainer = document.querySelector('.full-size-img-container svg');
        // make a simple rectangle
        for (const slice of cellSlices) {
            const e = bbox2ellipse(slice.bbox);
            const newRect = document.createElementNS(svgns, 'ellipse');
            newRect.setAttribute('cx', String(e[0]));
            newRect.setAttribute('cy', String(e[1]));
            newRect.setAttribute('rx', String(e[2]));
            newRect.setAttribute('ry', String(e[3]));
            const cellColor = typeof slice.label !== 'undefined' ? labels[slice.label].color : '#000';
            newRect.setAttribute('stroke', cellColor);
            newRect.setAttribute('fill-opacity', '0');
            svgContainer?.appendChild(newRect);
        }
    };

    // const handleSegmentationResult = (elements: Array<Array<number>>) => {
    //     // setDisplayShape(shape);
    //     setCellSlices(
    //         elements.map((bbox, idx) => {
    //             return { idx: idx, bbox };
    //         }),
    //     );
    // };

    const addShapes = (shape: string, cellSlices: CellSlice[]) => {
        // TODO Add SVG to JSX instead of manipulating DOM
        if (shape === 'min_circle' || shape === 'max_circle') {
            addCircles(cellSlices);
        } else if (shape === 'ellipse') {
            addEllipses(cellSlices);
        } else if (shape === 'rect') {
            addRectangles(cellSlices);
        }
    };

    // const classificationHandler = (labels: number[]) => {
    //     const labeledSlices = cellSlices.map((slice, idx) => {
    //         slice.label = labels[idx];
    //         return slice;
    //     });
    //     setCellSlices(labeledSlices);

    //     // console.log(data);
    // };

    // const handleCellImages = (wsi: Wsi, cellSlices: CellSlice[]) => {
    //     retrieveCellsFromSlices(wsi, cellSlices).then(
    //         (imageNames: string[]) => imageNames.map(n => `${config.backend}/static/wsi_backend/cache/cells/${n}`)
    //         ).then(setCellImages);
    // }

    const fullSlideAnalysis = async (wsi: Wsi) => {
        const detectionModelName = 'segmentation_model_2020_11_28.pth';
        const classificationModelName = 'cell_classification_model_2023_05_02.pth';
        setInProgress(true);
        setLoadingIndicatorActive(true);
        const cellBoundingBoxes = await trackPromise(detectionInference(wsi, detectionModelName));
        const cellImageUrls = await trackPromise(retrieveCellsFromSlices(wsi, cellBoundingBoxes));
        const labeledCellSlices = await trackPromise(
            classificationInference(wsi, cellBoundingBoxes, classificationModelName).then((labels: number[]) => {
                return cellBoundingBoxes.map((bbox: number[], idx: number) => {
                    const cellSlice: CellSlice = { idx: idx, bbox, label: labels[idx], imageName: cellImageUrls[idx] };
                    return cellSlice;
                });
            }),
        );
        saveCellSlices(user, wsi, labeledCellSlices);
        setCellSlices(labeledCellSlices);
        setInProgress(false);
        setLoadingIndicatorActive(false);
    };

    const activateLoader = () => {
        const loader = document.getElementById('loader');
        if (loader) {
            loader.style.display = "block";
        }
        const demoAnalysis = document.getElementById('demo-analysis');
        if (demoAnalysis) {
            demoAnalysis.style.pointerEvents = "none";
        }
    }

    const deactivateLoader = () => {
        const loader = document.getElementById('loader');
        if (loader) {
            loader.style.display = "none";
        }
        const demoAnalysis = document.getElementById('demo-analysis');
        if (demoAnalysis) {
            demoAnalysis.style.pointerEvents = "auto";
        }
    }

    useEffect(() => {
        if(!loadingIndicatorActive) {
            deactivateLoader();
        } else if (!inProgress) {
            setLoadingIndicatorActive(false);
        }
    });

    const loadSlideAnalysis = () => {
        if (typeof userCellSlices !== 'undefined' && userCellSlices.length > 0) {
            activateLoader();
            setTimeout(() => {
                setCellSlices(userCellSlices);
                setLoadingIndicatorActive(false);
            }, 100);
        }
    };

        const switchWsi = (wsi: Wsi) => {
    /*      Massive speedup, but at the same time kills the react app
            const svgContainer = document.querySelector('.full-size-img-container svg');
            if (svgContainer !== null) {
                svgContainer.innerHTML = '';
            } */
            /* setLoaded(false); */
            activateLoader()
            setTimeout(() => {
                setSlideImageLoaded(false);
                setCellSlices([]);
                setCurrentWsi(wsi);
                setLoadingIndicatorActive(false);
            }, 100);
        };

    const setSelectedCellLabels = (idx: number) => {
        const label = idx === selectedCellLabel ? -1 : idx;
        setSelectedCellLabel(label);
        if (selectedCellLabelLeftSide === -1) {
            setSelectedCellLabelLeftSide(label);
        }
    };

    const handleDragEnter = (e: any, labelIdx: number) => {
        dropEnterExitCount.current++;
        setCellCorrectionDraggingState({
            ...cellCorrectionDraggingState,
            targetLabelArea: e.target,
            targetLabelIdx: labelIdx,
            draggingState: DraggingState.ENTERED_TARGET,
        });
    };

    const handleDragLeave = (e: any, labelIdx: number) => {
        dropEnterExitCount.current--;
        if (cellCorrectionDraggingState.targetLabelArea === e.target && dropEnterExitCount.current === 0) { 
            setCellCorrectionDraggingState({ ...cellCorrectionDraggingState, 
                targetLabelArea: undefined, 
                targetLabelIdx: undefined,
                draggingState: DraggingState.STARTED });
        } 
    };

    const getDropPosition = (e: any) => {
        // Works, but currently order of cell slices isn't implemented
        let dropPosition = -1;
        if (e.target.children.length === 0) {
            dropPosition = 0;
        }
        if (e.target.children.length > 0) {
            // let rows = [{fromY: 0, toY: Infinity}];
            let targetRow = { fromY: 0, toY: Infinity };
            let childrenInRow = [{ idx: 0, node: e.target.children[0] }];
            // let itemsPerRow = 1;
            // let position = -1;
            let rowFound = false;
            // Let's find the index position of the drop. e.target is cellDropArea
            let i = 1;
            while (!rowFound && i < e.target.children.length) {
                const childRect = e.target.children[i].getBoundingClientRect();
                const previousRect = e.target.children[i - 1].getBoundingClientRect();

                if (childRect.y > previousRect.y) {
                    // We are in a new row
                    // Set bottom of previous row and top of current row to the middle between both rows,
                    // because boundingClientRect does not account for margin
                    targetRow.toY = previousRect.bottom + (childRect.y - previousRect.bottom) / 2;
                    if (e.clientY > targetRow.fromY && e.clientY < targetRow.toY) {
                        rowFound = true;
                    } else {
                        // Clear children in row for children in new row
                        childrenInRow = [];
                        targetRow = { fromY: targetRow.toY, toY: Infinity };
                    }
                }
                if (!rowFound) {
                    childrenInRow.push({ idx: i, node: e.target.children[i] });
                }
                i++;
                // if(rows.length === 1) {
                //     itemsPerRow++;
                // }
                // previousRect = childRect;
            }
            for (const child of childrenInRow) {
                const childRect = child.node.getBoundingClientRect();
                if (e.clientX < childRect.x) {
                    dropPosition = child.idx;
                    break;
                }
            }
            if (dropPosition === -1) {
                // If image wasn't dropped before any element in the row, it's the last element in this row
                dropPosition = childrenInRow[childrenInRow.length - 1].idx + 1;
            }
        }
        console.log(`Dropped at index ${dropPosition}`);
        return dropPosition;
    };

    const handleDrop = (e: any, labelIdx: number) => {
        activateLoader();
        setLoadingIndicatorActive(true);
        setTimeout(() => {
            if (e.target && typeof currentWsi !== 'undefined') {
                // let dropPosition = getDropPosition(e);
                const updatedSlices = cellSlices.map((c) => {
                    if (c.idx === cellCorrectionDraggingState.draggedCellIdx) {
                        c.label = labelIdx; 
                        c.userCorrected = true;
                    }
                    return c;
                });
                saveCellSlices(user, currentWsi, updatedSlices); 
                setCellSlices(updatedSlices);
            }
            setCellCorrectionDraggingState(DEFAULT_DRAGGING_STATE);
        }, 100);
    };

    const handleDragStart = (e: any, draggedCellIdx: number) => {
        setCellCorrectionDraggingState({
            /* srcCellArea: cellLabelAreaLeft,
            targetCellArea: cellLabelAreaRight, */
            draggedCellIdx: draggedCellIdx,
            draggingState: DraggingState.STARTED,
        });
    };

    const handleDragEnd = (e: any) => {
        setCellCorrectionDraggingState({ draggingState: DraggingState.NOT_STARTED });
    };
    // const dropdownChangeLeftSide = (idx: number) => {
    //     const cellLabelLeftSide = idx;
    //     setSelectedCellLabelLeftSide(cellLabelLeftSide);
    //     if(cellSlicesOfLeftSide.length === 0) {
    //         let t = cellSlicesOfLeftSide;
    //         t[idx] =
    //         setCellSlicesOfLeftSide(cellSlices.filter((slice) => cellLabelLeftSide === -1 || slice.label === cellLabelLeftSide))
    //     }
    // }

    // const dropdownChangeRightSide = (idx: number) => {
    //     const cellLabelRightSide = idx;
    //     setSelectedCellLabelRightSide(cellLabelRightSide);
    //     if(cellSlicesOfRightSide.length === 0) {
    //         setCellSlicesOfRightSide(cellSlices.filter((slice) => cellLabelRightSide === -1 || slice.label === cellLabelRightSide))
    //     }
    // }

    // const cellSlicesOfLeftSide = useMemo(
    //     () => cellSlices.filter((slice) => selectedCellLabelLeftSide === -1 || slice.label === selectedCellLabelLeftSide),
    //     [cellSlices, selectedCellLabelLeftSide],
    // );

    // const cellSlicesOfRightSide = useMemo(
    //     () => cellSlices.filter((slice) => selectedCellLabelRightSide === -1 || slice.label === selectedCellLabelRightSide),
    //     [cellSlices, selectedCellLabelRightSide],
    // );
    const cellTableWidth = cellTableRef.current?.clientWidth;
    const getCellDropAreaBackgroundColor = (labelIdx: number) => {
        if (
            cellCorrectionDraggingState.draggingState === DraggingState.NOT_STARTED 
/*             ||
            // cellCorrectionDraggingState.draggingState === DraggingState.DROPPED ||
            self !== cellCorrectionDraggingState.targetCellArea?.ref */
        ) {
            return 'transparent';
        } else if (cellCorrectionDraggingState.draggingState === DraggingState.STARTED) {
            return 'yellow';
        } else if (cellCorrectionDraggingState.draggingState === DraggingState.ENTERED_TARGET
            && labelIdx === cellCorrectionDraggingState.targetLabelIdx) {
            return 'green';
        }
        return 'transparent';
/*         throw new Error('Invalid cellCorrectionDraggingState'); */
    };

    const renderEmptyItem = () => {
    
    }

    return (
        <div id="demo-analysis" className="demo-analysis" style={ !loadingIndicatorActive ? {} : {pointerEvents: 'none'}}>
                <div className="center-helper-top" style={{justifyContent: 'center'}}>
                    <div id='wsi-carousel-thumbs' className='animated-toggle wsi-carousel-thumbs' >
                        {availableWsi.map((wsi) => (
                            <img key={wsi.id} src={wsi.webUrl} 
                                onClick={switchWsi.bind(null, wsi)}
                                style={{border: wsi.id === currentWsi?.id ? "solid 1px red" : "solid 1px black"}}></img>
                        ))}
                    </div>
                        <div id="loader" className="loader" style={{ zIndex: 999, 
                            position: 'absolute',
                            left: '45vw',
                            top: '40vh',
                            display: loadingIndicatorActive ?  'block' : 'none'}}></div>
                    {/* <LoadingIndicator active={loadingIndicatorActive} /> */}
                </div>
{/*                 <WsiCarousel
                    availableWsi={availableWsi}
                    selectedWsi={currentWsi}
                    onChange={switchWsi}
                    useNameAsLabel={true}
                     renderThumbs={(children: ReactChild[]) => {
                        console.log('renderThumbs');
                        return [(<></>)];
                    }} 
                /> */}
                {typeof currentWsi !== 'undefined' && (
                    <>
                        <FullSizedImageSvgOverlay
                            containerRef={slideImageRef}
                            cellSlices={cellSlices}
                            visibleIndices={cellSlicesIndicesOfSelectedLabel}
                            src={currentWsi.webUrl}
                            onLoad={() => setSlideImageLoaded(true)}
                        />
                        <div id='wsi-pagemap-container' className='animated-toggle'>
                            {slideImageLoaded && typeof cellTableWidth !== 'undefined' && (
                                <PageMap
                                    id="wsi-pagemap"
                                    viewport={slideImageRef.current}
                                    styles={{
                                        container: {
                                            position: 'absolute',
                                            top: 0,
                                            left: 0, //"28px",
                                            // position: 'relativeve',
                                            // top: '8px',
                                            // right: '8px',
                                            //width: `${cellTableWidth}px`,
                                            //height: `${cellTableWidth * 4}px`, // set height >>> width,
                                            // because internally pagemap resizes canvas based on minimum dimension
                                            // to keep aspect ratio
                                            backgroundImage: `url("${currentWsi.webUrl}")`,
                                            backgroundSize: 'contain',
                                            outline: '12px solid rgba(0,0,0,0.5)',
                                            transition: 'all 0.5s ease-in-out',
                                            // zIndex: '100'
                                        },
                                    }}
                                    view="rgba(255,0,0,0.4)"
                                    viewStroke="rgba(255,0,0,0.6)"
                                    dragStroke="rgba(255,0,0,0.9)"
                                />
                            )}
                        </div>
                        <div id='cell-label-correction' className='cell-label-correction animated-toggle toggle-cell-label-corrections'>
                            {cellSlicesByLabel.size > 0 && (
                                <div className='cell-slices styled-scrollbars' style={{width: '25vw', overflowY: 'scroll'}} ref={cellDragArea}>
                                    {Array.from(cellSlicesByLabel.keys()).sort((a, b) => a - b).map((label: number) => (
                                        <div key={label}
                                            style={{
                                                marginTop: '1rem',
                                                border: '1px solid #ddd',
                                                borderRadius: '5px',
                                                padding: '0.5rem',
                                        }}>
                                            <h4 id={`slices-labelheader-${label}`} style={{marginBottom: "0.25rem"}}>{labelNames[label]}</h4>
                                            <div
                                                key={label}
                                                style={{
                                                    display: 'flex',
                                                    flexFlow: 'row',
                                                    flexWrap: 'wrap',
                                                }}>
                                                {cellSlicesByLabel.get(label)?.map((idx: number) => (
                                                    <div key={idx} style={{maxWidth: '18%', margin: '2.5%', 
                                                        display: 'flex', flexFlow: 'column', alignItems: 'center',
                                                        border: cellSlices[idx].userCorrected ? '4px solid #999' : 'none'}}>
                                                        <img
                                                            id={`cell-slice-${idx}`}
                                                            draggable="true"
                                                            onDragStart={(e) => handleDragStart(e, idx)}
                                                            onDragEnd={handleDragEnd}
                                                            onClick={() => {
                                                                document.querySelector(`#cell-ellipse-${idx}`)?.scrollIntoView();
                                                            }}
                                                            style={{
                                                                height: 'auto',
                                                                flexBasis: 'auto',
                                                                border: cellSlices[idx].userCorrected ? '1px solid #ddd' : 'none',
                                                                opacity:
                                                                idx === cellCorrectionDraggingState.draggedCellIdx
                                                                        ? 0
                                                                        : 1,
                                                            }}
                                                            src={`${config.backend}/static/wsi_backend/cache/cells/${cellSlices[idx].imageName}`}
                                                            />
                                                        {cellSlices[idx].userCorrected && (
                                                            <span style={{fontSize: '0.8rem'}}>User corrected</span>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{    
                                                display: 'flex',
                                                padding: '0 3rem 1rem 0',
                                                justifyContent: 'flex-end',
                                            }}>
                                                <Button style={ checkedLabelGroups.indexOf(label) >= 0 ? 
                                                    { background: 'green', border: 'green'} : 
                                                    { background: 'gray', border: 'gray'}
                                                }
                                                    onClick={() => setCheckedLabelGroups(
                                                        checkedLabelGroups.indexOf(label) >= 0 ? checkedLabelGroups.filter((l) => l !== label) : [...checkedLabelGroups, label])}
                                                >
                                                    <i className='fa fa-check'></i>
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div style={{ width: '25vw'}}>
                                <div ref={cellTableRef} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: '700' }}>Cell type</div>
                                    <div style={{ fontWeight: '700' }}>Count</div>
                                </div>
                                <div >
                                    <div style={{ display: 'flex', flexFlow: 'column', rowGap: '0.5rem'}}>
                                        {labels.length > 0 &&
                                            labels.map((l, idx) => (
                                                <div
                                                key={idx}
                                                onDragEnter={(e) => handleDragEnter(e, idx)}
                                                onDragOver={(e) => { 
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDragLeave={(e) => handleDragLeave(e, idx)}
                                                onDrop={(e) => handleDrop(e, idx)}
                                                style={{
                                                    display: 'flex',
                                                    padding: '0.5rem 0.4rem',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: getCellDropAreaBackgroundColor(idx),
                                                    cursor: 'pointer',
                                                    border: selectedCellLabel === idx ? '1px solid #000' : 'none',
                                                }}
                                                onClick={() => {
                                                    const headerElement = document.querySelector(`#slices-labelheader-${idx}`);
                                                    headerElement?.scrollIntoView();
/*                                                     const cellSlicesElement = document.querySelector('.cell-slices');
                                                    if (cellSlicesElement) {
                                                        if (headerElement) {
                                                            cellSlicesElement.scrollTo({ top: headerElement.getBoundingClientRect().top, behavior: 'smooth' });
                                                        }
                                                    } */
                                                    setSelectedCellLabels(idx);
                                                }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: l.color,
                                                                width: '1.2rem',
                                                                height: '0.5rem',
                                                                borderRadius: '2px',
                                                                marginRight: '0.5rem',
                                                            }}
                                                            >
                                                            &nbsp;
                                                        </div>
                                                        <div>{l.name}</div>
                                                    </div>
                                                    <div>
                                                        {typeof labelCounts[idx] !== 'undefined' ? labelCounts[idx] : 0}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {cellSlicesIndicesOfSelectedLabel.size === 0 ? (
                            <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row',
                                columnGap: '1rem',
                                justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '2rem',
                                    position: 'absolute',
                                    top: '80vh',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    style={{ fontSize: '1.25rem', width: '15%' }}
                                    theme="primary"
                                    onClick={() => fullSlideAnalysis(currentWsi)}
                                >
                                    Start analysis
                                </Button>
                                <Button
                                    style={{ fontSize: '1.25rem', width: '15%' }}
                                    theme="primary"
                                    disabled={!(typeof userCellSlices !== 'undefined' && userCellSlices.length > 0)}
                                    onClick={loadSlideAnalysis}
                                >
                                    Load previous analysis
                                </Button>
                            </div>
                        ) : (
                            <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row',
                                columnGap: '1rem',
                                justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '2rem',
                                    position: 'absolute',
                                    top: '80vh',
                                    width: '100%'
                                }}
                            >
                            <Button
                                style={{ fontSize: '1.25rem', width: '15%' }}
                                theme="primary"
                                onClick={() => switchWsi(currentWsi)}
                            >
                                Reset analysis
                                </Button>
                            </div>
                        )}
                    </>
                )}
        </div>
    );
};

export default AnalysisDemo02;
