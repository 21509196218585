import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarSearch from './SidebarSearch';
import SidebarNavItems from './SidebarNavItems';

import { Store } from '../../../flux';

class MainSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuVisible: false,
            sidebarNavItems: Store.getSidebarItems(),
        };

        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        Store.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onChange);
    }

    onChange() {
        this.setState({
            ...this.state,
            menuVisible: Store.getMenuState(),
            sidebarNavItems: Store.getSidebarItems(),
        });
    }
    /* 
  changeAdminUrl() {
      document.getElementsByClassName("Admin")[0].href= "http://localhost:8000/admin";
  }

  componentDidMount() {
      this.changeAdminUrl()
  }
 */

    render() {
        const classes = classNames('main-sidebar', 'px-0', 'col-8', this.state.menuVisible && 'open');

        return (
            <Col tag="aside" className={classes} xl={{ size: 2 }} lg={{ size: 4 }} md={{ size: 5 }}>
                <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
                <SidebarSearch />
                <SidebarNavItems />
            </Col>
        );
    }
}

MainSidebar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
    hideLogoText: false,
};

export default MainSidebar;
