import React, { ChangeEvent, FormEvent, ReactText, useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, CardFooter, Col, Container, Row, FormCheckbox } from 'shards-react';
import styled from 'styled-components';
import PageTitle from '../components/common/PageTitle';
import { Dataset, Wsi, ViaAnnotation } from '../models/BackendModels';
import DatasetSelect from './../components/wsi/DatasetSelect';
import WsiSelect, { retrieveWsi } from './../components/wsi/WsiSelect';
import config from './../config';
import { UserContext } from './../context/UserContext';
import { checkSuccess } from './../utils/ajax';
import styles from './Common.module.css';
import { saveViaProjectToBackend } from './../components/wsi/ViaProjects';

const Form = styled.form`
    display: grid;
    grid:
        [start] 'col' 'col' 'col' 1fr [end]
        / 1fr 1fr 1fr;
    justify-items: end;
`;

const Input = styled.input`
    margin-left: 15px;
`;

// Required to prevent typescript bug on options property?
const StyledSelect = styled(Select)`
    display: inline-block;
    width: 30%;
`;

class UserImportOption {
    public label = 'User';
    public value = '0';
    public model?: ViaAnnotation;

    constructor(annotation?: ViaAnnotation) {
        if (annotation) {
            this.label = annotation.annotatorName;
            this.value = String(annotation.annotator);
            this.model = annotation;
        }
    }
}

interface LoadingIndicatorProps {
    active: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ active }: LoadingIndicatorProps) => {
    const { promiseInProgress } = usePromiseTracker();

    return active && promiseInProgress ? <Loader type="ThreeDots" color="#2BAD60" height={100} width={100} /> : null;
};

const onlyUniqueUsers = (annotation: ViaAnnotation, index: number, self: Array<any>) => {
    return self.findIndex((el: ViaAnnotation) => el.annotator === annotation.annotator) === index;
};

const retrieveViaProjects = () =>
    fetch(`${config.backend}/api/via/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

const defaultImportOption = new UserImportOption();

const Utility: React.FC = () => {
    // const [expertise, setExpertise] = useState(2);
    const { user } = useContext(UserContext);
    const [viaAnnotations, setViaAnnotations] = useState<ViaAnnotation[]>([]);
    const [selectedOption, setSelectedOption] = useState<UserImportOption>(defaultImportOption);
    const [overwriteAnnotations, setOverwriteAnnotations] = useState(false);
    const [messages, setMessages] = useState<string[]>([]);

    const importHandler = (annotations: ViaAnnotation[]) => {
        const imports: Array<Promise<any>> = [];
        annotations.forEach((annotation: ViaAnnotation) => {
            imports[annotation.wsi] = saveViaProjectToBackend(
                user.id,
                annotation.wsi,
                JSON.parse(annotation.viaProject),
                overwriteAnnotations,
            );
        });
        const newMessages = imports.map((promise, wsi) => `Import annotation for WSI ${wsi}`);
        setMessages(newMessages);
        Promise.all(imports)
            .then(() => setMessages([...newMessages, 'Import sucessful']))
            .catch((err) => setMessages([...newMessages, `Error ${err}`]));
    };

    const notCurrentUser = (annotation: ViaAnnotation) => annotation.annotator !== user.id;

    const viaAnnotationsOfSelectedUser = viaAnnotations.filter(
        (annotation: ViaAnnotation) => annotation.annotator === parseInt(selectedOption.value),
    );

    useEffect(() => {
        const initImportOptions = async () => {
            const annotationProjects = await (await retrieveViaProjects()).json();
            setViaAnnotations(annotationProjects);
        };
        initImportOptions();
    }, []);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Utility functions"
                    subtitle="Slide images"
                    md="12"
                    className="ml-sm-auto mr-sm-auto"
                />
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">
                                Import annotations from user
                                <FormCheckbox
                                    inline
                                    className={styles.checkboxInCardHeader}
                                    checked={overwriteAnnotations}
                                    onChange={() => setOverwriteAnnotations(!overwriteAnnotations)}
                                >
                                    Overwrite my annotations
                                </FormCheckbox>
                            </h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <StyledSelect
                                placeholder="Select user"
                                isSearchable={true}
                                options={viaAnnotations
                                    .filter(onlyUniqueUsers)
                                    .filter(notCurrentUser)
                                    .map((annotation: ViaAnnotation) => new UserImportOption(annotation))}
                                value={selectedOption}
                                onChange={setSelectedOption}
                            ></StyledSelect>
                            <Button
                                theme="primary"
                                disabled={selectedOption === defaultImportOption}
                                onClick={
                                    (/*e: FormEvent<HTMLFormElement>*/) => importHandler(viaAnnotationsOfSelectedUser)
                                }
                            >
                                Import projects from user
                            </Button>
                        </CardBody>
                        <CardFooter>
                            {messages.map((msg: string, idx: number) => (
                                <div key={idx}>{msg}</div>
                            ))}
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Utility;
