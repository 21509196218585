import React from 'react';
import { FormCheckbox } from 'shards-react';
import Select from 'react-select';
import styled from 'styled-components';

export interface Option {
    label: string;
    value: string;
}

class CheckboxProps {
    value: any;
    label = '';
    className?: string;
    inline?: boolean = true;
}

interface OneOrNoneCheckboxGroupProps {
    checkboxesProps: CheckboxProps[];
    selectedValue: any;
    setSelectedValue: React.Dispatch<React.SetStateAction<any>>;
}

export const OneOrNoneCheckboxGroup: React.FC<OneOrNoneCheckboxGroupProps> = ({
    checkboxesProps,
    selectedValue,
    setSelectedValue,
}: OneOrNoneCheckboxGroupProps) => {
    return (
        <>
            {checkboxesProps.map((checkbox) => (
                <FormCheckbox
                    key={checkbox.value}
                    inline={checkbox.inline}
                    className={checkbox.className}
                    checked={selectedValue === checkbox.value}
                    onChange={() => setSelectedValue(selectedValue === checkbox.value ? null : checkbox.value)}
                >
                    {checkbox.label}
                </FormCheckbox>
            ))}
        </>
    );
};

const StyledSelect = styled(Select)`
    width: 60%;
    display: inline-block;
`;

export class Clause<T> {
    public negated: boolean;
    private _literals: T[];

    constructor(literals: T[] = []) {
        this.negated = false;
        this._literals = literals;
    }

    or(v: T): this {
        this._literals.push(v);
        return this;
    }

    setNegated(negated = false): this {
        this.negated = negated;
        return this;
    }

    negate(): this {
        this.negated = !this.negated;
        return this;
    }

    literals() {
        return this._literals;
    }
}

export class Cnf<T> {
    private _clauses: Clause<T>[];

    constructor(positiveClauses: Array<Array<T>> = []) {
        this._clauses = [];
        for (const clause of positiveClauses) {
            this.and(new Clause(clause));
        }
    }

    and(clause: Clause<T>): this {
        this._clauses.push(clause);
        return this;
    }

    clauses(): Clause<T>[] {
        return this._clauses;
    }

    clone(): Cnf<T> {
        const c = new Cnf<T>();
        this.clauses().map(c.and.bind(c));
        return c;
    }
}

interface ConjunctionOptions {
    and: Option;
    andNot: Option;
}

const AndOption = {
    value: '1',
    label: 'are',
};

const AndNotOption = {
    value: '0',
    label: 'are not',
};

const defaultConjunctionOptions = {
    and: AndOption,
    andNot: AndNotOption,
};

interface DynamicCnfSelectGroupProps<T> {
    cnf: Cnf<T>;
    updateClause: (clause: Clause<T>, option: Option) => void;
    text: string;
    conjunctionPrefixText: string;
    conjunctionOptions?: ConjunctionOptions;
    // checkboxesProps: CheckboxProps[];
    // selectedValue: any;
    // setSelectedValue: React.Dispatch<React.SetStateAction<any>>;
}

// const ConjunctionOptions: Option[] =

export const DynamicCnfSelectGroup: React.FC<DynamicCnfSelectGroupProps<Option>> = ({
    cnf,
    updateClause,
    text,
    conjunctionPrefixText,
    conjunctionOptions = defaultConjunctionOptions,
}: // checkboxesProps,
// selectedValue,
// setSelectedValue,
DynamicCnfSelectGroupProps<Option>) => {
    return (
        <>
            {cnf.clauses().map((clause, idx) => (
                <span key={idx}>
                    {idx > 0 ? conjunctionPrefixText : ''}
                    <StyledSelect
                        value={clause.negated ? conjunctionOptions.andNot : conjunctionOptions.and}
                        onChange={updateClause.bind(null, clause)}
                        isSearchable={false}
                        options={[conjunctionOptions.and, conjunctionOptions.andNot]}
                    />
                    {text}
                </span>
            ))}
        </>
    );
};

{
    /* <StyledSelect
    isMulti
    onChange={(option: Option[]) => {
        console.log(option);
    }}
    placeholder="Choose user"
    isSearchable={false}
    options={[...usersWithAnnotationsOptions]}
/>;

{
    checkboxesProps.map((checkbox) => (
        <FormCheckbox
            key={checkbox.value}
            inline={checkbox.inline}
            className={checkbox.className}
            checked={selectedValue === checkbox.value}
            onChange={() => setSelectedValue(selectedValue === checkbox.value ? null : checkbox.value)}
        >
            {checkbox.label}
        </FormCheckbox>
    ));
} */
}
