import React, { useState, useContext, ReactChild } from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput,
    FormRadio,
    Row,
    Alert,
} from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { UserContext } from '../context/UserContext';
import { HTMLElementEvent } from '../@types/HTMLElementEvent';
import { PayloadError } from '../utils/ajax';
// import { requestUser, retrieveUser } from './../components/user-profile-lite/CookieLogin';
//<SingleCellAnnotation />

// class LandingPage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             username: '',
//             selectedExpertise: 1,
//         };
//         requestUser();
//     }

//     submit(data) {
//         if (data.length) {
//             this.updateUser(data[0], this.state.selectedExpertise);
//         } else {
//             this.createUser(this.state.username, this.state.selectedExpertise);
//         }
//     }

// const buildErrorMessages = (error: PayloadError): Promise<any> => {
//     return error.payload.then((p) => {
//         let messages: Record<string, ReactChild> = {};
//         for(const key of )
//         if(typeof p["password"] !== 'undefined' && p["password"].length) {
//             alerts["password"] = (<Alert theme="danger">
//                 Error: {p["password"][0]}
//             </Alert>)
//         }
//         if(typeof p["username"] !== 'undefined' && p["username"].length) {
//             alerts["username"] = (<Alert theme="danger">
//                 Error: {p["username"][0]}
//             </Alert>)
//         }
//         if(typeof p["non_field_errors"] !== 'undefined' && p["non_field_errors"].length) {
//             alerts["non_field_errors"] =
//         }
//         return alerts;
//     });
// }

const errorAlert = (errors: Record<string, string[]>, key: string) => {
    return typeof errors[key] !== 'undefined' && errors[key].length ? (
        <Alert theme="danger">Error: {errors[key][0]}</Alert>
    ) : (
        ''
    );
};

const LandingPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [expertise, setExpertise] = useState(2);
    const { login } = useContext(UserContext);
    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Annotation platform" subtitle="Digital pathology" className="text-sm-left mb-3" />
            </Row>

            <Row>
                <Col lg="3" md="12" sm="12" className="mb-4">
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={(e: Event) => {
                                    e.preventDefault();
                                    login(username, password).catch((e: PayloadError) => e.payload.then(setErrors));
                                    //     (e) => {
                                    //     setError(e);
                                    //     let pl = await e.payload;
                                    //     console.log(pl);
                                    //     debugger;
                                    // })
                                    // .then((req) => {
                                    //     debugger;
                                    //     console.log(req);
                                    // })
                                    // .catch((e) => {
                                    //     debugger;
                                    //     setError(e);
                                    // })
                                }}
                            >
                                {errorAlert(errors, 'non_field_errors')}
                                <FormGroup>
                                    <label htmlFor="#username">Username</label>
                                    <FormInput
                                        id="#username"
                                        value={username}
                                        onChange={(e: HTMLElementEvent<HTMLInputElement>) =>
                                            setUsername(e.target.value)
                                        }
                                        placeholder="Username"
                                    />
                                    {errorAlert(errors, 'username')}
                                    <label htmlFor="#password">Password</label>
                                    <FormInput
                                        id="#password"
                                        value={password}
                                        type="password"
                                        onChange={(e: HTMLElementEvent<HTMLInputElement>) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder="Password"
                                    />
                                    {errorAlert(errors, 'password')}
                                </FormGroup>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button type="submit">Los</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

{
    /* <label htmlFor="#expertise">Skill level</label>
<div>
    <FormRadio
        name="expertise"
        checked={expertise === 1}
        onChange={() => setExpertise(1)}
    >
        Novice
    </FormRadio>
    <FormRadio
        name="expertise"
        checked={expertise === 2}
        onChange={() => setExpertise(2)}
    >
        Intermediate
    </FormRadio>
    <FormRadio
        name="expertise"
        checked={expertise === 3}
        onChange={() => setExpertise(3)}
    >
        Expert
    </FormRadio>
</div> */
}

LandingPage.propTypes = {};

LandingPage.defaultProps = {};

export default LandingPage;
