import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Dataset, ExportGroup, Wsi } from '../../models/BackendModels';
import config from './../../config';
import { checkSuccess, auth_fetch } from './../../utils/ajax';

interface ExportGroupSelectProps {
    className?: string;
    availableExportGroups?: ExportGroup[];
    dataset?: Dataset;
    selectedExportGroup?: ExportGroup;
    onChange: (selectedExportGroup: ExportGroup) => void;
    placeholder?: string;
}

const newExportGroup = new ExportGroup();

class ExportGroupOption {
    public label = '';
    public value = '';
    public model?: ExportGroup;

    constructor(exportGroup?: ExportGroup) {
        if (!exportGroup) {
            exportGroup = newExportGroup;
        }
        this.label = exportGroup.name;
        this.value = String(exportGroup.id);
        this.model = exportGroup;
    }
}
const StyledSelect = styled(Select)``;

// interface ExportGroupOption {
//     label: string;
//     value: number;
//     selected: boolean;
//     pixelDiameterInMicrometer: number;
//     datasetId: number;
// }

const newExportGroupOption = new ExportGroupOption();

const retrieveExportGroup = (pk: number): Promise<ExportGroup> =>
    auth_fetch(`${config.backend}/api/export_group/${pk}/`).then(checkSuccess);

const retrieveExportGroups = (): Promise<ExportGroup[]> =>
    auth_fetch(`${config.backend}/api/export_groups/`).then(checkSuccess);

const retrieveExportGroupWsi = (pk: number) =>
    auth_fetch(`${config.backend}/api/export_group_wsi/${pk}/`).then(checkSuccess);

const ExportGroupSelect: React.FC<ExportGroupSelectProps> = ({
    className,
    availableExportGroups,
    selectedExportGroup,
    onChange,
    placeholder,
}: ExportGroupSelectProps) => {
    // const [selectedOption, setSelectedOption] = useState(defaultDatasetOption);
    // const [ExportGroupOptions, setExportGroupOptions] = useState<ExportGroupOption[]>([]);
    const [exportGroupOptions, setExportGroupOptions] = useState<ExportGroupOption[]>([]);

    useEffect(() => {
        if (typeof availableExportGroups === 'undefined') {
            retrieveExportGroups().then((data: ExportGroup[]) =>
                setExportGroupOptions(data.map((exportGroup) => new ExportGroupOption(exportGroup))),
            );
        } else {
            setExportGroupOptions(availableExportGroups.map((exportGroup) => new ExportGroupOption(exportGroup)));
        }

        // setAvailableExportGroup(
        // data.map((ExportGroup: ExportGroup) => {
        //     return {
        //         label: ExportGroup.imgName, value: ExportGroup.id, selected: false,
        //         pixelDiameterInMicrometer: ExportGroup.pixelDiameterInMicrometer, datasetId: ExportGroup.datasetId
        //     };
        // })));
    }, [availableExportGroups]);
    const selectedOption = exportGroupOptions.find((o) => o.value === String(selectedExportGroup?.id));
    return (
        <StyledSelect
            className={className}
            placeholder={placeholder}
            isSearchable={true}
            options={exportGroupOptions}
            value={selectedOption ? selectedOption : newExportGroupOption}
            onChange={(o: ExportGroupOption) => (o.model ? onChange(o.model) : null)}
        ></StyledSelect>
    );
};

// {
/* <StyledSelect
value={selectedOption ? selectedOption : defaultExportGroupOption}
placeholder="ExportGroup name"
isSearchable={true}
onChange={(o: ExportGroupOption) => onChange({
id: o.value, imgName: o.label,
pixelDiameterInMicrometer: o.pixelDiameterInMicrometer, datasetId: o.datasetId
})}
options={ExportGroupOptions}
/> */
// }
export default ExportGroupSelect;
export { retrieveExportGroup, retrieveExportGroups, retrieveExportGroupWsi };
