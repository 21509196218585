import React, { ChangeEvent, FormEvent, ReactText, useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, FormCheckbox } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Video, Wsi, ViaAnnotation } from '../models/BackendModels';
import VideoSelect from './../components/wsi/VideoSelect';
import config from './../config';
import { UserContext } from './../context/UserContext';
import { checkSuccess } from './../utils/ajax';
import styled from 'styled-components';
import styles from './Common.module.css';
import { saveViaVideoProjectToBackend } from './../components/wsi/ViaProjects';
import { Option } from './../components/common/FormInputs';
import { filterDistinct } from './../utils/util';
const Form = styled.form`
    display: grid;
    grid:
        [start] 'col' 'col' 'col' 1fr [end]
        / 1fr 1fr 1fr;
    justify-items: end;
`;

const Input = styled.input`
    margin-left: 15px;
`;

interface LoadingIndicatorProps {
    active: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ active }: LoadingIndicatorProps) => {
    const { promiseInProgress } = usePromiseTracker();

    return active && promiseInProgress ? <Loader type="ThreeDots" color="#2BAD60" height={100} width={100} /> : null;
};

// const a = [{ id: 1 }, { id: 1 }, { id: 3 }];
// const b = [{ idObj: { id: 1 } }, { idObj: { id: 1 } }, { idObj: { id: 3 } }];
// const c = [1, 1, 3]

// let t = filterDistinct(a, (a, b) => a.id === b.id);
// t = filterDistinct(b, (a, b) => a.idObj.id === b.idObj.id);
// t = filterDistinct(c);

const backendUrl = (video?: Video) => (typeof video !== 'undefined' ? `${video.webUrl}` : undefined);

interface CircleFormProps {
    circleHandler: (data: Array<Array<number>>) => void;
    video?: Video;
}

const CircleForm: React.FC<CircleFormProps> = ({ circleHandler, video }: CircleFormProps) => {
    const [inputs, setInputs] = useState<Record<string, ReactText>>({
        pixelDiameterInMicrometer: 0,
        kernelDim: 5,
        pixelMinCellRadius: 1.5,
        pixelMaxCellRadius: 6.6,
        pixelMinCellCenterDist: 4.5,
        dilateIterations: 1,
        houghCirclesDp: 3.2,
        denoiseH: 20,
        denoiseTemplateWindow: 7,
        denoiseSearchWindow: 21,
    });

    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);

    return (
        <Card>
            <CardHeader className="border-bottom">
                <h6 className="m-0">
                    {' '}
                    <Button type="submit" theme="primary">
                        New circles
                    </Button>
                </h6>
                <div className="block-handle" />
            </CardHeader>

            <CardBody className="border-top">
                <Form>
                    {Object.keys(inputs).map((key) => (
                        <label key={key}>
                            {key}
                            <Input
                                name={key}
                                type="number"
                                disabled={key === 'pixelDiameterInMicrometer' ? true : false}
                                value={inputs[key]}
                            />
                        </label>
                    ))}
                    <LoadingIndicator active={loadingIndicatorActive} />
                </Form>
            </CardBody>
        </Card>
    );
};

const StyledSelect = styled(Select)`
    width: 60%;
    display: inline-block;
`;

interface UserProjectOption {
    label: string;
    value: number;
}

interface ViaProjectImportProps {
    video?: Video;
    importHandler: (userIdToImportFrom: number) => void;
    availableUserProjectOptions: Array<UserProjectOption>;
}

const defaultUserProjectOption = {
    value: 0,
    label: 'Choose user to import project from',
};

const retrieveViaProjects = (videoId?: number) =>
    fetch(`${config.backend}/api/video/via/` + (videoId ? `${videoId}/` : ``), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    });

const ViaProjectImport: React.FC<ViaProjectImportProps> = ({
    /*wsi,*/
    importHandler,
    availableUserProjectOptions,
}: ViaProjectImportProps) => {
    const { user } = useContext(UserContext);

    // const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [selectedUserProjectOption, setSelectedUserProjectOption] = useState(defaultUserProjectOption);

    useEffect(() => {
        const selectCurrentUserOption = availableUserProjectOptions.find(
            (option: UserProjectOption) => option.value === user.id,
        );
        setSelectedUserProjectOption(selectCurrentUserOption ? selectCurrentUserOption : defaultUserProjectOption);
    }, [user, availableUserProjectOptions]);

    return (
        <div style={{ width: '70%' }}>
            <StyledSelect
                value={selectedUserProjectOption}
                onChange={(option: UserProjectOption) => setSelectedUserProjectOption(option as UserProjectOption)}
                placeholder="Choose user to import project from"
                isSearchable={false}
                options={availableUserProjectOptions}
            />
            <Button
                theme="primary"
                onClick={(/*e: FormEvent<HTMLFormElement>*/) => importHandler(selectedUserProjectOption.value)}
            >
                Import project from user
            </Button>
        </div>
    );
};

const ViaFrame = styled.iframe`
    width: 100%;
    height: 2000px;
`;

const VideoDemo1: React.FC = () => {
    const { user } = useContext(UserContext);
    const [currentVideo, setCurrentVideo] = useState<Video | undefined>();
    const [allViaAnnotations, setAllViaAnnotations] = useState<ViaAnnotation[]>([]);

    const viaFrameRef = useRef<HTMLIFrameElement>(null);
    const [viaFrameHasLoaded, setViaFrameHasLoaded] = useState(false);
    const [availableModelInferenceOptions, setAvailableModelInferenceOptions] = useState([]);
    const [availableUserProjectOptions, setAvailableUserProjectOptions] = useState([]);
    const [usersWithAnnotationsOptions, setUsersWithAnnotationsOptions] = useState<Set<Option>>(new Set());
    // const [selectedViaProject, setSelectedViaProject] = useState('');

    const updateViaFrame = (userId: number, video?: Video) => {
        if (typeof video !== 'undefined') {
            const payload = {
                action: 'load',
                videoName: backendUrl(video),
                videoId: video.id,
            };
            // sendToViaFrame(payload);
            retrieveViaProject(userId, video)
                .then(checkSuccess)
                .then((data) => sendToViaFrame({ ...payload, viaProject: JSON.parse(data.viaProject) }))
                .catch((/*error*/) => {
                    sendToViaFrame(payload);
                    console.log('No existing annotation');
                });
        }
    };

    const updateViaProjectOptions = (videoId: number) => {
        retrieveViaProjects(videoId)
            .then(checkSuccess)
            .then((projects) => {
                const available = projects.map((element: Record<string, unknown> /*idx: number*/) => {
                    return { value: element.annotator, label: element.annotatorName };
                });
                setAvailableUserProjectOptions(available);
            })
            .catch((/*error*/) => console.log('No existing via projects, yet'));
    };

    useEffect(() => {
        if (viaFrameHasLoaded) {
            updateViaFrame(user.id, currentVideo);
        }

        const handleViaFrameMessages = (event: MessageEvent) => {
            if (/^react-devtools/gi.test(event.data.source)) {
                return;
            }
            if (!event.data.error) {
                if (event.data.action === 'loaded') {
                    setViaFrameHasLoaded(true);
                }
                if (
                    event.data.action === 'saveViaDataStore' &&
                    typeof currentVideo !== 'undefined' &&
                    typeof event.data.viaDataStore !== 'undefined'
                ) {
                    saveViaVideoProjectToBackend(user.id, currentVideo.id, event.data.viaDataStore, true).then(() =>
                        updateViaProjectOptions(currentVideo.id),
                    );
                }
            }
        };

        window.addEventListener('message', handleViaFrameMessages);

        // clean up
        return () => window.removeEventListener('message', handleViaFrameMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, viaFrameRef, viaFrameHasLoaded, currentVideo]);

    useEffect(() => {
        if (typeof currentVideo !== 'undefined') {
            updateViaProjectOptions(currentVideo.id);
        }
    }, [user, currentVideo]);

    const retrieveViaProject = (userId: number, video: Video) =>
        fetch(`${config.backend}/api/video/via/${video.id}/${userId}/`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

    const sendToViaFrame = (payload: Record<string, unknown>) => {
        const contentWindow = viaFrameRef?.current?.contentWindow;
        if (contentWindow) {
            contentWindow.postMessage(payload, '*');
        }
    };

    const startSavingViaDataStore = () => {
        sendToViaFrame({
            action: 'retrieveDataStore', //ViaFrame will emit event with action "saveViaDataStore" and  event.data.viaDataStore
        });
    };

    useEffect(() => {
        const fetchAllViaAnnotations = async () => {
            const allViaAnnotationsPromise = retrieveViaProjects();
            setAllViaAnnotations(await (await allViaAnnotationsPromise).json());
        };
        fetchAllViaAnnotations();
    }, []);

    useEffect(() => {
        const distictUsersAnnotations = [
            ...filterDistinct(allViaAnnotations, (a: ViaAnnotation, b: ViaAnnotation) => a.annotator === b.annotator),
        ];
        const usersWithAnnotationsOptions = new Set<Option>();
        distictUsersAnnotations.forEach((annotation: ViaAnnotation) => {
            usersWithAnnotationsOptions.add({ value: String(annotation.annotator), label: annotation.annotatorName });
        });
        setUsersWithAnnotationsOptions(usersWithAnnotationsOptions);
    }, [allViaAnnotations]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Cell annotation" subtitle="Slide images" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Select video</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <VideoSelect
                                selectedVideo={currentVideo}
                                onChange={(vid) => {
                                    setCurrentVideo(vid);
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {typeof currentVideo !== 'undefined' && (
                <div>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4">
                            <Card>
                                <CardBody>
                                    <ViaProjectImport
                                        availableUserProjectOptions={availableUserProjectOptions}
                                        importHandler={(userId: number) => updateViaFrame(userId, currentVideo)}
                                    ></ViaProjectImport>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4">
                            <Card>
                                <CardBody>
                                    <Button theme="primary" onClick={startSavingViaDataStore}>
                                        Save project in backend
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-4"></Col>
                        <ViaFrame
                            // onLoad={() => setIframeHasLoaded(true)}
                            src={process.env.PUBLIC_URL + 'via_image_annotator_video.html'}
                            ref={viaFrameRef}
                        ></ViaFrame>
                    </Row>
                </div>
            )}
        </Container>
    );
};

// {
/* <FormCheckbox
inline
className={styles.checkboxInCardHeader}
checked={filterAnnotatedWsi === WsiAnnotationFilter.ONLY_ANNOTATED}
onChange={() =>
    setFilterAnnotatedWsi(
        filterAnnotatedWsi === WsiAnnotationFilter.ONLY_ANNOTATED
            ? WsiAnnotationFilter.ALL
            : WsiAnnotationFilter.ONLY_ANNOTATED,
    )
}
>
Show only annotated WSI
</FormCheckbox>
<FormCheckbox
inline
className={styles.checkboxInCardHeader}
checked={filterAnnotatedWsi === WsiAnnotationFilter.ONLY_NON_ANNOTATED}
onChange={() =>
    setFilterAnnotatedWsi(
        filterAnnotatedWsi === WsiAnnotationFilter.ONLY_NON_ANNOTATED
            ? WsiAnnotationFilter.ALL
            : WsiAnnotationFilter.ONLY_NON_ANNOTATED,
    )
}
>
Show only not annotated WSI
</FormCheckbox> */
// }

export default VideoDemo1;
