import config from './../../config';
import { auth_fetch, checkSuccess } from './../../utils/ajax';

export const saveViaProjectToBackend = (
    userId: number,
    wsiId: number,
    viaProject: Record<string, unknown>,
    overwrite = false,
): Promise<any> => {
    return auth_fetch(`${config.backend}/api/via/${wsiId}/${userId}/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            overwrite: overwrite,
            viaProject: JSON.stringify(viaProject),
        }),
    })
        .then(checkSuccess)
        .catch((error) => console.log(error));
};

export const saveViaVideoProjectToBackend = (
    userId: number,
    videoId: number,
    viaProject: Record<string, unknown>,
    overwrite = false,
): Promise<any> => {
    return auth_fetch(`${config.backend}/api/video/via/${videoId}/${userId}/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            overwrite: overwrite,
            viaProject: JSON.stringify(viaProject),
        }),
    })
        .then(checkSuccess)
        .catch((error) => console.log(error));
};

export const saveViaVideoFramesToBackend = (
    userId: number,
    videoId: number,
    viaProject: Record<string, unknown>,
    overwrite = false,
): Promise<any> => {
    return auth_fetch(`${config.backend}/api/videoframes/via/${videoId}/${userId}/`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            overwrite: overwrite,
            viaProject: JSON.stringify(viaProject),
        }),
    })
        .then(checkSuccess)
        .catch((error) => console.log(error));
};
