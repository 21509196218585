// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Common_checkboxInCardHeader__3ChN8 {\n    left: 50px;\n    top: 7px;\n    z-index: 0;\n}", ""]);
// Exports
exports.locals = {
	"checkboxInCardHeader": "Common_checkboxInCardHeader__3ChN8"
};
module.exports = exports;
