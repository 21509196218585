import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';

const MyeloaidPageLayout = ({ children }) => (
    <>
        <div style={{height: "3rem", backgroundColor: "#000", width: "100%"}}><img style={{height: "3rem"}}  src="/logo.webp"></img></div>
        <Container fluid>
            <Row>
                <Col
                    className="main-content p-0"
                    lg={{ size: 10, offset:1 }}
                    sm="12"
                    tag="main"
                >
                    {children}
                </Col>
            </Row>
        </Container>
    </>
);

MyeloaidPageLayout.propTypes = {
    children: PropTypes.node,
};

MyeloaidPageLayout.defaultProps = {};

export default MyeloaidPageLayout;
