import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'reflect-metadata';
import './assets/global.css';
import './flux/constants';
import './flux/dispatcher';
import routes from './routes';
import './shards-dashboard/styles/shards-dashboards.1.1.0.min.css';
import withTracker from './withTracker';
import { UserContextProvider } from './context/UserContext';

// const FluxContext = React.createContext('light');
type RouteProps = Record<string, unknown>;

export const App: React.FC = () => (
    <UserContextProvider>
        <Router basename={process.env.REACT_APP_BASENAME || ''}>
            <div>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={(props: RouteProps) => {
                                return (
                                    <route.layout {...props}>
                                        <route.component {...props} />
                                    </route.layout>
                                );
                            }}
                        />
                    );
                })}
            </div>
        </Router>
    </UserContextProvider>
);
