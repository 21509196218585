import React, { Component, useContext, useEffect, useMemo, useRef, useState } from 'react';
import config from './../config';
import { auth_fetch } from './../utils/ajax';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormCheckbox,
    FormRadio,
} from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import SingleCellDisplay, { fetchLabelGroups } from './../components/wsi/SingleCellDisplay';
import WsiDisplay from './../components/wsi/WsiDisplay';
//import SingleCellAnnotation from "./../components/wsi/SingleCellAnnotation";
import {
    MinifiedLabeledCell,
    Cell,
    Dataset,
    FlattenedLabelSelection,
    LabelSelection,
    LabelGroup,
    LabelSet,
    Label,
    Wsi,
    ExportGroup,
    Consensus,
} from './../models/BackendModels';
import DatasetSelect from './../components/wsi/DatasetSelect';
import WsiSelect from './../components/wsi/WsiSelect';
import { UserContext } from './../context/UserContext';
import { checkSuccess } from './../utils/ajax';
import { intersectUnique, unique } from '../utils/util';
import { type } from 'os';
import ClipLoader from 'react-spinners/ClipLoader';
// import Input from 'react-select/src/components/Input';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CellAnnotationComparison.css';
import ExportGroupSelect, {
    retrieveExportGroup,
    retrieveExportGroups,
    retrieveExportGroupWsi,
} from '../components/wsi/ExportGroupSelect';

type CellId = number;
type AnnotatorId = number;
type LabelId = number;

const SpacedCardBody = styled(CardBody)`
    display: flex;
    gap: 10px;
`;

const CellGrid = styled(`div`)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const CellGridItem = styled(`div`)`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 1rem 4rem;
    @media (max-width: 767px) {
        width: 100%;
    }
    @media (min-width: 768px) {
        width: 15%;
    }
`;

const FilterButton = styled(Button)``;
// interface CellQueryResultItem {
//     id: number;
//     dataset: number;
//     wsi: number;
//     segmentationSet: number;
// }

interface FilterArgs {
    cellIds?: number[];
    segmentationSetId?: number;
    datasetId?: number;
    wsiId?: number;
    labeledBy?: Array<number | null>;
    labelSetId?: number;
    labelGroupIds?: number[];
}

export function retrieveLabelSet(labelSetId: number): Promise<LabelSet> {
    return auth_fetch(`${config.backend}/api/labelset/${labelSetId}/`).then(checkSuccess);
}

export const retrieveDataset = (datasetId: number): Promise<Dataset> =>
    auth_fetch(`${config.backend}/api/dataset/${datasetId}`)
        .then(checkSuccess)
        .then((data) => data[0]);

export const retrieveWsi = (wsiId: number): Promise<Wsi> =>
    auth_fetch(`${config.backend}/api/wsi/${wsiId}`)
        .then(checkSuccess)
        .then((data) => data[0]);

const fetchCellQueryResult = (filterArgs: FilterArgs): Promise<CellId[]> => {
    return auth_fetch(`${config.backend}/api/query_cells/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filterArgs),
    }).then(checkSuccess);
};

const fetchLabeledCellQueryResult = (id: CellId): Promise<Cell> => {
    return auth_fetch(`${config.backend}/api/labeled_cell/${id}`).then(checkSuccess);
};

const fetchLabeledCellListResult = (cellIds: CellId[]): Promise<MinifiedLabeledCell[]> => {
    return auth_fetch(`${config.backend}/api/minified_labeled_cells/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cellIds: cellIds }),
    }).then(checkSuccess);
};

// const fetchCellQueryResultFull = (filterArgs: FilterArgs): Promise<Cell[]> => {
//     return fetchCellQueryResult({...filterArgs, "fullReturn": true}) as Promise<Cell[]>;
// }

// const fetchCellQueryResultIds = (filterArgs: FilterArgs): Promise<CellId[]> => {
//     return fetchCellQueryResult({...filterArgs, "fullReturn": false}) as Promise<CellId[]>;
// }

const fetchCell = (cellId: number): Promise<Cell> => {
    return auth_fetch(`${config.backend}/api/cell/${cellId}/`).then(checkSuccess);
};

const fetchAnnotationCount = (userId: number, segmentationSetId: number): Promise<number> => {
    return auth_fetch(`${config.backend}/api/cell_label_selections_count/${userId}/${segmentationSetId}/`).then(
        checkSuccess,
    );
};

const fetchConsensus = (cellIds: CellId[], annotators: AnnotatorId[]): Promise<Consensus[]> => {
    return auth_fetch(`${config.backend}/api/consensus/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cellIds, annotators }),
    }).then(checkSuccess);
};

interface Option {
    id: number;
    name: string;
}

const VALID_ANNOTATOR_OPTIONS = [
    {
        id: 12,
        name: 'Junker Jack',
    },
    {
        id: 26,
        name: 'Blair Brook',
    },
    {
        id: 29,
        name: 'Casey Carr',
    },
    {
        id: 30,
        name: 'Riley Ray',
    },
];

type DatasetOption = {
    oid: number;
    name: string;
    ids: number[];
};

const DATASET_OPTIONS = [
    {
        oid: 0,
        name: 'AML',
        ids: [1, 2],
    },
    {
        oid: 1,
        name: 'Healthy',
        ids: [3],
    },
];

const VALID_DATASET_OPTIONS = [DATASET_OPTIONS[0], DATASET_OPTIONS[1]];

const VALID_DATASET_IDS = [1, 2, 3];

const FIXED_SUGGESTIONS = [
    {
        id: 13,
        name: 'Cell',
        labelGroup: 8,
    },
    {
        id: 6,
        name: 'Erythrocyte',
        labelGroup: 5,
    },
];

const FIXED_EXPORT_GROUPS = [44, 45];

const toggleSidebar = () => {
    const sidebar = document.querySelectorAll<HTMLElement>('.main-sidebar')[0];
    if (sidebar.style.transform === `translateX(-100%)`) {
        sidebar.style.transform = 'translateX(0)';
    } else {
        sidebar.style.transform = 'translateX(-100%)';
    }
};

const CELL_TYPE_LABEL_GROUP = 5;

const buildLabelsByAnnotator = (
    cell: MinifiedLabeledCell,
    filterOptions: Option[],
    labelFilter: LabelId[],
): Map<AnnotatorId, LabelId[]> => {
    const filterOptionIds = filterOptions.map((o) => o.id);
    // const validLabelSetLabelIds = labelFilter.map(label => label.id);
    const labelsByAnnotator = new Map<AnnotatorId, LabelId[]>();
    for (const annotatorId of filterOptionIds) {
        labelsByAnnotator.set(
            annotatorId,
            unique(
                cell.labelSelections
                    ?.map((selection) =>
                        selection.labelGroupId === CELL_TYPE_LABEL_GROUP && annotatorId === selection.annotatorId
                            ? selection.labels
                            : [],
                    )
                    .reduce((p, c) => p.concat(c), [])
                    .map((label) => label.id)
                    .filter((labelId) => labelFilter.indexOf(labelId) > -1),
            ),
        );
    }
    return labelsByAnnotator;
};

const findCellsOfTypes = (cells: MinifiedLabeledCell[], filterOptions: Option[], labelFilter: LabelId[]) =>
    cells?.filter((c) => {
        const filterOptionIds = filterOptions.map((o) => o.id);
        const labelsByAnnotator = buildLabelsByAnnotator(c, filterOptions, labelFilter);
        const uniqueLabelIds = unique(Array.from(labelsByAnnotator.values()).reduce((p, c) => p.concat(c), []));
        return intersectUnique(uniqueLabelIds, labelFilter).length > 0;
    });

const findAmbigousCellsOfTypes = (
    cells: MinifiedLabeledCell[],
    filterOptions: Option[],
    labelSet: LabelSet,
    labelFilter: LabelId[] = [],
    matchAnyLabel = false,
) =>
    cells?.filter((c) => {
        const filterOptionIds = filterOptions.map((o) => o.id);
        const labelsByAnnotator = buildLabelsByAnnotator(
            c,
            filterOptions,
            labelSet.labels.map((l) => l.id),
        );
        const uniqueLabelIds = unique(Array.from(labelsByAnnotator.values()).reduce((p, c) => p.concat(c), []));
        if (uniqueLabelIds.length <= 1) {
            // All annotators agree on only a single label or no label at all => Discard agreed cell
            return false;
        }
        // Detected disjoint labels, but this might be because of someone using multiple labels for cell type.
        if (matchAnyLabel) {
            // Check if each annotator selected at least one of the filter labels
            let isAmbigous = false;
            for (const labelIds of Array.from(labelsByAnnotator.values())) {
                if (intersectUnique(labelFilter, labelIds).length === 0) {
                    // None of the labels from annotator are inside the filter labels
                    isAmbigous = true;
                }
            }
            if (!isAmbigous) {
                return false;
            }
        } else {
            // Check if all annotator can agree on ONE label
            for (const labelId of uniqueLabelIds) {
                const annotatorsWhoAnnotatedLabelId = filterOptionIds.filter(
                    (annotatorId) =>
                        labelsByAnnotator.has(annotatorId) &&
                        (labelsByAnnotator.get(annotatorId) as number[]).indexOf(labelId) > -1,
                );
                if (annotatorsWhoAnnotatedLabelId.length === filterOptionIds.length) {
                    // Every annotator has used this label => Discard agreed cell
                    return false;
                }
            }
        }
        // If labelFilter is set at least one of the used labels needs to be inside the filter
        return labelFilter.length > 0 ? intersectUnique(uniqueLabelIds, labelFilter).length > 0 : true;
        // Array.from(labelsByAnnotator.values()).filter(annotatorLabelIds => annotatorLabelIds.map());
        // for(const annotatorLabelIds of labelsByAnnotator.values()) {

        // }
        // AlabelsByAnnotator.values().reduce((p,c) => p.concat(c), [])
        // const labels = unique(c.labelSelections?.map(
        //         selection => selection.labelGroupId === CELL_TYPE_LABEL_GROUP && filterOptionIds.indexOf(selection.annotatorId) > -1 ? selection.labels : [])
        //     .reduce((p,c) => p.concat(c), [])
        //     .map(label => label.id));
    });

type Indexable = {
    [key: string]: any;
};

type ArrayIndexable = {
    [key: string]: any;
};

function arrayToMap<Type extends Indexable>(keyProperty: string, arr: Type[]): Map<any, Type> {
    const m = new Map();
    for (const e of arr) {
        m.set(e[keyProperty] as any, e);
    }
    return m;
}

function arrayToIdArray<Type extends ArrayIndexable>(idKeyProperty: string, arr: Type[]): Type[] {
    const m: Type[] = [];
    for (const e of arr) {
        m[e[idKeyProperty]] = e;
    }
    return m;
}

function toMidnight(d: Date) {
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
}

function toEndOfDay(d: Date) {
    d.setMilliseconds(999);
    d.setSeconds(59);
    d.setMinutes(59);
    d.setHours(23);
    return d;
}

const CellAnnotationComparison: React.FC = () => {
    const { user } = useContext(UserContext);
    const [cellsFilter, setCellsFilter] = useState<CellId[]>();
    const [validFilterOptions, setValidFilterOptions] = useState<Set<Option>>(new Set(VALID_ANNOTATOR_OPTIONS));
    const [filterOptions, setFilterOptions] = useState<Option[]>([]); // VALID_ANNOTATOR_OPTIONS[0]
    const [labelFilter, setLabelFilter] = useState<LabelId[]>([]);
    const [datasetFilterOptions, setdatasetFilterOptions] = useState<DatasetOption[]>(VALID_DATASET_OPTIONS);
    const [labelSet, setLabelSet] = useState<LabelSet>();
    const [pageNum, setPageNum] = useState(1);
    const lastElement = useRef<HTMLImageElement>(null);
    const [limit, setLimit] = useState<number>(10);
    // const offset = pageNum * limit;
    // const [labeledBy, setLabeledBy] = useState([]);
    const [suggestions, setSuggestions] = useState<Label[]>(FIXED_SUGGESTIONS);
    const [loaded, setLoaded] = useState(true);
    const [showOnlyAmbiguous, setShowOnlyAmbiguous] = useState(false);
    const [matchAnyLabels, setMatchAnyLabels] = useState(false);
    const [finalLoaded, setFinalLoaded] = useState(false);
    const [selectedCellIndex, setSelectedCellIndex] = useState(0);
    // const [selectedCell, setSelectedCell] = useState<Cell>();
    const [cells, setCells] = useState<MinifiedLabeledCell[]>();
    const [consensus, setConsensus] = useState<Consensus[]>([]);
    const [consensusMask, setConsensusMask] = useState<boolean[]>([]);
    const [annotationCount, setAnnotationCount] = useState<number>();
    const [afterDates, setAfterDates] = useState<Date[]>();
    const [beforeDates, setBeforeDates] = useState<Date[]>();
    const filterOptionsIds = filterOptions.map((o) => o.id);
    const datasetFilterOptionsIds =
        datasetFilterOptions.length > 0
            ? datasetFilterOptions.map((ds) => ds.ids).reduce((pOpt, cOpt) => pOpt.concat(cOpt))
            : [];
    const cellsDatasetFiltered = useMemo(
        () =>
            loaded && typeof cells !== 'undefined' && typeof datasetFilterOptionsIds !== 'undefined'
                ? cells.filter((c) => datasetFilterOptionsIds.indexOf(c.dataset) !== -1)
                : [],
        [loaded, cells, datasetFilterOptionsIds],
    );
    // const cellsDateFiltered = useMemo(() => loaded && typeof cellsDatasetFiltered !== "undefined" && typeof afterDate !== "undefined" && typeof beforeDate !== "undefined" ?
    //     cellsDatasetFiltered.filter(c => new Date(c.lastAnnotationDate) >= afterDate && new Date(c.lastAnnotationDate) <= beforeDate) : [], [loaded, cellsDatasetFiltered, afterDate, beforeDate]);
    const cellsDateFiltered = useMemo(
        () =>
            loaded &&
            typeof cellsDatasetFiltered !== 'undefined' &&
            typeof afterDates !== 'undefined' &&
            typeof beforeDates !== 'undefined'
                ? cellsDatasetFiltered.filter(
                      (c) =>
                          c.labelSelections.filter(
                              (selection) =>
                                  filterOptionsIds.includes(selection.annotatorId) &&
                                  new Date(selection.dateModified) >= afterDates[selection.annotatorId] &&
                                  new Date(selection.dateModified) <= beforeDates[selection.annotatorId] &&
                                  selection.labelGroupId === CELL_TYPE_LABEL_GROUP,
                          ).length === filterOptionsIds.length,
                  )
                : [],
        [loaded, cellsDatasetFiltered, afterDates, beforeDates],
    );
    const cellsOfTypes = useMemo(
        () =>
            loaded && typeof cellsDateFiltered !== 'undefined' && labelFilter.length > 0
                ? findCellsOfTypes(cellsDateFiltered, filterOptions, labelFilter)
                : cellsDateFiltered,
        [cellsDateFiltered, filterOptions, labelFilter, loaded],
    );
    // const ambigousCells = useMemo(() => loaded && typeof cells !== "undefined" && typeof labelSet !== "undefined" ?
    //     findAmbigousCellsOfTypes(cells, filterOptions, cellsAfterDateFiltered) : [], [cells, filterOptions, labelSet, loaded]);
    const ambigousCellsOfTypes = useMemo(
        () =>
            loaded && typeof cellsDateFiltered !== 'undefined' && typeof labelSet !== 'undefined'
                ? findAmbigousCellsOfTypes(cellsDateFiltered, filterOptions, labelSet, labelFilter, matchAnyLabels)
                : [],
        [cellsDateFiltered, labelSet, filterOptions, labelFilter, matchAnyLabels, loaded],
    );
    // const ambigousTypeFilteredCells = useMemo(() => loaded && typeof labelFilter !== "undefined" && labelFilter.length > 0 ? ambigousCells.filter(cell => ) : ambigousCells, [labelFilter, ambigousCells, loaded]);
    const visibleCells = useMemo(
        () =>
            showOnlyAmbiguous
                ? ambigousCellsOfTypes?.slice(0, pageNum * limit)
                : cellsOfTypes?.slice(0, pageNum * limit),
        [ambigousCellsOfTypes, pageNum, limit, showOnlyAmbiguous],
    );
    const validLabelSetLabelIds = useMemo(
        () => (typeof labelSet !== 'undefined' ? labelSet.labels.map((label) => label.id) : []),
        [labelSet],
    );
    const interObserverAgreement = useMemo(
        () =>
            loaded &&
            typeof cellsOfTypes !== 'undefined' &&
            typeof ambigousCellsOfTypes !== 'undefined' &&
            cellsOfTypes.length > 0
                ? `${(100 - (100 * ambigousCellsOfTypes?.length) / cellsOfTypes?.length).toFixed(2)}%`
                : '',
        [cellsOfTypes, ambigousCellsOfTypes, loaded],
    );

    // cell.labelSelections?.map((selection: FlattenedLabelSelection) => (
    //     <div key={selection.id}>{selection.labelGroupId === CELL_TYPE_LABEL_GROUP && selection.labels.length > 0 ?
    // const [me, setMe] = useState<Option>();

    useEffect(() => {
        if (loaded && typeof cells !== 'undefined' && typeof filterOptions !== 'undefined') {
            const filterOptionsIds = filterOptions.map((o) => o.id);
            if (
                typeof afterDates === 'undefined' ||
                filterOptionsIds.filter((id) => typeof afterDates[id] === 'undefined').length > 0
            ) {
                const dates: Date[] = [];
                cells.forEach((c) =>
                    c.labelSelections
                        .filter(
                            (selection) =>
                                filterOptionsIds.includes(selection.annotatorId) &&
                                selection.labelGroupId === CELL_TYPE_LABEL_GROUP,
                        )
                        .forEach((selection) => {
                            const previous =
                                typeof dates[selection.annotatorId] !== 'undefined'
                                    ? dates[selection.annotatorId].getTime()
                                    : new Date().getTime();
                            dates[selection.annotatorId] = new Date(
                                Math.min(new Date(selection.dateModified).getTime(), previous),
                            );
                        }),
                );
                setAfterDates(dates);
            }
            if (
                typeof beforeDates === 'undefined' ||
                filterOptionsIds.filter((id) => typeof beforeDates[id] === 'undefined').length > 0
            ) {
                const dates: Date[] = [];
                cells.forEach((c) =>
                    c.labelSelections
                        .filter(
                            (selection) =>
                                filterOptionsIds.includes(selection.annotatorId) &&
                                selection.labelGroupId === CELL_TYPE_LABEL_GROUP,
                        )
                        .forEach((selection) => {
                            const previous =
                                typeof dates[selection.annotatorId] !== 'undefined'
                                    ? dates[selection.annotatorId].getTime()
                                    : 0;
                            dates[selection.annotatorId] = new Date(
                                Math.max(new Date(selection.dateModified).getTime(), previous),
                            );
                        }),
                );
                setBeforeDates(dates);
                // setBeforeDates(new Date(cells.map(c => new Date(c.lastAnnotationDate).getTime()).reduce((pd, cd) => Math.max(pd, cd), new Date(cells[0].lastAnnotationDate).getTime())));
            }
        }
    }, [loaded, cells, filterOptions]);

    useEffect(() => {
        if (typeof cellsDateFiltered !== 'undefined') {
            setAnnotationCount(cellsDateFiltered.length);
        }
    }, [cellsDateFiltered]);

    useEffect(() => {
        if (typeof filterOptions !== 'undefined' && filterOptions.length > 0) {
            setLoaded(false);
            const cellQueries = [];
            for (const o of filterOptions) {
                cellQueries.push(
                    fetchCellQueryResult({
                        labelSetId: config.labelSet,
                        labeledBy: [o.id],
                        labelGroupIds: [CELL_TYPE_LABEL_GROUP],
                        segmentationSetId: config.latestSegmentationSetId,
                    }),
                );
            }
            Promise.all(cellQueries).then((results) => {
                // let minResult = results[0];
                // for(let result of results) {
                //     if(result.length < minResult.length) {
                //         minResult = result;
                //     }
                // }
                // setAnnotationCount(unique(results.reduce((prev, curr) => prev.concat(curr))).length);
                setCellsFilter(results.reduce(intersectUnique, results[0]).sort());
            });
        }
    }, [filterOptions]);

    useEffect(() => {
        if (filterOptions.length < 2) {
            setShowOnlyAmbiguous(false);
        }
    }, [filterOptions]);

    useEffect(() => {
        if (filterOptions.length < 2 || labelFilter.length < 2) {
            setMatchAnyLabels(false);
        }
    }, [filterOptions, labelFilter]);

    useEffect(() => {
        if (filterOptions.length > 1 && ambigousCellsOfTypes.length > 0) {
            fetchConsensus(
                ambigousCellsOfTypes.map((c) => c.id),
                filterOptions.map((o) => o.id),
            ).then((consensus: Consensus[]) => setConsensus(arrayToIdArray('cell', consensus)));
        }
    }, [ambigousCellsOfTypes, filterOptions]);

    useEffect(() => {
        if (Object.keys(consensus).length > 0 && Object.keys(consensusMask).length === 0) {
            const consensusMask: boolean[] = [];
            for (const c of Object.values(consensus)) {
                consensusMask[c.cell] = false;
            }
            setConsensusMask([...consensusMask]);
        }
    }, [consensus]);

    const callback = (entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting) {
            const newPage = pageNum + 1;
            setPageNum(newPage);
        }
    };
    const observer = useRef(new IntersectionObserver(callback));

    useEffect(() => {
        const options = {
            rootMargin: '0px',
            threshold: 1,
        };

        observer.current = new IntersectionObserver(callback, options);
        if (lastElement.current) {
            observer.current.observe(lastElement.current);
        }
        return () => {
            observer.current.disconnect();
        };
    });

    useEffect(() => {
        if (typeof cellsFilter !== 'undefined') {
            fetchLabeledCellListResult(cellsFilter)
                .then(setCells)
                .then(() => setLoaded(true));
            // let queries = [];
            // for(let i=0; i<cellsFilter.length; i++) {
            //     if(typeof cellsFilter[i] !== "undefined") {
            //         queries.push(fetchLabeledCellQueryResult(cellsFilter[i]));
            //     }
            // }
            // Promise.all(queries).then(setCells);
            // (cells) => {
            //     let validCells = [];
            //     let filterOptionIds = filterOptions.map(o => o.id);
            //     for(const cell of cells) {
            //         // Check that every selected Annotator has actually labeled this cell with a cell type (labelGroup.id === 5)
            //         // TODO This shouldn't be possible any longer, so all cells are valid?
            //         const filterOptionSelections = cell.labelSelections.filter(s => filterOptionIds.indexOf(s.annotator.id) !== -1);
            //         const emptySelection = filterOptionSelections.find(s => s.labels.length === 0 && s.labelGroup.id === CELL_TYPE_LABEL_GROUP);
            //         if(typeof emptySelection !== "undefined") {
            //             debugger;
            //             console.log(`Empty labels for ${emptySelection}`);
            //         } else {
            //             validCells.push(cell);
            //         }
            //     }
            //     setCells(validCells);
            // });
        }
    }, [cellsFilter]);

    useEffect(() => {
        if (typeof user !== 'undefined') {
            const my = VALID_ANNOTATOR_OPTIONS.find((u) => u.id === user.id);
            // setMe({...user, name: "Me"});
            const me = { ...user, name: `Me ${typeof my !== 'undefined' ? '(' + my.name + ')' : ''}` };
            setValidFilterOptions(new Set(VALID_ANNOTATOR_OPTIONS.filter((u) => u.id !== user.id).concat(me)));
        }
    }, [user]);

    useEffect(() => {
        retrieveLabelSet(config.labelSet).then((labelSet: LabelSet) => {
            setLabelSet(labelSet);
            // debugger;
        });
    }, []);

    const toggleFilterOption = (option: Option) => {
        if (loaded) {
            if (option.id === null) {
                if (filterOptions.includes(option)) {
                    setFilterOptions([]);
                } else {
                    setFilterOptions([option]);
                }
            } else {
                if (filterOptions.includes(option)) {
                    setFilterOptions(filterOptions.filter((o) => o.id !== option.id && o.id !== null));
                } else {
                    setFilterOptions(
                        filterOptions
                            .filter((o) => o.id !== null)
                            .concat(option)
                            .sort((a, b) => (a.id === null ? 1 : b.id === null ? -1 : a.id - b.id)),
                    );
                }
            }
        }
    };

    const toggleDatasetOption = (option: DatasetOption) => {
        if (datasetFilterOptions.includes(option)) {
            setdatasetFilterOptions(datasetFilterOptions.filter((o) => o.oid !== option.oid && o.oid !== null));
        } else {
            setdatasetFilterOptions(
                datasetFilterOptions
                    .filter((o) => o.oid !== null)
                    .concat(option)
                    .sort((a, b) => (a.oid === null ? 1 : b.oid === null ? -1 : a.oid - b.oid)),
            );
        }
    };

    const filteredLabelNames =
        typeof labelSet !== 'undefined' && labelFilter.length > 0
            ? labelSet?.labels.filter((label) => labelFilter.indexOf(label.id) > -1).map((label) => label.name + 's')
            : ['Cells'];
    let cellGridTitle = filteredLabelNames.length > 0 ? filteredLabelNames.join(' or ') : 'Cells';
    let correctlyLabeledCells;
    if (filterOptions.length > 0) {
        if (filterOptions.length > 1) {
            cellGridTitle += ' jointly';
            if (showOnlyAmbiguous) cellGridTitle += ' and ambigously';
            if (consensus.length > 0 && ambigousCellsOfTypes.length > 0) {
                correctlyLabeledCells = filterOptions.map((o) => {
                    let correctlyLabeled = 0;
                    for (const c of ambigousCellsOfTypes) {
                        const annotatorSelections = c.labelSelections.filter((sel) => sel.annotatorId === o.id);
                        if (typeof consensus[c.id] !== 'undefined') {
                            const correctLabel = consensus[c.id].label;
                            if (
                                typeof annotatorSelections.find(
                                    (sel) => sel.labels.map((l) => l.id).indexOf(correctLabel) !== -1,
                                ) !== 'undefined'
                            ) {
                                correctlyLabeled += 1;
                            }
                        }
                    }
                    return { ...o, correctlyLabeledCells: correctlyLabeled };
                });
            }
        }
        cellGridTitle += ' labeled by ' + filterOptions.map((o) => o.name).join(' and ');
    }

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col className={`mr-sm-auto text-center text-md-left mb-sm-0 col-10`}>
                    <span className="text-uppercase page-subtitle">Slide images</span>
                    <h3 className="page-title">Cell annotation Comparison</h3>
                </Col>
                <span className={'material-icons d-xl-none'} onClick={toggleSidebar} style={{ fontSize: '3.5rem' }}>
                    list
                </span>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h5 className="m-0">
                                Display cells annotated by: {filterOptions.map((o) => o.name).join(' and ')}
                            </h5>
                            <div className="block-handle" />
                        </CardHeader>
                        <SpacedCardBody className="border-top container-spacing">
                            {[...validFilterOptions].map((o) => (
                                <FilterButton
                                    theme={filterOptions.includes(o) ? 'success' : 'secondary'}
                                    disabled={!loaded}
                                    key={o.id}
                                    onClick={() => toggleFilterOption(o)}
                                >
                                    {o.name}
                                </FilterButton>
                            ))}
                        </SpacedCardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h5 className="m-0">
                                Label filter:{' '}
                                {filteredLabelNames.length > 0 && (
                                    <span>Show cells labeled as {filteredLabelNames.join(' or ')}</span>
                                )}
                            </h5>
                            <FormCheckbox
                                className="mt-3"
                                id="match-any-labels"
                                type="checkbox"
                                checked={matchAnyLabels}
                                disabled={!loaded}
                                onClick={() => setMatchAnyLabels(!matchAnyLabels)}
                            >
                                {(filteredLabelNames.length > 0 ||
                                    filterOptions.length < 2 ||
                                    labelFilter.length < 2) && (
                                    <span>
                                        Consider cells labeled as {filteredLabelNames.join(' or ')} as agreement
                                    </span>
                                )}
                            </FormCheckbox>
                            <div className="block-handle" />
                        </CardHeader>
                        <SpacedCardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {labelSet &&
                                labelSet.labels
                                    .filter((label) => label.labelGroup === CELL_TYPE_LABEL_GROUP)
                                    .map((label) => {
                                        const checked = labelFilter.indexOf(label.id) > -1;
                                        return (
                                            <FilterButton
                                                key={label.id}
                                                disabled={!loaded}
                                                theme={checked ? 'success' : 'secondary'}
                                                onClick={(evt: any) =>
                                                    checked
                                                        ? setLabelFilter(labelFilter.filter((id) => id !== label.id))
                                                        : setLabelFilter([...labelFilter, label.id])
                                                }
                                            >
                                                {label.name}
                                            </FilterButton>
                                        );
                                    })}
                        </SpacedCardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">Selection fine-tuning</CardHeader>
                        <SpacedCardBody style={{ flexFlow: 'column' }}>
                            {loaded && filterOptions.length >= 2 ? (
                                <div>
                                    <FormCheckbox
                                        className="mt-3"
                                        id="show-only-ambigous"
                                        type="checkbox"
                                        checked={showOnlyAmbiguous}
                                        disabled={!loaded || filterOptions.length < 2}
                                        onClick={() => setShowOnlyAmbiguous(!showOnlyAmbiguous)}
                                    >
                                        Show only ambigously labeled cells
                                    </FormCheckbox>
                                </div>
                            ) : (
                                <div>Select at least 2 annotators to show ambigously labeled cells</div>
                            )}
                            {loaded && typeof beforeDates !== 'undefined' && typeof afterDates !== 'undefined' ? (
                                <div>
                                    Show cells from datasets:{' '}
                                    {[...VALID_DATASET_OPTIONS].map((o) => (
                                        <FilterButton
                                            theme={datasetFilterOptions.includes(o) ? 'success' : 'secondary'}
                                            disabled={!loaded}
                                            key={o.oid}
                                            onClick={() => toggleDatasetOption(o)}
                                        >
                                            {o.name}
                                        </FilterButton>
                                    ))}
                                    <br />
                                    <br />
                                    {filterOptions.map((o) => (
                                        <div key={o.id}>
                                            Show only annotations from {o.name} between{' '}
                                            <DatePicker
                                                selected={afterDates[o.id]}
                                                onChange={(date: Date) => {
                                                    afterDates[o.id] = toMidnight(date);
                                                    setAfterDates([...afterDates]);
                                                }}
                                            />{' '}
                                            and{' '}
                                            <DatePicker
                                                selected={beforeDates[o.id]}
                                                onChange={(date: Date) => {
                                                    beforeDates[o.id] = toEndOfDay(date);
                                                    setBeforeDates([...beforeDates]);
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>Select at least 1 annotator to show date and dataset filter</div>
                            )}
                        </SpacedCardBody>
                    </Card>
                </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
                <ClipLoader loading={!loaded} size={150} />
            </div>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h5 className="m-0">
                                Stats for cells labeled by {filterOptions.map((o) => o.name).join(' and ')}
                                {/* {(typeof afterDates !== "undefined" && typeof beforeDates !== "undefined") && ` between ${afterDates.toLocaleDateString()} and ${beforeDates.toLocaleDateString()}`} */}
                            </h5>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            {filterOptions.length > 1 ? (
                                <div>
                                    <div style={{ fontSize: '1.2em' }}>
                                        Total # of jointly labeled cells: {cellsDateFiltered?.length}
                                    </div>
                                    <div style={{ fontSize: '1.2em' }}>
                                        Total # of cells at least one annotator labeled as{' '}
                                        {filteredLabelNames.join(' or ')}: {cellsOfTypes?.length}
                                    </div>
                                    <div style={{ fontSize: '1.2em' }}>
                                        Total # of ambigously labeled {filteredLabelNames.join(' or ')}:{' '}
                                        {ambigousCellsOfTypes?.length}
                                    </div>
                                    <div style={{ fontSize: '1.2em' }}>
                                        → Inter-observer agreement: {interObserverAgreement}
                                    </div>
                                    <br></br>
                                    <div>Consensus performance:</div>
                                    {correctlyLabeledCells?.map((o) => (
                                        <div key={o.id}>
                                            {o.name}:{' '}
                                            {Math.floor(
                                                10000 * (o.correctlyLabeledCells / Object.values(consensus).length),
                                            ) / 100}
                                            %
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div style={{ fontSize: '1.2em' }}>Total # of labeled cells: {annotationCount}</div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h5 className="m-0">{cellGridTitle}</h5>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <CellGrid>
                                {visibleCells?.map((cell, idx) => (
                                    <CellGridItem key={cell.id}>
                                        <img
                                            ref={lastElement}
                                            className="cell-image mb-1"
                                            alt={`cell ${cell.id}`}
                                            src={`${cell.ressourceUrl}`}
                                        />
                                        <div>Nr. {idx + 1}</div>
                                        <div>Cell ID: {cell.id}</div>
                                        {cell.labelSelections
                                            ?.sort(
                                                (a: FlattenedLabelSelection, b: FlattenedLabelSelection) =>
                                                    a.annotatorId - b.annotatorId,
                                            )
                                            .map((selection: FlattenedLabelSelection) => (
                                                <div key={selection.id}>
                                                    {selection.labelGroupId === CELL_TYPE_LABEL_GROUP &&
                                                    selection.labels.filter(
                                                        (label) => validLabelSetLabelIds.indexOf(label.id) > -1,
                                                    ).length > 0 &&
                                                    filterOptions.map((o) => o.id).indexOf(selection.annotatorId) > -1
                                                        ? `${
                                                              VALID_ANNOTATOR_OPTIONS.find(
                                                                  (o) => o.id === selection.annotatorId,
                                                              )?.name
                                                          }: ${selection.labels.map((label) => label.name).join()}`
                                                        : ''}
                                                </div>
                                            ))}
                                        {typeof consensus[cell.id] !== 'undefined' && !consensusMask[cell.id] && (
                                            <div>
                                                Consensus:{' '}
                                                <Button
                                                    onClick={(_: any) => {
                                                        consensusMask[cell.id] = true;
                                                        setConsensusMask([...consensusMask]);
                                                    }}
                                                >
                                                    Hidden
                                                </Button>
                                            </div>
                                        )}
                                        {typeof consensus[cell.id] !== 'undefined' && consensusMask[cell.id] && (
                                            <div>Consensus: {consensus[cell.id]?.labelName}</div>
                                        )}
                                    </CellGridItem>
                                ))}
                            </CellGrid>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CellAnnotationComparison;
