export class PayloadError extends Error {
    payload: Promise<any>;
    constructor(message: string, payload: Promise<any>) {
        super(message);
        this.payload = payload;
    }
}

export function auth_fetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
    const headersInit: HeadersInit = {};
    let requestInit: RequestInit = { headers: {} };
    if (typeof init !== 'undefined') {
        requestInit = Object.assign(requestInit, init);
    }
    headersInit['Authorization'] = sessionStorage.getItem('Authorization') as string;
    requestInit.headers = Object.assign(requestInit.headers as Record<string, unknown>, headersInit);
    return fetch(input, requestInit);
}

export function checkSuccess(response: Response): Promise<any> {
    if (!response.ok) {
        throw new PayloadError('Request failed! Response: '.concat(JSON.stringify(response)), response.json());
    }
    return response.json();
}

export function logData(response: Response): Promise<any> {
    if (!response.ok) {
        throw new PayloadError('Something went wrong! Response: '.concat(JSON.stringify(response)), response.json());
    }
    return response.json();
}

export function checkSuccessBlob(response: Response): Promise<any> {
    if (!response.ok) {
        throw Error('Something went wrong! Response: '.concat(JSON.stringify(Response)));
    }
    return response.blob();
}

export function downloadBlob(filename: string, blob: any): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}
