import React, { useEffect, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import { Card, CardHeader, CardBody, Col, Container, Row } from 'shards-react';
import styled from 'styled-components';
import PageTitle from '../components/common/PageTitle';
import { Dataset } from './../models/BackendModels';
import DatasetSelect from '../components/wsi/DatasetSelect';
import config from './../config';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
} as React.CSSProperties;

// const thumb = {
//     display: 'inline-flex',
//     borderRadius: 2,
//     border: '1px solid #eaeaea',
//     marginBottom: 8,
//     marginRight: 8,
//     width: 100,
//     height: 100,
//     padding: 4,
//     boxSizing: 'border-box',
// } as React.CSSProperties;

// const thumbInner = {
//     display: 'flex',
//     minWidth: 0,
//     overflow: 'hidden',
// } as React.CSSProperties;

// const img = {
//     display: 'block',
//     width: 'auto',
//     height: '100%',
// } as React.CSSProperties;

const Success = styled.span`
    color: green;
`;

const Failed = styled.span`
    color: red;
`;

const Pending = styled.span`
    color: yellow;
`;

const DropzoneArea = styled.p`
    padding: 3rem;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 8px;
`;

type PreviewFile = File & { preview: string };
type UploadResponseStatus = { success: boolean; message: string };

const WsiUpload: React.FC = () => {
    const [currentDataset, setCurrentDataset] = useState<Dataset | undefined>();

    const [files, setFiles] = useState<Array<PreviewFile>>([]);
    const [uploadStatus, setUploadStatus] = useState<Record<string, UploadResponseStatus>>({});

    const onDrop = (acceptedFiles: Array<File>) => {
        setFiles(
            acceptedFiles.map((file: File) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        );

        const promises: Promise<Record<string, any>>[] = [];
        acceptedFiles.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', new Blob([file]));
            promises.push(
                fetch(`${config.backend}/upload/${currentDataset?.id}/${file.name}`, {
                    method: 'PUT',
                    // headers: { 'Content-Type': 'application/json' },
                    body: formData,
                })
                    .then((response: Response) => response.json())
                    .then((json) => {
                        return { [file.name]: json };
                    }),
            );
        });
        Promise.all(promises).then((values) => {
            const uploadStatus: Record<string, UploadResponseStatus> = {};
            values.forEach((perFileJson) => {
                Object.keys(perFileJson).forEach((filename) => {
                    const json = perFileJson[filename];
                    if (typeof json.error !== 'undefined') {
                        uploadStatus[filename] = { success: false, message: json.error };
                    } else {
                        uploadStatus[filename] = { success: true, message: json.success };
                    }
                });
            });
            setUploadStatus(uploadStatus);
        });

        // POST to a test endpoint for demo purposes
        // const req = request.post('https://httpbin.org/post');
        // files.forEach(file => {
        //   req.attach(file.name, file);
        // });
        // req.end();
    };
    const uploadStatusMessage = (status?: UploadResponseStatus) => {
        if (typeof status !== 'undefined') {
            if (status.success) {
                return <Success>Success</Success>;
            } else if (typeof status.message !== 'undefined') {
                return <Failed>Failed ({status.message})</Failed>;
            }
        }
        return <Pending>Pending</Pending>;
    };

    const thumbs = files.map((file: PreviewFile) => (
        <li key={file.name}>
            Upload of {file.name}&nbsp;
            {uploadStatusMessage(uploadStatus[file.name])}
        </li>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file: PreviewFile) => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Wsi upload" subtitle="Slide images" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Select dataset</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <DatasetSelect selectedDataset={currentDataset} onChange={setCurrentDataset} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    {typeof currentDataset !== 'undefined' && (
                        <Card>
                            <CardBody>
                                <ReactDropzone onDrop={onDrop}>
                                    {({ getRootProps, getInputProps }: any) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <DropzoneArea>
                                                    Drag and drop some files here, or click to select files
                                                </DropzoneArea>
                                            </div>
                                        </section>
                                    )}
                                </ReactDropzone>
                                <aside style={thumbsContainer}>
                                    <ul>{thumbs}</ul>
                                </aside>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default WsiUpload;
