import React from 'react';
import { Nav, NavItem, NavLink } from 'shards-react';
import { Store } from '../../../flux';
import config from '../../../config';
import SidebarNavItem from './SidebarNavItem';

class SidebarNavItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navItems: Store.getSidebarItems(),
        };

        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        Store.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onChange);
    }

    onChange() {
        this.setState({
            ...this.state,
            navItems: Store.getSidebarItems(),
        });
    }

    render() {
        const { navItems: items } = this.state;
        return (
            <div className="nav-wrapper">
                <Nav className="nav--no-borders flex-column">
                    {items.map((item, idx) => (
                        <SidebarNavItem key={idx} item={item} />
                    ))}
                </Nav>
            </div>
        );
    }
}
/*
                    <NavItem>
                        <NavLink href={config.backend.concat('/admin/')}>
                            <div className="d-inline-block item-icon-wrapper">
                                <i className="material-icons">person</i>
                            </div>
                            <span>Admin</span>
                        </NavLink>
                    </NavItem>
*/
export default SidebarNavItems;
