// https://material.io/resources/icons/
export default function () {
    return [
        {
            title: 'Preselected Cell annotation',
            to: '/preselectedcell-annotation',
            htmlBefore: '<i class="material-icons">edit</i>',
            htmlAfter: '',
        },
        {
            title: 'Single Cell annotation',
            to: '/cell-annotation',
            htmlBefore: '<i class="material-icons">edit</i>',
            htmlAfter: '',
        },
        {
            title: 'Cell annotation comparison',
            htmlBefore: '<i class="material-icons">view_module</i>',
            to: '/cell-annotation-comparison',
        },
        {
            title: 'ROI annotation',
            to: '/roi-annotation',
            htmlBefore: '<i class="material-icons">edit</i>',
            htmlAfter: '',
        },
        {
            title: 'Microscope cell segmentation',
            to: '/microscope-cell-segmentation',
            htmlBefore: '<i class="material-icons">edit</i>',
            htmlAfter: '',
        },
        // {
        //     title: 'User Profile',
        //     htmlBefore: '<i class="material-icons">person</i>',
        //     to: '/user-profile-lite',
        // },
        // {
        //     title: 'Segmentation annotation',
        //     htmlBefore: '<i class="material-icons">view_module</i>',
        //     to: '/segmentation-annotation',
        // },
        // {
        //     title: 'Wsi upload',
        //     htmlBefore: '<i class="material-icons">cloud_upload</i>',
        //     to: '/wsi-upload',
        // },
        {
            title: 'Analysis',
            htmlBefore: '<i class="material-icons">cloud_upload</i>',
            to: '/analysis-demo',
        },
        // {
        //     title: 'Via export',
        //     htmlBefore: '<i class="material-icons">cloud_upload</i>',
        //     to: '/via-export',
        // },
        // {
        //     title: 'Utility',
        //     htmlBefore: '<i class="material-icons">build</i>',
        //     to: '/utility',
        // },
        // {
        //     title: 'Video Demo 1',
        //     htmlBefore: '<i class="material-icons">view_module</i>',
        //     to: '/video-demo-1',
        // },
        // {
        //     title: 'Video Demo 2',
        //     htmlBefore: '<i class="material-icons">view_module</i>',
        //     to: '/video-demo-2',
        // },
        {
            title: 'Logout',
            htmlBefore: '<i class="material-icons">person</i>',
            to: '/logout',
        },
    ];
}
