import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faMap, faImages } from '@fortawesome/free-solid-svg-icons';

const MyeloaidPageLayout02 = ({ children }) => (
    <>
        <div style={{justifyContent: 'space-between',
            alignItems: 'center', display: 'flex', height: "3rem", backgroundColor: "#000", width: "100%"}}>
            <img style={{height: "3rem"}}  src="/logo.webp"></img>
            <div className="center-helper-top" style={{
                alignItems: 'center', height: "3rem", justifyContent: 'center', columnGap: '4rem', fontSize: '1.5rem'}}>
                <FontAwesomeIcon icon={faMap} style={{color: "#ffffff", cursor: "pointer"}} 
                    onClick={() => document.querySelector("#wsi-pagemap-container > canvas").classList.toggle("slide-toggle-top")} />
                <FontAwesomeIcon icon={faImages} style={{color: "#ffffff", cursor: "pointer"}} 
                    onClick={() => document.querySelector("#wsi-carousel-thumbs").classList.toggle("slide-toggle-top")} />
                <FontAwesomeIcon icon={faPen} style={{color: "#ffffff", cursor: "pointer"}} 
                    onClick={() => document.querySelector("#cell-label-correction").classList.toggle("toggle-cell-label-corrections")} />
            </div>
        </div>
        <Container fluid>
            <Row>
                <Col
                    className="p-0 w-100"
                    lg={{ size: 12, offset:0 }}
                    sm="12"
                    tag="main"
                >
                    {children}
                </Col>
            </Row>
        </Container>
    </>
);

MyeloaidPageLayout02.propTypes = {
    children: PropTypes.node,
};

MyeloaidPageLayout02.defaultProps = {};

export default MyeloaidPageLayout02;
