import React, { Component, useContext, useEffect, useState } from 'react';
import config from './../config';
import { auth_fetch } from './../utils/ajax';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormCheckbox,
    FormRadio,
} from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import SingleCellDisplay, { fetchLabelGroups } from './../components/wsi/SingleCellDisplay';
import WsiDisplay from './../components/wsi/WsiDisplay';
//import SingleCellAnnotation from "./../components/wsi/SingleCellAnnotation";
import { Cell, Dataset, LabelGroup, LabelSet, Label, Wsi, ExportGroup } from './../models/BackendModels';
import DatasetSelect from './../components/wsi/DatasetSelect';
import WsiSelect from './../components/wsi/WsiSelect';
import { UserContext } from './../context/UserContext';
import { checkSuccess } from './../utils/ajax';
import { type } from 'os';
import ClipLoader from 'react-spinners/ClipLoader';
import Input from 'react-select/src/components/Input';
import styled from 'styled-components';
import ExportGroupSelect, {
    retrieveExportGroup,
    retrieveExportGroups,
    retrieveExportGroupWsi,
} from '../components/wsi/ExportGroupSelect';

type CellId = number;

const SpacedCardBody = styled(CardBody)`
    display: flex;
    gap: 10px;
`;
const FilterButton = styled(Button)``;
// interface CellQueryResultItem {
//     id: number;
//     dataset: number;
//     wsi: number;
//     segmentationSet: number;
// }

interface FilterArgs {
    segmentationSetId?: number;
    datasetId?: number;
    wsiId?: number;
    labeledBy?: Array<number | null>;
    labelSetId: number;
}

export function retrieveLabelSet(labelSetId: number): Promise<LabelSet> {
    return auth_fetch(`${config.backend}/api/labelset/${labelSetId}/`).then(checkSuccess);
}

export const retrieveDataset = (datasetId: number): Promise<Dataset> =>
    auth_fetch(`${config.backend}/api/dataset/${datasetId}`)
        .then(checkSuccess)
        .then((data) => data[0]);

export const retrieveWsi = (wsiId: number): Promise<Wsi> =>
    auth_fetch(`${config.backend}/api/wsi/${wsiId}`)
        .then(checkSuccess)
        .then((data) => data[0]);

const fetchCellQueryResult = (filterArgs: FilterArgs): Promise<CellId[]> => {
    return auth_fetch(`${config.backend}/api/query_cells/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filterArgs),
    }).then(checkSuccess);
};

// const fetchCells = (cellIds: CellId[]): Promise<Cell> => {
//     return auth_fetch(`${config.backend}/api/cell/${cellId}/`).then(checkSuccess);
// };

const fetchWsiCells = (wsiId: number): Promise<Cell[]> => {
    return auth_fetch(`${config.backend}/api/wsi_cells/${wsiId}/`).then(checkSuccess);
};

const fetchCell = (cellId: number): Promise<Cell> => {
    return auth_fetch(`${config.backend}/api/cell/${cellId}/`).then(checkSuccess);
};

const fetchAnnotationCount = (
    userId: number,
    segmentationSetId: number,
    labelGroupId: number | undefined = undefined,
): Promise<number> => {
    return auth_fetch(
        `${config.backend}/api/cell_label_selections_count/${userId}/${segmentationSetId}/${
            typeof labelGroupId !== 'undefined' ? labelGroupId + '/' : ''
        }`,
    ).then(checkSuccess);
};

interface Option {
    id: number | null;
    name: string;
}
const VALID_ANNOTATOR_OPTIONS = [
    {
        id: null,
        name: 'Nobody',
    },
    {
        id: 12,
        name: 'Junker Jack',
    },
    {
        id: 26,
        name: 'Blair Brook',
    },
    {
        id: 29,
        name: 'Casey Carr',
    },
    {
        id: 30,
        name: 'Riley Ray',
    },
];

const FIXED_SUGGESTIONS = [
    {
        id: 13,
        name: 'Cell',
        labelGroup: 8,
    },
    {
        id: 6,
        name: 'Erythrocyte',
        labelGroup: 5,
    },
];

const FIXED_EXPORT_GROUPS = [44, 45];
const CELL_TYPE_LABEL_GROUP = 5;

const toggleSidebar = () => {
    const sidebar = document.querySelectorAll<HTMLElement>('.main-sidebar')[0];
    if (sidebar.style.transform === `translateX(-100%)`) {
        sidebar.style.transform = 'translateX(0)';
    } else {
        sidebar.style.transform = 'translateX(-100%)';
    }
};

const CellAnnotation: React.FC = () => {
    const { user } = useContext(UserContext);
    const [availableExportGroups, setAvailableExportGroups] = useState<ExportGroup[]>([]);
    const [selectedExportGroup, setSelectedExportGroup] = useState<ExportGroup>();
    const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [selectedWsi, setSelectedWsi] = useState<Wsi>();
    const [labelSet, setLabelSet] = useState<LabelSet>();
    // const [labeledBy, setLabeledBy] = useState([]);
    const [suggestions, setSuggestions] = useState<Label[]>(FIXED_SUGGESTIONS);
    const [validFilterOptions, setValidFilterOptions] = useState<Set<Option>>(new Set(VALID_ANNOTATOR_OPTIONS));
    const [filterOptions, setFilterOptions] = useState<Option[]>([]); // VALID_ANNOTATOR_OPTIONS[0]
    const [cellsFilter, setCellsFilter] = useState<CellId[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [finalLoaded, setFinalLoaded] = useState(false);
    const [selectedCellIndex, setSelectedCellIndex] = useState(0);
    const [selectedCell, setSelectedCell] = useState<Cell>();
    const [cells, setCells] = useState<Cell[]>([]);
    const [annotationCount, setAnnotationCount] = useState<number>(0);
    const [cellTypeAnnotationCount, setCellTypeAnnotationCount] = useState<number>(0);
    // const [me, setMe] = useState<Option>();

    useEffect(() => {
        if (typeof user !== 'undefined') {
            const my = VALID_ANNOTATOR_OPTIONS.find((u) => u.id === user.id);
            // setMe({...user, name: "Me"});
            const me = { ...user, name: `Me ${typeof my !== 'undefined' ? '(' + my.name + ')' : ''}` };
            setValidFilterOptions(new Set(VALID_ANNOTATOR_OPTIONS.filter((u) => u.id !== user.id).concat(me)));
        }
    }, [user]);

    useEffect(() => {
        retrieveLabelSet(config.labelSet).then((labelSet: LabelSet) => {
            setLabelSet(labelSet);
            // debugger;
        });
    }, []);

    useEffect(() => {
        setLoaded(false);
        retrieveExportGroups().then((exportGroups: ExportGroup[]) => {
            if (typeof exportGroups !== 'undefined' && exportGroups.length > 0) {
                setAvailableExportGroups(
                    exportGroups.filter((g: ExportGroup) => FIXED_EXPORT_GROUPS.indexOf(g.id) !== -1),
                );
            }
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (typeof selectedExportGroup !== 'undefined') {
            setLoaded(false);
            retrieveExportGroupWsi(selectedExportGroup.id)
                .then((data) =>
                    setAvailableWsi(
                        data.map((e: any) => {
                            e.id = e.wsi;
                            return e;
                        }),
                    ),
                )
                .then(() => setLoaded(true));
        }
    }, [selectedExportGroup]);

    useEffect(() => {
        if (typeof availableWsi !== 'undefined') {
            setSelectedWsi(availableWsi[0]);
        }
    }, [availableWsi]);

    useEffect(() => {
        if (typeof selectedWsi !== 'undefined') {
            setLoaded(false);
            fetchCellQueryResult({
                wsiId: selectedWsi.id,
                labelSetId: config.labelSet,
                labeledBy: filterOptions.map((o) => o.id),
                segmentationSetId: config.latestSegmentationSetId,
            }).then(setCellsFilter);
        }
    }, [selectedExportGroup, selectedWsi, filterOptions]);

    useEffect(() => {
        if (typeof cellsFilter !== 'undefined' && typeof selectedWsi !== 'undefined') {
            if (cellsFilter.length > 0 && typeof cellsFilter[selectedCellIndex] !== 'undefined') {
                setLoaded(false);
                fetchCell(cellsFilter[selectedCellIndex])
                    .then((cell) => {
                        setCells([cell]);
                        setSelectedCell(cell);
                    })
                    // .then(setSelectedCell)
                    .then(() => setFinalLoaded(true))
                    .then(() => setLoaded(true));
            } else {
                setCells([]);
                setSelectedCellIndex(0);
                setSelectedCell(undefined);
                setFinalLoaded(true);
                setLoaded(true);
            }

            // Promise.all(cellsFilter.map(fetchCell))
            // .then(setCells)
            // .then(() => setFinalLoaded(true))
            // .then(() => setLoaded(true));
        }
    }, [cellsFilter, selectedCellIndex]);

    // useEffect(() => {
    //     if(typeof selectedWsi !== 'undefined') {
    //         fetchWsiCells(selectedWsi.id)
    //             .then(setCells)
    //     }
    // }, [selectedWsi]);

    useEffect(() => {
        fetchAnnotationCount(user.id, config.latestSegmentationSetId).then(setAnnotationCount);
        fetchAnnotationCount(user.id, config.latestSegmentationSetId, CELL_TYPE_LABEL_GROUP).then(
            setCellTypeAnnotationCount,
        );
    }, [user, selectedCellIndex]);

    // useEffect(() => {
    //     if(typeof cells !== 'undefined') {
    //         if (typeof cellsFilter[selectedCellIndex] !== 'undefined') {
    //             setSelectedCell(cells.find(c => c.id === cellsFilter[selectedCellIndex]));
    //             // fetchCell(cellsFilter[selectedCellIndex]).then(async (cell: Cell) => {
    //             //     setSelectedCell(cell);
    //             // });
    //         }
    //     }
    // }, [cellsFilter, selectedCellIndex, cells]);

    // useEffect(() => {
    //     if (typeof selectedCell !== 'undefined') {
    //         if (dataset?.id !== selectedCell.dataset) {
    //             retrieveDataset(selectedCell.dataset).then(setDataset);
    //         }
    //         // if (wsi?.id !== selectedCell.wsi) {
    //         //     retrieveWsi(selectedCell.wsi).then(setWsi);
    //         // }
    //     }
    // }, [wsi, dataset, selectedCell]);

    const toggleFilterOption = (option: Option) => {
        if (option.id === null) {
            if (filterOptions.includes(option)) {
                setFilterOptions([]);
            } else {
                setFilterOptions([option]);
            }
        } else {
            if (filterOptions.includes(option)) {
                setFilterOptions(filterOptions.filter((o) => o.id !== option.id && o.id !== null));
            } else {
                setFilterOptions(
                    filterOptions
                        .filter((o) => o.id !== null)
                        .concat(option)
                        .sort((a, b) => (a.id === null ? 1 : b.id === null ? -1 : a.id - b.id)),
                );
            }
        }
    };

    const previousCell = () => {
        setSelectedCellIndex(selectedCellIndex - 1);
    };

    const nextCell = () => {
        setSelectedCellIndex(selectedCellIndex + 1);
    };

    const jumpToCell = (idx: number) => {
        setSelectedCellIndex(idx - 1);
    };

    const annotatedAsCellWithoutTypeCount = annotationCount - cellTypeAnnotationCount;

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col className={`mr-sm-auto text-center text-md-left mb-sm-0 col-10`}>
                    <span className="text-uppercase page-subtitle">Slide images</span>
                    <h3 className="page-title">Cell annotation</h3>
                </Col>
                <span className={'material-icons d-xl-none'} onClick={toggleSidebar} style={{ fontSize: '3.5rem' }}>
                    list
                </span>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Select dataset</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <ExportGroupSelect
                                selectedExportGroup={selectedExportGroup}
                                availableExportGroups={availableExportGroups}
                                onChange={setSelectedExportGroup}
                                placeholder={`Select dataset`}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Select whole slide image</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <WsiSelect
                                isDisabled={false}
                                availableWsi={availableWsi}
                                selectedWsi={selectedWsi}
                                onChange={setSelectedWsi}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">
                                Only display cells annotated by: {filterOptions.map((o) => o.name).join(' or ')}
                            </h6>
                            <div className="block-handle" />
                        </CardHeader>
                        <SpacedCardBody className="border-top container-spacing">
                            {[...validFilterOptions].map((o) => (
                                <FilterButton
                                    theme={filterOptions.includes(o) ? 'success' : 'secondary'}
                                    key={o.id}
                                    disabled={!loaded}
                                    onClick={() => toggleFilterOption(o)}
                                >
                                    {o.name}
                                </FilterButton>
                            ))}
                        </SpacedCardBody>
                    </Card>
                </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
                <ClipLoader loading={!loaded} size={150} />
            </div>
            <Row>
                <Col lg="12" md="12" sm="12" className="mb-4">
                    {typeof selectedCell === 'undefined' && (
                        <Card small>
                            <CardBody>
                                This image has no cells annotated by {filterOptions.map((o) => o.name).join(' or ')}
                            </CardBody>
                        </Card>
                    )}
                    {typeof selectedWsi !== 'undefined' &&
                        typeof selectedCell !== 'undefined' &&
                        typeof labelSet !== 'undefined' && (
                            <SingleCellDisplay
                                pixelDiameterInMicrometer={Number(selectedWsi.pixelDiameterInMicrometer)}
                                title={`Cell ${selectedCellIndex + 1} of ${cellsFilter.length} `}
                                user={user}
                                // dataset={selectedExportGroup}
                                cell={selectedCell}
                                showPrevious={selectedCellIndex > 0}
                                showNext={selectedCellIndex < cellsFilter.length - 1}
                                onPrevious={previousCell}
                                onNext={nextCell}
                                onJumpTo={jumpToCell}
                                suggestions={suggestions}
                                labelSetId={labelSet.id}
                                annotationCountText={`You annotated a total of ${cellTypeAnnotationCount} cell types plus ${annotatedAsCellWithoutTypeCount} cells without type!`}
                                disabled={false}
                                allowFreetext={false}
                                loaded={false}
                            />
                        )}
                </Col>
            </Row>
            <Row>
                <Col className="d-block ">
                    {/* d-none d-lg-block */}
                    {typeof selectedWsi !== 'undefined' && (
                        <WsiDisplay
                            title="Whole slide image overlay"
                            loaded={finalLoaded}
                            wsi={selectedWsi}
                            cells={cells}
                            pixelDiameterInMicrometer={Number(selectedWsi.pixelDiameterInMicrometer)}
                            selectedCell={selectedCell}
                            downsize={true}
                            onClick={(cell: Cell) => setSelectedCellIndex(cellsFilter.indexOf(cell.id))}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default CellAnnotation;
