import React from 'react';
import { Col, Container, Row } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import UserAccountDetails from '../components/user-profile-lite/UserAccountDetails';
import { UserContext } from './../context/UserContext';

class UserProfileLite extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };
    }

    render() {
        const user = this.context.user;
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
                </Row>
                <Row>
                    <Col lg="2">
                        <UserAccountDetails user={user} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default UserProfileLite;
