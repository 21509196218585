import React, { Component } from 'react';
import { Button, Card, CardBody, Form, FormGroup, FormInput, FormRadio } from 'shards-react';
import config from '../../config';
import { UserContext } from './../../context/UserContext';
import { User } from './../../models/BackendModels';

class UserAccountDetails extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.user.name,
            selectedExpertise: this.props.user.expertise,
        };
    }

    // componentDidUpdate() {
    //     if (this.props.user && this.state.username === '') {
    //         this.setState({ username: this.props.user.name, selectedExpertise: this.props.user.expertise });
    //     }
    // }

    updateUser() {
        let user = this.props.user;
        user.name = this.state.username;
        user.expertise = this.state.selectedExpertise;
        return fetch(`${config.backend}/api/annotator/${user.id}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user),
        }).then(() => {
            this.context.login(user.name, user.expertise);
        });
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <Form>
                        <FormGroup>
                            <label htmlFor="#username">Username</label>
                            <FormInput
                                id="#username"
                                value={this.state.username}
                                onChange={(e) => this.setState({ username: e.target.value })}
                                placeholder="Nutzername"
                            />
                        </FormGroup>
                        <label htmlFor="#expertise">Skill level</label>
                        <div>
                            <FormRadio
                                name="expertise"
                                checked={this.state.selectedExpertise === 1}
                                onChange={() => this.setState({ selectedExpertise: 1 })}
                            >
                                Novice
                            </FormRadio>
                            <FormRadio
                                name="expertise"
                                checked={this.state.selectedExpertise === 2}
                                onChange={() => this.setState({ selectedExpertise: 2 })}
                            >
                                Intermediate
                            </FormRadio>
                            <FormRadio
                                name="expertise"
                                checked={this.state.selectedExpertise === 3}
                                onChange={() => this.setState({ selectedExpertise: 3 })}
                            >
                                Expert
                            </FormRadio>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => this.updateUser()}>Update</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}
UserAccountDetails.propTypes = {
    user: User,
};

UserAccountDetails.defaultProps = {};

export default UserAccountDetails;
