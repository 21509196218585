import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormInput, Row } from 'shards-react';
import styled from 'styled-components';
import { HTMLElementEvent } from '../@types/HTMLElementEvent';
import PageTitle from '../components/common/PageTitle';
import { Dataset, ExportGroup, Wsi } from '../models/BackendModels';
import DatasetSelect from './../components/wsi/DatasetSelect';
import ExportGroupSelect, { retrieveExportGroups } from './../components/wsi/ExportGroupSelect';
import WsiSelect, { retrieveWsi } from './../components/wsi/WsiSelect';
import config from './../config';
import { UserContext } from './../context/UserContext';
import { auth_fetch, checkSuccess, checkSuccessBlob, downloadBlob } from './../utils/ajax';
import Loader from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import useWebSocket, { ReadyState } from 'react-use-websocket';

// const thumbsContainer = {
//     display: 'flex',
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     marginTop: 16,
// } as React.CSSProperties;

interface LoadingIndicatorProps {
    active: boolean;
}

interface TrainingResponse {
    message: string;
    isLoading: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ active }: LoadingIndicatorProps) => {
    const { promiseInProgress } = usePromiseTracker();

    return active && promiseInProgress ? <Loader type="ThreeDots" color="#2BAD60" height={100} width={100} /> : null;
};

const StyledExportGroupSelect = styled(ExportGroupSelect)`
    display: inline-block;
    width: 50%;
`;

const StyledExportGroupNameInput = styled(FormInput)`
    display: inline-block;
    width: 50%;
`;

const StyledDatasetSelect = styled(DatasetSelect)`
    display: inline-block;
    width: 30%;
`;

const StyledWsiSelect = styled(WsiSelect)`
    display: inline-block;
    width: 50%;
`;

const IconButton = styled(Button)`
    padding: 0.4rem;
    font-size: 1rem;
`;

const download = (projectsPromise: Promise<Response>) => {
    return projectsPromise
        .then(checkSuccessBlob)
        .then(downloadBlob.bind(null, 'via-projects.json'))
        .catch((error: any) => console.log(error));
};

const fetchAllViaProjects = () => auth_fetch(`${config.backend}/api/via/export/`);
const fetchUserViaProjects = (userId: number) =>
    auth_fetch(`${config.backend}/api/via/export/`, {
        method: 'POST',
        body: JSON.stringify({
            annotator: userId,
        }),
        headers: { 'Content-Type': 'application/json' },
    });
const fetchWsiViaProjects = (wsiIds: number[]) =>
    auth_fetch(`${config.backend}/api/via/export/`, {
        method: 'POST',
        body: JSON.stringify({
            wsi: wsiIds,
        }),
        headers: { 'Content-Type': 'application/json' },
    });
const fetchUserWsiViaProjects = (userId: number, wsiIds: number[]) =>
    auth_fetch(`${config.backend}/api/via/export/`, {
        method: 'POST',
        body: JSON.stringify({
            annotator: userId,
            wsi: wsiIds,
        }),
        headers: { 'Content-Type': 'application/json' },
    });

const addExportGroupWsi = (exportGroupId: number, wsiId: number) =>
    auth_fetch(`${config.backend}/api/export_group_wsi/${exportGroupId}/`, {
        method: 'POST',
        body: JSON.stringify({ export_group: exportGroupId, wsi: wsiId }),
        headers: { 'Content-Type': 'application/json' },
    });

const removeExportGroupWsi = (exportGroupId: number, wsiId: number) =>
    auth_fetch(`${config.backend}/api/export_group_wsi/${exportGroupId}/${wsiId}/`, { method: 'DELETE' });

const retrieveExportGroupWsi = (exportGroupId: number) =>
    auth_fetch(`${config.backend}/api/export_group_wsi/${exportGroupId}/`);

const createExportGroup = (name: string) =>
    auth_fetch(`${config.backend}/api/export_groups/`, {
        method: 'POST',
        body: JSON.stringify({ name: name }),
        headers: { 'Content-Type': 'application/json' },
    });

const updateExportGroup = (id: number, name: string) =>
    auth_fetch(`${config.backend}/api/export_group/${id}/`, {
        method: 'PUT',
        body: JSON.stringify({ id: id, name: name }),
        headers: { 'Content-Type': 'application/json' },
    });

const deleteExportGroup = (id: number) =>
    auth_fetch(`${config.backend}/api/export_group/${id}/`, {
        method: 'DELETE',
    });

const end = (arr: any[]) => {
    return arr[arr.length - 1];
};

class DateParts {
    date: Date;
    dd: string;
    mm: string;
    yyyy: number;
    HH: string;
    MM: string;
    ss: string;
    constructor(datestring: string | number | Date) {
        this.date = new Date(datestring);
        this.dd = String(this.date.getDate()).padStart(2, '0');
        this.mm = String(this.date.getMonth() + 1).padStart(2, '0'); //January is 0!
        this.yyyy = this.date.getFullYear();
        this.HH = String(this.date.getHours()).padStart(2, '0');
        this.MM = String(this.date.getMinutes()).padStart(2, '0');
        this.ss = String(this.date.getSeconds()).padStart(2, '0');
    }
}

const newExportGroup = new ExportGroup();

const ViaExport: React.FC = () => {
    const { user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false);

    const [currentExportGroup, setCurrentExportGroup] = useState<ExportGroup>(newExportGroup);
    const [currentExportGroupNameInput, setCurrentExportGroupNameInput] = useState<string>(newExportGroup.name);
    const [wsiInExportGroup, setWsiInExportGroup] = useState<Wsi[]>([]);
    const [availableExportGroups, setAvailableExportGroups] = useState<ExportGroup[]>([]);
    const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [currentDataset, setCurrentDataset] = useState<Dataset | undefined>();

    const [currentWsi, setCurrentWsi] = useState<Wsi | undefined>();

    const [messages, setMessages] = useState<string[]>([]);
    const { sendMessage, lastMessage, readyState } = useWebSocket(`${config.wsBackend}/ws/training/`);

    // console.log(.isLoading);
    // const handleClickSendMessage = useCallback(() => sendMessage('Hello'), []);
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const trainOn = useCallback(
        (projectsPromise: Promise<Response>) => {
            return projectsPromise
                .then(checkSuccessBlob)
                .then((blob) => sendMessage(blob))
                .catch((error: any) => console.log(error));
        },
        [sendMessage],
    );

    const updateAvailableExportGroups = useCallback(() => {
        return retrieveExportGroups().then((data: ExportGroup[]) => {
            const exportGroups = [newExportGroup, ...data];
            setAvailableExportGroups(exportGroups);
            return exportGroups;
        });
    }, []);

    const updateExportGroupWsi = useCallback(() => {
        if (typeof currentExportGroup !== 'undefined') {
            if (currentExportGroup.id > 0) {
                retrieveExportGroupWsi(currentExportGroup.id)
                    .then(checkSuccess)
                    .then((data) =>
                        setWsiInExportGroup(
                            data.map((el: any) => Object.assign(new Wsi(), { id: el.wsi, imgName: el.imgName })),
                        ),
                    );
            } else {
                setWsiInExportGroup([]);
            }
        }
    }, [currentExportGroup]);

    const removeWsi = (wsiToRemove: Wsi) => {
        if (typeof currentExportGroup !== 'undefined') {
            removeExportGroupWsi(currentExportGroup.id, wsiToRemove.id).then(updateExportGroupWsi);
        }
        // setWsiInExportGroup(wsiInExportGroup.filter((wsi: Wsi) => wsi !== wsiToRemove));
    };

    const addWsi = (wsiToAdd?: Wsi) => {
        if (typeof currentExportGroup !== 'undefined' && typeof wsiToAdd !== 'undefined') {
            addExportGroupWsi(currentExportGroup.id, wsiToAdd.id).then(updateExportGroupWsi);
            // setWsiInExportGroup([...wsiInExportGroup, wsiToAdd]);
        }
    };

    const changeCurrentExportGroup = (exportGroup: ExportGroup) => {
        setCurrentExportGroup(exportGroup);
        setCurrentExportGroupNameInput(exportGroup.name);
    };

    const nameSubmitHandler = () => {
        if (currentExportGroup.id > 0) {
            updateExportGroup(currentExportGroup.id, currentExportGroupNameInput).then(updateAvailableExportGroups);
        } else {
            createExportGroup(currentExportGroupNameInput)
                .then(updateAvailableExportGroups)
                .then((exportGroups: ExportGroup[]) => changeCurrentExportGroup(end(exportGroups)));
        }
    };

    useEffect(() => {
        if (lastMessage !== null) {
            setIsLoading(JSON.parse(lastMessage.data).isLoading);
            setMessages([...messages, JSON.parse(lastMessage.data).message]);
        }
    }, [lastMessage]);

    useEffect(() => {
        updateAvailableExportGroups();
    }, [updateAvailableExportGroups]);

    useEffect(() => {
        updateExportGroupWsi();
    }, [updateExportGroupWsi]);

    useEffect(() => {
        if (typeof currentDataset !== 'undefined') {
            retrieveWsi(currentDataset.id)
                .then(checkSuccess)
                .then((data: Wsi[]) =>
                    setAvailableWsi(
                        data.filter((wsi) => {
                            return wsiInExportGroup.find((w) => w.id === wsi.id) === undefined;
                        }),
                    ),
                );
        }
    }, [currentDataset, wsiInExportGroup]);

    const buildExportGroupFromProjects = async (projectsResponse: Promise<Response>, username = 'ALL') => {
        // fetchAllViaProjects(user.id)
        const d = new DateParts(new Date());
        const exportGroup = await (
            await createExportGroup(`${username}_${d.yyyy}-${d.mm}-${d.dd}T${d.HH}-${d.MM}-${d.ss}`)
        ).json();
        const projects = await (await projectsResponse).json();
        const allAdded = projects.map((project: any) => addExportGroupWsi(exportGroup.id, project.wsi));
        Promise.all(allAdded)
            .then(updateAvailableExportGroups)
            .then((exportGroups: ExportGroup[]) => changeCurrentExportGroup(end(exportGroups)));
        return exportGroup;
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Project export" subtitle="Slide images" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Select export group</h6>
                            <div className="block-handle" />
                        </CardHeader>

                        <CardBody className="border-top">
                            <StyledExportGroupSelect
                                availableExportGroups={availableExportGroups}
                                selectedExportGroup={currentExportGroup}
                                onChange={(exportGroup: ExportGroup) => {
                                    updateAvailableExportGroups().then(() => changeCurrentExportGroup(exportGroup));
                                }}
                            />
                            {currentExportGroup.id > 0 && (
                                <Button
                                    theme="danger"
                                    onClick={() =>
                                        deleteExportGroup(currentExportGroup.id)
                                            .then(updateAvailableExportGroups)
                                            .then((exportGroups: ExportGroup[]) =>
                                                changeCurrentExportGroup(end(exportGroups)),
                                            )
                                    }
                                >
                                    Delete
                                </Button>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card small>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">{currentExportGroup.id > 0 ? 'Modify' : 'Create'} export group</h6>
                            <div className="block-handle" />
                        </CardHeader>
                        <CardBody className="border-top">
                            <Row>
                                <Col lg="12" md="12" className="mb-4"></Col>
                            </Row>
                            <Row>
                                <Col lg="12" md="12" className="mb-4">
                                    {currentExportGroup && (
                                        <div>
                                            <StyledExportGroupNameInput
                                                type="text"
                                                value={currentExportGroupNameInput}
                                                onChange={(e: HTMLElementEvent<HTMLInputElement>) =>
                                                    setCurrentExportGroupNameInput(e.target.value)
                                                }
                                            ></StyledExportGroupNameInput>
                                            <Button onClick={nameSubmitHandler}>
                                                {currentExportGroup.id > 0 ? 'Change name' : 'Create'}
                                            </Button>
                                        </div>
                                    )}
                                    <ul>
                                        {wsiInExportGroup.map((wsi) => (
                                            <li key={wsi.id}>
                                                {wsi.imgName}
                                                <IconButton
                                                    className="ml-3 mb-2"
                                                    theme="danger"
                                                    onClick={() => removeWsi(wsi)}
                                                >
                                                    <i className="material-icons">delete</i>
                                                </IconButton>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                {currentExportGroup.id > 0 && (
                                    <Col lg="8" md="8" className="mb-4">
                                        <StyledDatasetSelect
                                            className="ml-2"
                                            selectedDataset={currentDataset}
                                            onChange={setCurrentDataset}
                                        />
                                        <StyledWsiSelect
                                            availableWsi={availableWsi}
                                            dataset={currentDataset}
                                            selectedWsi={currentWsi}
                                            onChange={setCurrentWsi}
                                        />
                                        <Button
                                            disabled={typeof currentWsi === 'undefined'}
                                            theme="success"
                                            onClick={() => addWsi(currentWsi)}
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card>
                        <CardBody>
                            <Button theme="secondary" onClick={() => download(trackPromise(fetchAllViaProjects()))}>
                                Export all projects
                            </Button>
                            <Button
                                className="ml-2"
                                theme="secondary"
                                onClick={() => download(trackPromise(fetchUserViaProjects(user.id)))}
                            >
                                Export your projects
                            </Button>
                            {wsiInExportGroup.length > 0 && (
                                <Button
                                    className="ml-2"
                                    theme="secondary"
                                    onClick={() =>
                                        download(
                                            trackPromise(fetchWsiViaProjects(wsiInExportGroup.map((wsi) => wsi.id))),
                                        )
                                    }
                                >
                                    Export wsi projects in {currentExportGroup.name}
                                </Button>
                            )}
                            {wsiInExportGroup.length > 0 && (
                                <Button
                                    className="ml-2"
                                    theme="primary"
                                    onClick={() =>
                                        download(
                                            trackPromise(
                                                fetchUserWsiViaProjects(
                                                    user.id,
                                                    wsiInExportGroup.map((wsi) => wsi.id),
                                                ),
                                            ),
                                        )
                                    }
                                >
                                    Export your wsi projects in {currentExportGroup.name}
                                </Button>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card>
                        <CardHeader>
                            <Button
                                disabled={isLoading || connectionStatus !== 'Open'}
                                theme="secondary"
                                onClick={async () => {
                                    const exportGroup = buildExportGroupFromProjects(fetchAllViaProjects());
                                    sendMessage(JSON.stringify({ ExportGroupName: (await exportGroup).name }));
                                    trackPromise(trainOn(fetchAllViaProjects()));
                                }}
                            >
                                Train on all projects
                            </Button>
                            <Button
                                disabled={isLoading || connectionStatus !== 'Open'}
                                className="ml-2"
                                theme="secondary"
                                onClick={async () => {
                                    const exportGroup = buildExportGroupFromProjects(
                                        fetchUserViaProjects(user.id),
                                        user.name,
                                    );
                                    sendMessage(JSON.stringify({ ExportGroupName: (await exportGroup).name }));
                                    trackPromise(trainOn(fetchUserViaProjects(user.id)));
                                }}
                            >
                                Train on your projects
                            </Button>
                            {wsiInExportGroup.length > 0 && (
                                <Button
                                    disabled={isLoading || connectionStatus !== 'Open'}
                                    className="ml-2"
                                    theme="secondary"
                                    onClick={() =>
                                        trackPromise(
                                            trainOn(fetchWsiViaProjects(wsiInExportGroup.map((wsi) => wsi.id))),
                                        )
                                    }
                                >
                                    Train on wsi projects in {currentExportGroup.name}
                                </Button>
                            )}
                            {wsiInExportGroup.length > 0 && (
                                <Button
                                    disabled={isLoading || connectionStatus !== 'Open'}
                                    className="ml-2"
                                    theme="primary"
                                    onClick={() =>
                                        trackPromise(
                                            trainOn(
                                                fetchUserWsiViaProjects(
                                                    user.id,
                                                    wsiInExportGroup.map((wsi) => wsi.id),
                                                ),
                                            ),
                                        )
                                    }
                                >
                                    Train on your wsi projects in {currentExportGroup.name}
                                </Button>
                            )}
                        </CardHeader>
                        <CardBody>
                            <div>The WebSocket is currently {connectionStatus}</div>
                            {/* <button onClick={handleClickSendMessage}>Send Click</button> */}
                            {/* {lastMessage ? <span>Last message: {lastMessage.data}</span> : null} */}
                            {messages.map((msg, idx) => (
                                <div key={idx}>{msg}</div>
                            ))}
                            {isLoading && <Loader type="ThreeDots" color="#2BAD60" height={100} width={100} />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row></Row>
        </Container>
    );
};

export default ViaExport;
export { retrieveExportGroupWsi };
