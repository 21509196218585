import React, { Component } from 'react';
import config from './../config';
import { Card, CardHeader, CardBody, Col, Container, Row } from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import SingleCellDisplay from './../components/wsi/SingleCellDisplay';
import WsiDisplay from './../components/wsi/WsiDisplay';
//import SingleCellAnnotation from "./../components/wsi/SingleCellAnnotation";
import DatasetSelect from './../components/wsi/DatasetSelect';
import WsiSelect from './../components/wsi/WsiSelect';
import { UserContext } from './../context/UserContext';
import { checkSuccess } from './../utils/ajax';
//<SingleCellAnnotation />

class WsiSegmentation extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            wsi: undefined,
            cells: [],
            selectedCellIndex: 0,
            /*       cellLabelSelections: [],
      labelsInSelections: [], */
            loaded: false,
            dataset: undefined,
            /*      	wsi: {
          id: 1,
          imgName: "10113-AML-Register-41154-50x.png",
          pixelDiameterInMicrometer: 0.06802721088435375
    	}*/
        };
    }

    // componentDidMount() {
    //     requestUser().then((users) => {
    //         this.setState({ user: users[0] });
    //     });
    // }

    retrieveCellData(selectedWsi) {
        fetch(`${config.backend}/api/wsi_cells/${selectedWsi.id}/2/`)
            .then(checkSuccess)
            .then((data) => {
                this.setState({
                    cells: data,
                    loaded: true,
                });

                /* this.fetchCellLabelSelections(this.state.user.id, this.state.cells[this.state.selectedCellIndex].id) */
            });
    }

    /*   fetchCellLabelSelections(userId, cellId) {
    fetch("http://localhost:8000/api/cell_label_selections/"+userId+"/"+cellId+"/")
        .then(checkSuccess)
        .then(data => {
            if(data.error) {
                data = []
            }
            this.setState({
                cellLabelSelections: data
            });
            data.map((e,_) => this.fetchLabelsInSelection(e.id, data));
        });
  }

    fetchLabelsInSelection(selectionId, cellLabelSelections) {
        fetch("http://localhost:8000/api/labels_in_selection/"+selectionId+"/")
            .then(checkSuccess)
            .then(data => {
                if(data.error) {
                    data = [];
                }

                this.setState({
                    labelsInSelections: data
                });
            });
  } */

    fetchCell(cell) {
        fetch(`${config.backend}/api/cell/${cell.id}/`)
            .then(checkSuccess)
            .then((data) => {
                if (data.error) {
                    return;
                }
                let cells = this.state.cells;
                let idx = cells.findIndex((el) => el.id === cell.id);
                cells[idx] = data;
                this.setState({
                    cells: cells,
                });
            });
    }

    previousCell() {
        this.fetchCell(this.state.cells[this.state.selectedCellIndex]);
        let newCellIndex = this.state.selectedCellIndex - 1;
        this.setState({ selectedCellIndex: newCellIndex });
        /* this.fetchCellLabelSelections(this.state.user.id, this.state.cells[newCellIndex].id) */
    }

    nextCell() {
        this.fetchCell(this.state.cells[this.state.selectedCellIndex]);
        let newCellIndex = this.state.selectedCellIndex + 1;
        this.setState({ selectedCellIndex: newCellIndex });
        /* this.fetchCellLabelSelections(this.state.user.id, this.state.cells[newCellIndex].id) */
    }

    clickedCell(cell) {
        this.fetchCell(this.state.cells[this.state.selectedCellIndex]);
        this.setState({ selectedCellIndex: this.state.cells.findIndex((c) => cell === c) });
    }

    /*   updateCellLabelSelections(cellLabelSelections) {
    cellLabelSelections.map((selection, idx) => {
        this.createOrUpdateCellLabelSelection(selection, idx);
    });
  } */

    /*   createOrUpdateLabelsInSelection(selectionId, labelId) {
        fetch("http://localhost:8000/api/labels_in_selection/",{
        method: 'POST',
        headers: new Headers({'Content-Type' : 'application/json'}), 
        body: JSON.stringify(selection),
    }).then(checkSuccess).then(data => {
        let selections = this.state.cellLabelSelections;
        selections[idx] = data;
        selections[idx].selectedLabels = selection.selectedLabels;
        this.setState({cellLabelSelections: selections});

    });
  } */

    /*   createOrUpdateCellLabelSelection(selection, idx) {
    const dateTimeFormat = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit', second: '2-digit'}) 
    const [{ value: day },,{ value: month },,{ value: year },,{ value: hour },,{ value: minute },,{ value: second }] = dateTimeFormat.formatToParts(Date.now()) 

    selection.dateCreated = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    fetch("http://localhost:8000/api/cell_label_selections/",{
        method: 'POST',
        headers: new Headers({'Content-Type' : 'application/json'}), 
        body: JSON.stringify(selection),
    }).then(checkSuccess).then(data => {
        let selections = this.state.cellLabelSelections;
        selections[idx] = data;
        selections[idx].selectedLabels = selection.selectedLabels;
        this.setState({cellLabelSelections: selections});
    });
  }  */

    changeDataset(selectedDataset) {
        this.setState({ ...this.state, dataset: selectedDataset });
    }

    changeWsi(selectedWsi) {
        this.setState({ loaded: false, selectedCellIndex: 0, wsi: selectedWsi });
        this.retrieveCellData(selectedWsi);
    }

    render() {
        const { dataset, wsi, cells, selectedCellIndex, /*cellLabelSelections,*/ loaded } = this.state;
        const user = this.context.user;
        let selectedCell = cells[selectedCellIndex];
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        title="Cell annotation"
                        subtitle="Slide images"
                        md="12"
                        className="ml-sm-auto mr-sm-auto"
                    />
                </Row>
                <Row>
                    <Col lg="8" md="12" sm="12" className="mb-4">
                        {typeof wsi !== 'undefined' && (
                            <SingleCellDisplay
                                pixelDiameterInMicrometer={wsi.pixelDiameterInMicrometer}
                                title={`Cell ${selectedCellIndex + 1} of ${cells.length}`}
                                user={user}
                                dataset={dataset}
                                /*                   cellLabelSelections={cellLabelSelections}
                  labelsInSelections={labelsInSelections} */
                                cell={selectedCell}
                                showPrevious={selectedCellIndex > 0}
                                showNext={selectedCellIndex < cells.length - 1}
                                onPrevious={this.previousCell.bind(this)}
                                onNext={this.nextCell.bind(this)}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" className="mb-4">
                        <Card small>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Select dataset</h6>
                                <div className="block-handle" />
                            </CardHeader>

                            <CardBody className="border-top">
                                <DatasetSelect
                                    selectedDataset={dataset}
                                    onChange={(selectedDataset) => this.changeDataset(selectedDataset)}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="8" md="12" className="mb-4">
                        <Card small>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Select whole slide image</h6>
                                <div className="block-handle" />
                            </CardHeader>

                            <CardBody className="border-top">
                                <WsiSelect
                                    dataset={dataset}
                                    selectedWsi={wsi}
                                    onChange={(selectedWsi) => this.changeWsi(selectedWsi)}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="d-none d-lg-block">
                        {typeof wsi !== 'undefined' && (
                            <WsiDisplay
                                title="Whole slide image overlay"
                                loaded={loaded}
                                wsi={wsi}
                                cells={cells}
                                pixelDiameterInMicrometer={wsi.pixelDiameterInMicrometer}
                                selectedCell={selectedCell}
                                onClick={(cell) => this.clickedCell(cell)}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

WsiSegmentation.propTypes = {};

WsiSegmentation.defaultProps = {};

export default WsiSegmentation;
