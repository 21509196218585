import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Video } from '../../models/BackendModels';
import config from './../../config';
import { checkSuccess, auth_fetch } from './../../utils/ajax';

interface VideoSelectProps {
    className?: string;
    selectedVideo?: Video;
    onChange: (selectedVideo: Video) => void;
}

// Required to prevent typescript bug?
const StyledSelect = styled(Select)``;

class VideoOption {
    public label = 'Video';
    public value = '0';
    public model?: Video;

    constructor(video?: Video) {
        if (video) {
            this.label = video.name;
            this.value = String(video.id);
            this.model = video;
        }
    }
}

const defaultVideoOption = new VideoOption();

const VideoSelect: React.FC<VideoSelectProps> = ({ className, selectedVideo, onChange }: VideoSelectProps) => {
    // const [selectedOption, setSelectedOption] = useState(defaultDatasetOption);
    const [videoOptions, setVideoOptions] = useState<VideoOption[]>([defaultVideoOption]);

    useEffect(() => {
        const retrieveVideos = () =>
            auth_fetch(`${config.backend}/api/videos/`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

        retrieveVideos()
            .then(checkSuccess)
            .then((data) => setVideoOptions(data.map((ds: Video) => new VideoOption(ds))));
    }, []);
    const selectedOption = videoOptions.find((o) => o.value === String(selectedVideo?.id));
    return (
        <StyledSelect
            className={className}
            value={selectedOption ? selectedOption : defaultVideoOption}
            placeholder="Video name"
            isSearchable={true}
            onChange={(o: VideoOption) => (o.model ? onChange(o.model) : null)}
            options={videoOptions}
        />
    );
};

export default VideoSelect;
