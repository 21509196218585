import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Button, Card, CardBody, CardFooter, CardHeader, FormCheckbox, FormRadio, FormInput } from 'shards-react';
import config from './../../config';
import { checkSuccess, auth_fetch } from './../../utils/ajax';
//import {Checkbox, Radio} from 'react-btn-checkbox';
// Demo styles, see 'Styles' section below for some notes on use.
//import "~react-btn-checkbox/dist/react-btn-checkbox.min.css"
import './SingleCellDisplay.css';
import styled from 'styled-components';
import { Cell, Label, LabelGroup, LabelSelection, User } from 'models/BackendModels';
import { get } from 'http';
import { debug } from 'console';
import { exception, set } from 'react-ga';

const JumpToContainer = styled.span`
    float: right;
`;

const JumpToInput = styled(FormInput)`
    width: 200px;
    display: inline-block;
    margin-left: 30px;
`;
const GoJumpTo = styled(Button)``;

export function fetchLabelGroups(labelSetId: any) {
    return auth_fetch(`${config.backend}/api/label_groups/${labelSetId}/`)
        .then(checkSuccess)
        .then((data) => data.sort((a: any, b: any) => a.sortOrder - b.sortOrder));
}

export function fetchLabels(labelSetId: number) {
    return auth_fetch(`${config.backend}/api/labels/${labelSetId}/`).then(checkSuccess);
}

interface RequestError {
    error: string;
}

function isRequestError(object: any): object is RequestError {
    return 'error' in object;
}

type LabelInSelection = {
    annotatorCellLabelSelection: number;
    dateCreated: string;
    id: number;
    label: number;
    selectedBy?: SelectedBy;
};

type FreetextInSelection = {
    annotatorCellLabelSelection: number;
    dateCreated: string;
    id: number;
    freetext: string;
};

type AnnotatorCellLabelSelection = {
    annotator: number;
    cell: number;
    confidence: number;
    dateModified: string;
    id: number;
    labelGroup: number;
};

type SingleCellDisplayState = {
    labelGroups: LabelGroup[];
    cellLabelSelections: AnnotatorCellLabelSelection[];
    labelsInSelections: LabelInSelection[];
    labels: Label[];
    collapsed: boolean[];
    loaded: boolean;
    expandedLabelGroups: number[];
    lastCell: null;
    jumpTo: null;
    allowFreetext: false;
};

type SingleCellDisplayProps = {
    user: User;
    pixelDiameterInMicrometer: number;
    showPrevious: boolean;
    showNext: boolean;
    // onUpdateSelection: any;
    onPrevious: any;
    onNext: any;
    onJumpTo: any;
    suggestions: any[];
    annotationCountText: string;
    title: string;
    disabled: boolean;
    /**
     * The component's title.
     * @default 'Img'
     * */
    cell: Cell;
    loaded: boolean;
    labelSetId: number;
    allowFreetext: boolean;
    hiddenLabelGroups?: number[];
};

enum SelectedBy {
    ANNOTATOR = 'annotator',
    AI = 'ai',
    RULES = 'rules',
    NONE = 'none',
    UNKNOWN = 'unknown',
}

type SelectedLabel = Label & {
    selectedBy: SelectedBy;
};

type SelectedLabelsByGroup = LabelGroup & {
    selectedLabels: SelectedLabel[];
};

// type CellData = {
//     id: number;
//     dataset: number;
//     wsi: number;
//     segmentationSetId: number;
//     ressourceUrl: string;
//     lastAnnotationDate: Date;
//     firstAnnotationDate: Date;
//     eccentricity: number;
//     equivalentDiameter: number;
//     perimeter: number;
//     convexArea: number;
//     meanIntensity: number;
// }
function nonCellSentenceBuilder(selectedLabelsByGroups: SelectedLabelsByGroup[]) {
    const labelSentence = ' a non-cell';
    const nonCellType = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 5);
    if (typeof nonCellType !== 'undefined' && nonCellType.selectedLabels.length > 0) {
        return `${labelSentence} ${nonCellType.selectedLabels[0].name}`;
    }
    return `${labelSentence} object`;
}

function cellSentenceBuilder(selectedLabelsByGroups: SelectedLabelsByGroup[], isTypical: boolean) {
    let labelSentence = '';
    // let is_typical = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 11);
    //
    labelSentence += ` ${isTypical ? 'a typical' : 'an atypical'} `;
    //
    // if(typeof is_typical !== 'undefined' && is_typical.selectedLabels.length > 0) {
    //     if (is_typical.selectedLabels[0].id === 70 ) {
    //         // atypical
    //         labelSentence += ' an atypical'
    //     } else {
    //         // typical
    //         labelSentence += ' a typical';
    //     }
    // }
    const cellType = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 5);
    if (typeof cellType !== 'undefined' && cellType.selectedLabels.length > 0) {
        labelSentence += ` ${cellType.selectedLabels[0].name}`;
    } else {
        labelSentence += ' cell';
    }
    const lineage = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 9);
    if (typeof lineage !== 'undefined' && lineage.selectedLabels.length > 0 && lineage.selectedLabels[0].id !== 74) {
        // 74 === None cellLinage) {
        labelSentence += ` from the ${lineage.selectedLabels[0].name}`;
        if (lineage.selectedLabels[0].id === 61) {
            // Myeloid lineage
            const stainingType = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 10);
            if (typeof stainingType !== 'undefined' && stainingType.selectedLabels.length > 0) {
                labelSentence += `. Its staining characteristic is ${stainingType.selectedLabels[0].name}`;
            }
        }
    }
    return labelSentence + `.`;
}

function labelSentenceBuilder(selectedLabelsByGroups: SelectedLabelsByGroup[], isTypical: boolean) {
    // Label groups: Cell type (5), Linage (9), Staining types (10), typical (11), is_cell (12)
    const labelSentence = 'This is';
    const cellType = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 5);
    if (typeof cellType !== 'undefined' && cellType.selectedLabels.length > 0 && cellType.selectedLabels[0].id !== 73) {
        // 73 === None cellType
        if (cellType.selectedLabels[0].id === 55) {
            return labelSentence + ' a non-cell artifact.';
        } else {
            return labelSentence + cellSentenceBuilder(selectedLabelsByGroups, isTypical);
        }
    } else {
        // fallback
        return labelSentence + ` ${isTypical ? 'a typical' : 'an atypical'} cell.`;
    }

    // let is_cell = selectedLabelsByGroups.find((group: SelectedLabelsByGroup) => group.id === 12);
    // if(typeof is_cell !== 'undefined' && is_cell.selectedLabels.length > 0) {
    //     if (is_cell.selectedLabels[0].id === 72 ) {
    //         // Non-cell
    //         labelSentence += nonCellSentenceBuilder(selectedLabelsByGroups);
    //     } else {
    //         // Cell
    //         labelSentence += cellSentenceBuilder(selectedLabelsByGroups);
    //     }
    // }

    // selectedLabelsByGroups.forEach((group: SelectedLabelsByGroup) => {
    //     if(group.selectedLabels.length > 0) {
    //         labelSentence += group.selectedLabels.map((label: SelectedLabel) => label.name).join(', ');
    //         labelSentence += ' ';
    //     }
    // });
    // return labelSentence;
}

// const HIDDEN_LABELS = new Map<number, boolean>();
// HIDDEN_LABELS.set(73, true);
// HIDDEN_LABELS.set(74, true);

const DEFAULT_LABEL_CONTROLS = new Map<number, LabelControl>();

enum LabelState {
    ENABLED,
    DISABLED,
    SELECTED,
}

class LabelControl {
    // public enableStates: Map<number, LabelState> = new Map();
    public currentState;
    public enableRules: Map<number, LabelControl> = new Map();
    public disableRules: Map<number, LabelControl> = new Map();
    public selectRules: Map<number, LabelControl> = new Map();

    constructor(public label: Label, public defaultState: LabelState = LabelState.ENABLED) {
        this.currentState = defaultState;
    }

    // public enable(states: LabelState[]) {
    //     states.forEach((state) => {
    //         // selectedState = this;
    //         enableStates.set(label.id, state);
    //     });
    // }

    public addEnableRules(labelControls: LabelControl[]) {
        labelControls.forEach((labelControl) => {
            if (this.disableRules.has(labelControl.label.id)) {
                console.warn(
                    `Label ${labelControl.label.name} is disabled by ${this.label.name} and shouldn't be enabled.`,
                );
            }
            this.enableRules.set(labelControl.label.id, labelControl);
        });
    }

    public addDisableRules(labelControls: LabelControl[]) {
        labelControls.forEach((labelControl) => {
            // Can't disable selectRule
            if (!this.selectRules.has(labelControl.label.id)) {
                if (this.disableRules.has(labelControl.label.id)) {
                    console.warn(
                        `Label ${labelControl.label.name} is enabled by ${this.label.name} and shouldn't be disabled.`,
                    );
                }
                this.disableRules.set(labelControl.label.id, labelControl);
            }
        });
    }

    public addSelectRules(labelControls: LabelControl[]) {
        labelControls.forEach((labelControl) => {
            this.selectRules.set(labelControl.label.id, labelControl);
            this.disableRules.delete(labelControl.label.id);
            labelControl.disableRules.delete(this.label.id);
        });
    }

    public setState(state: LabelState) {
        this.currentState = state;
    }

    public enable() {
        this.currentState = LabelState.ENABLED;
    }

    public disable() {
        this.currentState = LabelState.DISABLED;
    }
}

const STATIC_LABELSET_LABELS = [
    { id: 1, name: 'Promyelocyte', labelGroup: 5 },
    { id: 2, name: 'Myelocyte', labelGroup: 5 },
    { id: 3, name: 'Blast', labelGroup: 5 },
    { id: 4, name: 'Immature Monocyte', labelGroup: 5 },
    { id: 5, name: 'Immature Lymphocyte', labelGroup: 5 },
    { id: 6, name: 'erythrocyte', labelGroup: 5 },
    { id: 14, name: 'Smudge cell', labelGroup: 5 },
    { id: 16, name: 'Erythroblast', labelGroup: 5 },
    { id: 17, name: 'Lymphocyte', labelGroup: 5 },
    { id: 21, name: 'Monocyte', labelGroup: 5 },
    { id: 23, name: 'Metamyelocyte', labelGroup: 5 },
    { id: 25, name: 'Plasma cell', labelGroup: 5 },
    { id: 27, name: 'Megakaryocyte', labelGroup: 5 },
    { id: 41, name: 'Ringed sideroblast', labelGroup: 5 },
    { id: 42, name: 'LGL Cell', labelGroup: 5 },
    { id: 43, name: 'Reticulocyte', labelGroup: 5 },
    { id: 45, name: 'Makrophage', labelGroup: 5 },
    { id: 46, name: 'Not recognizeable', labelGroup: 5 },
    { id: 47, name: 'Promonocyte', labelGroup: 5 },
    { id: 48, name: 'Proerythroblast', labelGroup: 5 },
    { id: 49, name: 'Thrombocyte', labelGroup: 5 },
    { id: 51, name: 'Osteoblast', labelGroup: 5 },
    { id: 52, name: 'Osteoclast', labelGroup: 5 },
    { id: 53, name: 'Stromal cell', labelGroup: 5 },
    { id: 54, name: 'Non-hematopoietic cell', labelGroup: 5 },
    { id: 55, name: 'Artifact', labelGroup: 5 },
    { id: 56, name: 'Band granulocyte', labelGroup: 5 },
    { id: 57, name: 'Segmented granulocyte', labelGroup: 5 },
    { id: 61, name: 'Myeloid lineage', labelGroup: 9 },
    { id: 62, name: 'Lymphoid lineage', labelGroup: 9 },
    { id: 63, name: 'Erythroid lineage', labelGroup: 9 },
    { id: 64, name: 'Monocyte-Megakaryocytic lineage', labelGroup: 9 },
    { id: 65, name: 'Basophil', labelGroup: 10 },
    { id: 66, name: 'Eosinophil', labelGroup: 10 },
    { id: 67, name: 'Neutrophil', labelGroup: 10 },
    { id: 68, name: 'Unknown', labelGroup: 10 },
    { id: 69, name: 'Typical', labelGroup: 11 },
    { id: 70, name: 'Atyptical', labelGroup: 11 },
    { id: 78, name: 'Mast Cell', labelGroup: 5 },
    // { id: 73, name: 'None', labelGroup: 5 },
    // { id: 74, name: 'None', labelGroup: 9 },
];

type LabelId = number;
type LabelGroupId = number;
type InnerGroupOfIds = LabelId[];
type InnerGroupOfLabels = Label[];
type InnerLabelGroupSortingRules = Map<LabelGroupId, InnerGroupOfIds[]>;

const INNER_LABELGROUP_LABEL_SORTING_RULES: InnerLabelGroupSortingRules = new Map();
INNER_LABELGROUP_LABEL_SORTING_RULES.set(5, [
    [3], // Blast
    [1, 2, 23, 56, 57, 78], // Promyelocyte, Myelocyte, Metamyelocyte, Band granulocyte, Segmented granulocyte
    [48, 75, 76, 77, 6, 41, 43], // Proerythroblast, Bas. Erythroblast, Polychrom. Erythroblast, Ortho. Erythroblast, erythrocyte, Ringed sideroblast, Reticulocyte
    [5, 17, 42, 25], // Immature Lymphocyte, Lymphocyte, LGL Cell, Plasma cell
    [47, 4, 21, 45], // Promonocyte, Immature Monocyte, Monocyte, Makrophage
    [27, 49, 51, 52], // Megakaryocyte, Thrombocyte, Osteoblast, Osteoclast,
    [53, 14, 54, 55], // Stromal cell, Smudge cell, Non-hematopoietic cell, Artifact
]);

const sortLabelsByLabelGroupsAndSortRules = (labels: Label[], groupsSortRules: InnerLabelGroupSortingRules) => {
    const sortedLabels: Map<LabelGroupId, InnerGroupOfLabels[]> = new Map();
    // sortedLabels.set(labelGroupId,
    const labelGroupIds = labels.reduce((acc, label) => {
        acc.set(label.labelGroup, true);
        return acc;
    }, new Map<LabelGroupId, boolean>());
    for (const labelGroupId of labelGroupIds.keys()) {
        if (groupsSortRules.has(labelGroupId)) {
            const subGroups = groupsSortRules.get(labelGroupId) as InnerGroupOfIds[];
            const sortedLabelsOfSubGroup: InnerGroupOfLabels[] = subGroups.map(
                (labelIds: LabelId[]) =>
                    labelIds
                        .map((id: LabelId) => labels.find((label) => label.id === id))
                        .filter((label) => typeof label !== 'undefined') as InnerGroupOfLabels,
            );
            const sortedLabelsIds = Array.from(sortedLabelsOfSubGroup.values())
                .flat()
                .map((l: Label) => l.id);
            const remainingLabels = labels.filter(
                (label) => label.labelGroup === labelGroupId && !sortedLabelsIds.includes(label.id),
            );
            if (remainingLabels.length > 0) {
                sortedLabelsOfSubGroup.push(remainingLabels.sort((a: Label, b: Label) => a.name.localeCompare(b.name)));
            }
            sortedLabels.set(labelGroupId, sortedLabelsOfSubGroup);
        } else {
            sortedLabels.set(labelGroupId, [
                labels
                    .filter((label) => label.labelGroup === labelGroupId)
                    .sort((a: Label, b: Label) => a.name.localeCompare(b.name)),
            ]);
        }
    }
    return sortedLabels;
};

STATIC_LABELSET_LABELS.forEach((label) => {
    if (!DEFAULT_LABEL_CONTROLS.has(label.id)) {
        const state = new LabelControl(label);
        DEFAULT_LABEL_CONTROLS.set(label.id, state);
    }
});

const STATIC_LABELSTATES_BY_LABELGROUP = STATIC_LABELSET_LABELS.reduce((acc, label) => {
    if (typeof acc[label.labelGroup] === 'undefined') {
        acc[label.labelGroup] = [];
    }
    acc[label.labelGroup].push(label);
    return acc;
}, [] as Label[][]);

STATIC_LABELSTATES_BY_LABELGROUP[10].forEach((label) => {
    if (DEFAULT_LABEL_CONTROLS.has(label.id)) {
        const control = DEFAULT_LABEL_CONTROLS.get(label.id) as LabelControl;
        control.defaultState = LabelState.DISABLED;
        control.currentState = LabelState.DISABLED;
    }
});

const enableOthers = (disable: number[]) => {
    return STATIC_LABELSET_LABELS.filter((e) => !disable.includes(e.id)).map((e) => e.id);
};

// Always enable other labels in the same group
// DEFAULT_LABEL_CONTROLS.forEach((labelControl) => {
//     let labelControls = STATIC_LABELSTATES_BY_LABELGROUP[labelControl.label.labelGroup]
//         .map((l : Label) => DEFAULT_LABEL_CONTROLS.get(l.id))
//         .filter((e) => typeof e !== 'undefined') as LabelControl[];
//     labelControl.enable(labelControls);
// });

// Auto select meta labels
// DEFAULT_LABEL_CONTROLS.forEach((labelControl) => {
//     switch(labelControl.label.id) {
//         case 3: // Blast
//             labelControl.enable(STATIC_LABELSTATES_BY_LABELGROUP[9].map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
//             break;
//         case 1: // Promyelocyte
//         case 2: // Myelocyte
//         case 23: // Metamyelocyte
//         case 56: // Band granulocyte
//         case 57: // Segmented granulocyte
//             labelControl.select(DEFAULT_LABEL_CONTROLS.get(61) as LabelControl);
//             break;
//         case 47: // Promonocyte
//         case 4: // Immature Monocyte
//         case 21: // Monocyte
//         case 45: // Makrophage
//             labelControl.select(DEFAULT_LABEL_CONTROLS.get(64) as LabelControl);
//             break;
//         case 17: // Lymphocyte
//         case 5: // Immature Lymphocyte
//         case 42: // LGL Cell
//         case 25: // Plasma cell
//             labelControl.select(DEFAULT_LABEL_CONTROLS.get(62) as LabelControl);
//             break;
//         case 16: // Erythroblast
//         case 48: // Proerythroblast
//         case 6: // erythrocyte
//         case 41: // Ringed sideroblast
//         case 43: // Reticulocyte
//             labelControl.select(DEFAULT_LABEL_CONTROLS.get(63) as LabelControl);
//             break;
//         case 14: // Smudge cell
//             break;
//         case 46: // Not recognizeable
//             break;
//         case 27: // Megakaryocyte
//         case 49: // Thrombocyte
//             break;
//         case 51: // Osteoblast
//         case 52: // Osteoclast
//             break;
//         case 53: // Stromal cell
//             break;
//         case 54: // Non-hematopoietic cell
//             break;
//         case 55: // Artifact
//             break;
//         case 61: // Myeloid lineage
//             labelControl.select(DEFAULT_LABEL_CONTROLS.get(67) as LabelControl);
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 62: // Lymphoid lineage
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 63: // Erythroid lineage
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 64: // Monocyte-Megakaryocytic lineage
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 65: // Basophil
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 66: // Eosinophil
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 67: // Neutrophil
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//         case 68: // Unknown
//             labelControl.enable([DEFAULT_LABEL_CONTROLS.get(3) as LabelControl]);
//             break;
//     };
// });

DEFAULT_LABEL_CONTROLS.forEach((labelControl) => {
    switch (labelControl.label.id) {
        case 3: // Blast
            break;
        case 1: // Promyelocyte
        case 2: // Myelocyte
        case 23: // Metamyelocyte
        case 56: // Band granulocyte
        case 57: // Segmented granulocyte
        case 78: // Mast Cell
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[9]
                    .filter((l: Label) => l.id !== 61)
                    .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl),
            );
            labelControl.addSelectRules([DEFAULT_LABEL_CONTROLS.get(61) as LabelControl]);
            labelControl.addSelectRules([DEFAULT_LABEL_CONTROLS.get(67) as LabelControl]);
            break;
        case 47: // Promonocyte
        case 4: // Immature Monocyte
        case 21: // Monocyte
        case 45: // Makrophage
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[9]
                    .filter((l: Label) => l.id !== 64)
                    .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl),
            );
            labelControl.addSelectRules([DEFAULT_LABEL_CONTROLS.get(64) as LabelControl]);
            break;
        case 5: // Immature Lymphocyte
        case 17: // Lymphocyte
        case 25: // Plasma cell
        case 42: // LGL Cell
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[9]
                    .filter((l: Label) => l.id !== 62)
                    .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl),
            );
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            labelControl.addSelectRules([DEFAULT_LABEL_CONTROLS.get(62) as LabelControl]);
            break;
        // case 16: // Erythroblast
        case 48: // Proerythroblast
        case 6: // erythrocyte
        case 41: // Ringed sideroblast
        case 43: // Reticulocyte
        case 75: // Bas. Erythroblast
        case 76: // Polychrom. Erythroblast
        case 77: // Ortho. Erythroblast
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[9]
                    .filter((l: Label) => l.id !== 63)
                    .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl),
            );
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            labelControl.addSelectRules([DEFAULT_LABEL_CONTROLS.get(63) as LabelControl]);
            break;
        case 46: // Not recognizeable
            break;
        case 14: // Smudge cell
        // break;
        case 27: // Megakaryocyte
        case 49: // Thrombocyte
        // break;
        case 51: // Osteoblast
        case 52: // Osteoclast
        // break;
        case 53: // Stromal cell
        // break;
        case 54: // Non-hematopoietic cell
        // break;
        case 55: // Artifact
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[9].map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl),
            );
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            break;
        case 61: // Myeloid lineage
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[5]
            //     .filter((l: Label) =>  l.id !== 1 && l.id !== 2 && l.id !== 3 && l.id !== 23 && l.id !== 56 && l.id !== 57)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            labelControl.addEnableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            labelControl.addSelectRules([DEFAULT_LABEL_CONTROLS.get(67) as LabelControl]);
            break;
        case 62: // Lymphoid lineage
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[5]
            //     .filter((l: Label) =>  l.id !== 3 && l.id !== 17 && l.id !== 5 && l.id !== 42 && l.id !== 25)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            break;
        case 63: // Erythroid lineage
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[5]
            //     .filter((l: Label) =>  l.id !== 3 && l.id !== 16 && l.id !== 48 && l.id !== 6 && l.id !== 41 && l.id !== 43)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            break;
        case 64: // Monocyte-Megakaryocytic lineage
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[5]
            //     .filter((l: Label) =>  l.id !== 3 && l.id !== 47 && l.id !== 4 && l.id !== 21 && l.id !== 45)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            labelControl.addDisableRules(
                STATIC_LABELSTATES_BY_LABELGROUP[10].map(
                    (l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl,
                ),
            );
            break;
        case 65: // Basophil
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[9]
            //     .filter((l: Label) =>  l.id !== 61)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            break;
        case 66: // Eosinophil
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[9]
            //     .filter((l: Label) =>  l.id !== 61)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            break;
        case 67: // Neutrophil
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[9]
            //     .filter((l: Label) =>  l.id !== 61)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            break;
        case 68: // Unknown
            // labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[9]
            //     .filter((l: Label) =>  l.id !== 61)
            //     .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
            break;
        // case 73: // None cellType
        //     labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[10]
        //         .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
        //     break;
        // case 74: // None cellLinage
        //     labelControl.disable(STATIC_LABELSTATES_BY_LABELGROUP[10]
        //         .map((l: Label) => DEFAULT_LABEL_CONTROLS.get(l.id) as LabelControl));
        //     break;
    }
});

// function toggle(id: any) {
//     let collapsed = collapsed;
//     collapsed[id] = !collapsed[id];
//     setState({ collapsed: collapsed });
// }

function fetchCellLabelSelections(
    user: User,
    cell: Cell,
    labelGroup: LabelGroup,
): Promise<AnnotatorCellLabelSelection[]> {
    return auth_fetch(`${config.backend}/api/cell_label_selections/${user.id}/${cell.id}/${labelGroup.id}/`).then(
        checkSuccess,
    );
}

// function updateCellLabelSelection(selection: any) {
//     const dateTimeFormat = new Intl.DateTimeFormat('de', {
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//     });
//     const [
//         { value: day },
//         ,
//         { value: month },
//         ,
//         { value: year },
//         ,
//         { value: hour },
//         ,
//         { value: minute },
//         ,
//         { value: second },
//     ] = dateTimeFormat.formatToParts(Date.now());

//     selection.dateModified = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
//     return auth_fetch(`${config.backend}/api/cell_label_selections/`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(selection),
//     })
//         .then(checkSuccess)

//         .then((data) => {
//             let selections = cellLabelSelections;
//             selections[data.labelGroup] = data;
//             setState({ cellLabelSelections: selections });
//             fetchLabelsInSelection(data.id);
//         });
// }

function fetchLabelsInSelection(selectionId: number): Promise<LabelInSelection[] | RequestError> {
    return auth_fetch(`${config.backend}/api/labels_in_selection/${selectionId}/`).then(checkSuccess);
}

function fetchFreetextsInSelection(selectionId: number): Promise<FreetextInSelection[]> {
    return auth_fetch(`${config.backend}/api/freetexts_in_selection/${selectionId}/`).then(checkSuccess);
}

function backendDeleteFreetextInSelection(freetextInSelectionId: number) {
    return auth_fetch(`${config.backend}/api/freetext_in_selection/${freetextInSelectionId}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

function backendAddFreetextToSelection(freetext: string, selectionId: number): Promise<FreetextInSelection> {
    const dateTimeFormat = new Intl.DateTimeFormat('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    const [
        { value: day },
        ,
        { value: month },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: second },
    ] = dateTimeFormat.formatToParts(Date.now());

    const labelInSelection = {
        dateCreated: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
        freetext: freetext,
        annotatorCellLabelSelection: selectionId,
    };
    return auth_fetch(`${config.backend}/api/freetexts_in_selections/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(labelInSelection),
    }).then(checkSuccess);
}

/*   updateCellLabelSelection(selection, cellLabelSelections, onUpdateSelection) {
  // Should make sure there is only one selection for each group.id (e.g. when working with "forgotten" selections after a certain time period)
let selectionIdx = cellLabelSelections.findIndex(el => el.labelGroup === selection.labelGroup);
  if(typeof cellLabelSelections[selectionIdx] === 'undefined') {
    cellLabelSelections.push(selection);
  } else {
    cellLabelSelections[selectionIdx] = selection;
  }
  onUpdateSelection(cellLabelSelections);
} */

function backendDeleteLabelInSelection(labelInSelectionId: number) {
    /*     const dateTimeFormat = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit', second: '2-digit'}) 
const [{ value: day },,{ value: month },,{ value: year },,{ value: hour },,{ value: minute },,{ value: second }] = dateTimeFormat.formatToParts(Date.now()) 

let labelInSelection = {
    dateCreated: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
    label: labelId,
    annotatorCellLabelSelection: selectionId
}
*/

    return auth_fetch(`${config.backend}/api/label_in_selection/${labelInSelectionId}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        } /* , 
    body: JSON.stringify(labelInSelection), */,
    });
}

function backendAddLabelToSelection(
    labelId: number,
    selectionId: number,
    selectedBy?: SelectedBy,
): Promise<LabelInSelection> {
    const dateTimeFormat = new Intl.DateTimeFormat('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    const [
        { value: day },
        ,
        { value: month },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: second },
    ] = dateTimeFormat.formatToParts(Date.now());

    const labelInSelection = {
        dateCreated: `${year}-${month}-${day} ${hour}:${minute}:${second}`,
        label: labelId,
        selectedBy: typeof selectedBy !== 'undefined' ? selectedBy : SelectedBy.UNKNOWN,
        annotatorCellLabelSelection: selectionId,
    };
    return auth_fetch(`${config.backend}/api/labels_in_selections/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(labelInSelection),
    }).then(checkSuccess);
}

type SelectionWithLabelsInSelection = {
    lis: LabelInSelection[];
    selection: AnnotatorCellLabelSelection;
};

type LabelWithSelection = {
    label: Label;
    selection: AnnotatorCellLabelSelection;
    selectedBy?: SelectedBy;
};

const getLabelsDisabledByLabelId = (labelId: number, labelControls: Map<number, LabelControl>): number[] => {
    const disabledLabels: number[] = [];
    if (labelControls.has(labelId)) {
        for (const key of labelControls.get(labelId)!.disableRules.keys()) {
            disabledLabels.push(key);
        }
    }
    return disabledLabels;
};

// Get all auto select labels without filter so that we know what to remove if label is deselected
const getLabelsToAutoSelect = (
    labelId: number,
    labels: Label[],
    cellLabelSelections: AnnotatorCellLabelSelection[],
    labelsInSelections: LabelInSelection[],
    labelControls: Map<number, LabelControl>,
): LabelWithSelection[] => {
    // let labels = labels; //.find((l: Label) => l.id === labelId);
    const labelsToAdd: LabelWithSelection[] = [];

    const currentLabel = labels.find((l: Label) => l.id === labelId);
    const labelControl = labelControls.get(labelId);
    if (typeof labelControl !== 'undefined' && typeof currentLabel !== 'undefined') {
        const autoSelectLabels = Array.from(labelControl.selectRules?.values()).map((l: LabelControl) => l.label);
        // let autoSelectLabels = recursivelyFollowSelectRules(labelControl, new Map<number, true>())
        //     .map((l: LabelControl) => l.label);
        if (typeof autoSelectLabels !== 'undefined' && autoSelectLabels.length > 0) {
            const selectedLabels: SelectedLabel[] = labelsInSelections
                .map(
                    (lis: LabelInSelection) =>
                        ({
                            ...labels.find((l: Label) => lis.label === l.id),
                            SelectedBy: lis.selectedBy,
                        } as unknown as SelectedLabel),
                )
                .filter((l: SelectedLabel | undefined) => typeof l !== 'undefined') as SelectedLabel[];
            const autoSelectLabelsGroups = autoSelectLabels.reduce((groups, label) => {
                let groupLabels: Label[] = [];
                if (groups.has(label.labelGroup)) {
                    groupLabels = groups.get(label.labelGroup) as Label[];
                }
                groupLabels.push(label);
                groups.set(label.labelGroup, groupLabels);
                return groups;
            }, new Map<number, Label[]>());

            autoSelectLabelsGroups.forEach((groupLabels: Label[], labelGroupId: number) => {
                const selectedLabelsOfAutoLabelGroup = selectedLabels.filter(
                    (l: SelectedLabel) => labelGroupId === l.labelGroup,
                );
                // TODO only works as long as all groups are single select!
                // Find correct selection for autoSelectLabel labelGroup
                const autoSelectLabelGroupSelection = cellLabelSelections.find(
                    (s: AnnotatorCellLabelSelection) => labelGroupId === s.labelGroup,
                );
                if (typeof autoSelectLabelGroupSelection !== 'undefined') {
                    // There is a selection for the autoSelectLabel labelGroup
                    if (selectedLabelsOfAutoLabelGroup.length === 0) {
                        // No label (in autoSelect labelGroup) selected yet
                        groupLabels.forEach((autoSelectLabel: Label) => {
                            labelsToAdd.push({
                                label: autoSelectLabel,
                                selection: autoSelectLabelGroupSelection!,
                                selectedBy: SelectedBy.RULES,
                            });
                        });
                    } else if (selectedLabelsOfAutoLabelGroup.length === 1) {
                        // There already is a label inside the selection
                        // Check if it's a newly disabled label
                        const newlyDisabledLabels: number[] = getLabelsDisabledByLabelId(labelId, labelControls);
                        selectedLabelsOfAutoLabelGroup.forEach((preSelectedLabel: SelectedLabel) => {
                            if (newlyDisabledLabels.includes(preSelectedLabel.id)) {
                                // If yes, remove it from selection and add autoSelectLabel
                                // (Removal happens later, just exclude it from labelsToAdd here)
                                groupLabels.forEach((autoSelectLabel: Label) => {
                                    labelsToAdd.push({
                                        label: autoSelectLabel,
                                        selection: autoSelectLabelGroupSelection!,
                                        selectedBy: SelectedBy.RULES,
                                    });
                                });
                            } else {
                                // Otherwise keep it and discard autoSelectLabel
                                labelsToAdd.push({
                                    label: preSelectedLabel,
                                    selection: autoSelectLabelGroupSelection!,
                                    selectedBy: preSelectedLabel.selectedBy,
                                });
                            }
                        });
                    } else {
                        console.error(`Can't handle more than one label selected in labelGroup ${labelGroupId}`);
                        // throw Error(`Can't handle more than one label selected in labelGroup ${labelGroupId}`);
                    }
                }
            });
        }
    }
    return labelsToAdd;
};

function buildDisableLabels(labelsInSelections: LabelInSelection[]) {
    return labelsInSelections.reduce((acc, lis: LabelInSelection) => {
        const labelControl = DEFAULT_LABEL_CONTROLS.get(lis.label);
        if (typeof labelControl !== 'undefined') {
            labelControl.disableRules.forEach((control: LabelControl) => {
                acc.set(control.label.id, true);
            });
            return acc;
        }
        return acc;
    }, new Map<number, boolean>());
}

// function recursivelyFollowSelectRules(control: LabelControl, visitedControls: Map<number, true>): LabelControl[] {
//     let selectedControls = [control];
//     if(visitedControls.has(control.label.id)) {
//         console.warn(`Recursion select rule detected for label ${control.label.name}
//             ${Array.from(visitedControls.keys()).join('->')}`);
//         return selectedControls;
//     }
//     visitedControls.set(control.label.id, true);
//     return selectedControls
//         .concat(Array.from(control.selectRules.values())
//             .map((c: LabelControl) => recursivelyFollowSelectRules(c, visitedControls).flat()).flat());
// }

// function recursivelyEnableRules(control: LabelControl, visitedControls: Map<number, true>) {
//     if(visitedControls.has(control.label.id)) {
//         console.warn(`Recursion enable rule detected for label ${control.label.name}
//             ${Array.from(visitedControls.keys()).join('->')}`);
//         return;
//     }
//     control.enable();
//     visitedControls.set(control.label.id, true);
//     control.enableRules.forEach((c: LabelControl) => {
//         recursivelyEnableRules(c, visitedControls);
//     });
// }

// function recursivelyDisableRules(control: LabelControl, visitedControls: Map<number, true>) {
//     if(visitedControls.has(control.label.id)) {
//         console.warn(`Recursion disable rule detected for label ${control.label.name}
//             ${Array.from(visitedControls.keys()).join('->')}`);
//         return;
//     }
//     control.disable();
//     visitedControls.set(control.label.id, true);
//     control.enableRules.forEach((c: LabelControl) => {
//         recursivelyDisableRules(c, visitedControls);
//     });
// }

function buildLabelControlStatesByRules(labelsWithSelections: LabelWithSelection[]) {
    const labelControls: Map<number, LabelControl> = DEFAULT_LABEL_CONTROLS;
    for (const labelControl of labelControls.values()) {
        labelControl.setState(labelControl.defaultState);
    }
    labelsWithSelections.forEach((lis: LabelWithSelection) => {
        if (typeof lis.label !== 'undefined') {
            const labelControl = labelControls.get(lis.label.id);
            if (typeof labelControl !== 'undefined') {
                // recursivelyDisableRules(labelControl, new Map<number, true>());
                // recursivelyEnableRules(labelControl, new Map<number, true>());
                labelControl.disableRules.forEach((control: LabelControl) => {
                    control.disable();
                });
                labelControl.enableRules.forEach((control: LabelControl) => {
                    control.enable();
                });
            }
        }
    });
    return labelControls;
}

const buildSelectedLabelGroupLabels = (
    labelGroup: LabelGroup,
    labels: Label[],
    labelsInSelections: LabelInSelection[],
    cellLabelSelections: AnnotatorCellLabelSelection[],
) => {
    let l: SelectedLabel[] = [];
    if (
        typeof labelGroup !== 'undefined' &&
        labels.length > 0 &&
        labelsInSelections.length > 0 &&
        cellLabelSelections.length > 0
    ) {
        const selection = cellLabelSelections.find((e: any) => e.labelGroup === labelGroup.id);
        if (typeof selection !== 'undefined') {
            const labelsInSelection = labelsInSelections
                // .filter((lis: LabelInSelection) =>
                //     DEFAULT_LABEL_CONTROLS.get(lis.label)?.defaultState === LabelStateState.ENABLED)
                .filter((lis: LabelInSelection) => lis.annotatorCellLabelSelection === selection!.id);
            if (typeof labelsInSelection !== 'undefined' && labelsInSelection.length > 0) {
                l = labelsInSelection.map(
                    (lis: LabelInSelection) =>
                        ({ ...labels.find((l) => l.id === lis.label), selectedBy: lis.selectedBy } as SelectedLabel),
                ); // If labeled with a label not in labelset from previous sessions
            }
        }
    }
    return l;
};

const isSuggestionChecked = (suggestion: any, labelsInSelections: LabelInSelection[]) => {
    if (typeof suggestion === 'undefined') {
        return false;
    }
    return labelsInSelections.some((el: any) => el.label === suggestion.id);
};

const DEFAULT_FREETEXT_GROUPS = new Map<number, boolean>();
DEFAULT_FREETEXT_GROUPS.set(5, true);

type LabelInputProps = {
    user: User;
    cell: Cell;
    group: LabelGroup;
    label: Label;
    cellLabelSelections: AnnotatorCellLabelSelection[];
    labelsInSelections: LabelInSelection[];
    disabled: boolean;
    toggleCheckbox: (evt: any, selection: any, label: SelectedLabel, labelsInSelections: LabelInSelection[]) => void;
    toggleDeselectableRadio: (
        evt: any,
        selection: any,
        label: SelectedLabel,
        labelsInSelections: LabelInSelection[],
    ) => void;
};

const LabelInput: React.FC<LabelInputProps> = ({
    user,
    cell,
    group,
    label,
    cellLabelSelections,
    labelsInSelections,
    disabled,
    toggleCheckbox,
    toggleDeselectableRadio,
}) => {
    // Should make sure there is only one selection for group.id (e.g. when working with "forgotten" selections after a certain time period)
    const selection = cellLabelSelections.find((e) => e.labelGroup === group.id);
    /* if(typeof selection === 'undefined') {
    selection = buildDefaultLabelSelections(user, cell, group);
} */
    const checked =
        labelsInSelections.findIndex((el, id) => {
            return el.label === label.id;
        }) >= 0;
    /* let updateCb = selection => updateCellLabelSelection(selection, cellLabelSelections, onUpdateSelection); */
    if (group.multiSelect) {
        return (
            <FormCheckbox
                key={label.id}
                value={label.id}
                checked={checked}
                className={`btn btn-checkbox ${checked ? 'btn-success' : 'btn-secondary'} ${
                    disabled ? 'btn-disabled' : ''
                }`}
                onChange={(evt: any) =>
                    toggleCheckbox(evt, selection, { ...label, selectedBy: SelectedBy.ANNOTATOR }, labelsInSelections)
                }
            >
                {label.name}
            </FormCheckbox>
        );
    } else {
        return (
            <span
                key={label.id}
                className={`btn btn-radio ${checked ? 'btn-success' : 'btn-secondary'} ${disabled ? 'disabled' : ''}`}
            >
                <FormRadio
                    value={label.id}
                    checked={checked}
                    onClick={(evt: any) =>
                        toggleDeselectableRadio(
                            evt,
                            selection,
                            { ...label, selectedBy: SelectedBy.ANNOTATOR },
                            labelsInSelections,
                        )
                    }
                    // onChange={(evt: any) => toggleRadio(evt, selection, label.id)}
                >
                    {label.name}
                </FormRadio>
            </span>
        );
        // return (
        //     <FormRadio key={label.id} value={label.id}>
        //         <label>{label.name}</label>
        //     </FormRadio>
        // );
    }
};

const isAtypical = (atypical: SelectionWithLabelsInSelection | undefined) =>
    typeof atypical !== 'undefined' &&
    typeof atypical.lis !== 'undefined' &&
    atypical.lis.length === 1 &&
    atypical.lis[0].label === 70;

const SingleCellDisplay: React.FC<SingleCellDisplayProps> = ({
    user,
    title,
    cell,
    disabled,
    pixelDiameterInMicrometer,
    showPrevious,
    showNext,
    /*onUpdateSelection,*/
    onPrevious,
    onNext,
    onJumpTo,
    suggestions,
    annotationCountText,
    hiddenLabelGroups,
    allowFreetext,
    labelSetId,
}) => {
    const [labelGroups, setLabelGroups] = useState<LabelGroup[]>([]);
    const [cellLabelSelections, setCellLabelSelections] = useState<AnnotatorCellLabelSelection[]>([]);
    const [labelsInSelections, setLabelsInSelections] = useState<LabelInSelection[]>([]);
    const [labels, setLabels] = useState<Label[]>([]);
    const [collapsed, setCollapsed] = useState<boolean[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [expandedLabelGroups, setExpandedLabelGroups] = useState<number[]>([]);
    const [lastCell, setLastCell] = useState<Cell | null>(null);
    const [jumpTo, setJumpTo] = useState<number | null>(null);
    const [freetextsInSelections, setFreetextsInSelections] = useState<FreetextInSelection[]>([]);
    // const [typical, setTypical] = useState<boolean>(true);
    // const [newFreetext, setNewFreetext] = useState<string>('');
    const atypical: SelectionWithLabelsInSelection | undefined = useMemo(() => {
        const selection = cellLabelSelections.find((cls: AnnotatorCellLabelSelection) => cls.labelGroup === 11);
        if (typeof selection !== 'undefined') {
            const lis = labelsInSelections.filter(
                (lis: LabelInSelection) => lis.annotatorCellLabelSelection === selection!.id,
            );
            return { selection: selection, lis: lis };
        }
    }, [cellLabelSelections, labelsInSelections]);

    const selectedLabelsByGroups = labelGroups.map((group: LabelGroup) => {
        return {
            ...group,
            selectedLabels: buildSelectedLabelGroupLabels(group, labels, labelsInSelections, cellLabelSelections),
        };
    });

    const toggleTypical = useCallback(() => {
        if (typeof atypical !== 'undefined') {
            deleteLabelInSelection(atypical.lis[0].id);
            if (isAtypical(atypical)) {
                // Was atypical, now typical
                addLabelToSelection(69, atypical.selection.id, SelectedBy.ANNOTATOR);
            } else {
                // Was typical, now atypical
                addLabelToSelection(70, atypical.selection.id, SelectedBy.ANNOTATOR);
            }
        }
        // if(typeof atypical !== 'undefined' && typeof atypical.selection !== 'undefined'
        //     && typeof atypical.lis !== 'undefined') {
        //     if(atypical.lis.length === 1 && atypical.lis[0].label === 70) {
        //         let atypicalLisId = atypical.lis[0].id;
        //         deleteLabelInSelection(atypicalLisId);
        //         // .then(() => {
        //         //     setLabelsInSelections(lis => lis.filter((e) => e.id !== atypicalLisId));
        //         // });
        //     } else if (atypical.lis.length === 0) {
        //         addLabelToSelection(70, atypical.selection.id, SelectedBy.ANNOTATOR);
        //         // .then(
        //         //     (data: LabelInSelection) => {
        //         //     setLabelsInSelections(lis => [...lis, data]);
        //         // });
        //     } else {
        //         console.error(`Can't handle more than one label selected in labelGroup 11`);
        //     }
        // }
    }, [atypical]);

    const labelsWithSelections = useMemo(
        () =>
            labelsInSelections.map((lis: LabelInSelection) => ({
                selection: cellLabelSelections.find(
                    (s: AnnotatorCellLabelSelection) => s.id === lis.annotatorCellLabelSelection,
                ),
                label: labels.find((l: Label) => l.id === lis.label),
            })),
        [labelsInSelections],
    );
    const labelControls = useMemo(
        () => buildLabelControlStatesByRules(labelsWithSelections as LabelWithSelection[]),
        [labelsWithSelections],
    );
    const freetextsByLabelGroup = useMemo(() => {
        return freetextsInSelections.reduce((acc, fts: FreetextInSelection) => {
            const labelGroup = cellLabelSelections.find(
                (cls: AnnotatorCellLabelSelection) => fts.annotatorCellLabelSelection === cls.id,
            )?.labelGroup;
            if (typeof labelGroup !== 'undefined') {
                let labelGroupFreetexts: FreetextInSelection[] = [];
                if (acc.has(labelGroup)) {
                    labelGroupFreetexts = acc.get(labelGroup) as FreetextInSelection[];
                }
                labelGroupFreetexts.push(fts);
                acc.set(labelGroup, labelGroupFreetexts);
            }
            return acc;
        }, new Map<number, FreetextInSelection[]>());
    }, [freetextsInSelections, cellLabelSelections]);

    const deleteLabelInSelection = (labelInSelectionId: number) => {
        return backendDeleteLabelInSelection(labelInSelectionId).then(() => {
            setLabelsInSelections((lis) => lis.filter((e) => e.id !== labelInSelectionId));
        });
    };

    const addLabelToSelection = (labelId: number, selectionId: number, selectedBy?: SelectedBy) => {
        return backendAddLabelToSelection(labelId, selectionId, selectedBy).then((data: LabelInSelection) => {
            setLabelsInSelections((lis) => [...lis, data]);
        });
    };

    const deleteFreetextInSelection = (freetextInSelectionId: number) => {
        return backendDeleteFreetextInSelection(freetextInSelectionId).then(() => {
            setFreetextsInSelections((fts) => fts.filter((e) => e.id !== freetextInSelectionId));
        });
    };

    const addFreetext = (evt: any, group: LabelGroup) => {
        const selection = cellLabelSelections.find((e) => e.labelGroup === group.id);
        if (typeof selection !== 'undefined') {
            console.log(evt.target.value);
            if (evt.key === 'Enter') {
                backendAddFreetextToSelection(evt.target.value, selection.id).then((data: FreetextInSelection) => {
                    setFreetextsInSelections((fts) => [...fts, data]);
                });
            }
        }
    };

    // const addAutoSelectLabels = (labelId: number) => {
    //     getLabelsToAutoSelect(labelId, labels, cellLabelSelections, labelsInSelections).forEach((lws: LabelWithSelection) => {
    //         addLabelToSelection(lws.label.id, lws.selection.id);
    //     });
    // };

    useEffect(() => {
        if (labelGroups.length === 0) {
            fetchLabelGroups(labelSetId).then(setLabelGroups);
        }
        fetchLabels(labelSetId).then((labels) => setLabels(labels));
    }, []);

    const fetchFreetextsFromCellLabelSelections = (cellLabelSelections: AnnotatorCellLabelSelection[]) => {
        if (typeof cellLabelSelections !== 'undefined') {
            DEFAULT_FREETEXT_GROUPS.forEach((_, labelGroupId) => {
                const selection = cellLabelSelections.find((e) => e.labelGroup === labelGroupId);
                if (typeof selection !== 'undefined') {
                    fetchFreetextsInSelection(selection.id).then((data: FreetextInSelection[]) => {
                        setFreetextsInSelections(data);
                    });
                }
            });
        }
    };

    useEffect(() => {
        if (labelGroups.length && typeof cell !== 'undefined' && cell !== lastCell && user !== null) {
            // setLastCell(cell);
            const selectionsPromises = labelGroups.map((labelGroup, _) =>
                fetchCellLabelSelections(user, cell, labelGroup),
            );
            Promise.all(selectionsPromises).then((data: AnnotatorCellLabelSelection[][]) => {
                setCellLabelSelections(data.flat());
                fetchFreetextsFromCellLabelSelections(data.flat());
            });
        }
    }, [cell, labelGroups]);

    useEffect(() => {
        if (typeof cellLabelSelections !== 'undefined' && cellLabelSelections.length > 0) {
            const lisPromises = cellLabelSelections.map((selection: AnnotatorCellLabelSelection) =>
                fetchLabelsInSelection(selection.id),
            );
            Promise.all(lisPromises).then(async (data: (LabelInSelection[] | RequestError)[]) => {
                let finalLabelsInSelecions: LabelInSelection[] = [];
                for (let i = 0; i < data.length; i++) {
                    const lis = data[i];
                    const selection = cellLabelSelections[i];
                    if (!isRequestError(lis)) {
                        if (lis.length === 0) {
                            if (typeof selection !== 'undefined') {
                                // Set typical, is_cell,( None cellType and None lineage as selected default)
                                if (selection.labelGroup === 11) {
                                    lis.push(await backendAddLabelToSelection(69, selection.id, SelectedBy.RULES));
                                } else if (selection.labelGroup === 12) {
                                    lis.push(await backendAddLabelToSelection(71, selection.id, SelectedBy.RULES));
                                }
                                //  else if (selection.labelGroup === 5) {
                                //     addLabelToSelection(73, selection.id);
                                // } else if (selection.labelGroup === 9) {
                                //     addLabelToSelection(74, selection.id);
                                // }
                            }
                        }
                        finalLabelsInSelecions.push(...lis);
                    }
                }
                const disableRules = buildDisableLabels(finalLabelsInSelecions);
                finalLabelsInSelecions = finalLabelsInSelecions
                    .filter((lis: LabelInSelection) => labels.findIndex((l: Label) => l.id === lis.label) !== -1)
                    .map((lis: LabelInSelection) => (disableRules.get(lis.label) ? null : lis))
                    .filter((lis: LabelInSelection | null) => lis !== null) as LabelInSelection[];
                setLabelsInSelections(finalLabelsInSelecions);
            });
        }
    }, [cellLabelSelections]);

    // useEffect(() => {
    //     if (
    //         labelsInSelections.length &&
    //         user !== null
    //     ) {
    //         let disableRules = buildDisableLabels(labelsInSelections);
    //         let disablePromises = Array.from(disableRules.keys()).map(( key: number) => {
    //             let labelInSelection = labelsInSelections.find((lis: LabelInSelection) => lis.label === key);
    //             if(typeof labelInSelection !== 'undefined') {
    //                 return deleteLabelInSelection(labelInSelection.id)
    //             }
    //             return Promise.resolve()
    //         });
    //         // await Promise.all(disablePromises);
    //     }
    // }, [labelsInSelections]);

    // const clearSelectionsOfRadioLabelGroups = (selections: AnnotatorCellLabelSelection[]) => {
    //     let selectionsToClear: { [x: number]: boolean } = selections.reduce(
    //         (clear, selection: AnnotatorCellLabelSelection) => ({...clear, [selection.id]: true}),
    //         {});
    //     labelsInSelections
    //         .filter((el: any) => selectionsToClear[el.annotatorCellLabelSelection])
    //         .forEach((el: any) => deleteLabelInSelection(el.id));
    // }

    const toggleDeselectableRadio = (evt: any, selection: any, label: SelectedLabel) => {
        console.log('radio');
        const deselect = labelsInSelections.some((el: any) => el.label === label.id);
        let labelsToAutoSelect: LabelWithSelection[] = [];
        labelsToAutoSelect = getLabelsToAutoSelect(
            label.id,
            labels,
            cellLabelSelections,
            labelsInSelections,
            labelControls,
        );
        const labelsToSelect = labelsToAutoSelect.concat({
            label: label,
            selection: selection,
            selectedBy: label.selectedBy,
        });
        const selectionsOfSelectedLabels: AnnotatorCellLabelSelection[] = labelsToSelect.map(
            (lws: LabelWithSelection) => lws.selection,
        );

        const newlyDisabledLabels: number[] = labelsToSelect
            .map((lws: LabelWithSelection) => getLabelsDisabledByLabelId(lws.label.id, labelControls))
            .flat();

        const deletePromises: Promise<void>[] = [];
        labelsInSelections.forEach((lis: LabelInSelection) => {
            // Remove any labels that are disabled by new selection
            // and any labels that in the same groups as the new selection (if not multi select <- TODO)
            if (
                selectionsOfSelectedLabels.findIndex((selection) => selection.id === lis.annotatorCellLabelSelection) >=
                    0 ||
                newlyDisabledLabels.includes(lis.label)
            ) {
                deletePromises.push(deleteLabelInSelection(lis.id));
            }
        });

        // clearSelectionsOfRadioLabelGroups(selectionsOfSelectedLabels);
        if (!deselect) {
            Promise.all(deletePromises).then(() => {
                labelsToSelect.forEach((lws: LabelWithSelection) => {
                    addLabelToSelection(lws.label.id, lws.selection.id, lws.selectedBy);
                });
            });
        }
    };

    // const toggleRadio = (evt: any, selection: AnnotatorCellLabelSelection, labelId: number) => {
    //     console.log('radio');
    //     let labelsToAutoSelect = getLabelsToAutoSelect(labelId, labels, cellLabelSelections, labelsInSelections);
    //     let selectionsOfSelectedLabels = labelsToAutoSelect.map((lws: LabelWithSelection) => lws.selection).concat(selection);
    //     clearSelectionsOfRadioLabelGroups(selectionsOfSelectedLabels);
    //     addLabelToSelection(labelId, selection.id);
    //     addAutoSelectLabels(labelId);
    // };

    const toggleCheckbox = useCallback(
        (evt: any, selection: any, label: Label) => {
            console.log('checkbox');
            let deleted = false;
            const labelsToAutoSelect = getLabelsToAutoSelect(
                label.id,
                labels,
                cellLabelSelections,
                labelsInSelections,
                labelControls,
            );
            const selectionsOfSelectedLabels = labelsToAutoSelect
                .map((lws: LabelWithSelection) => lws.selection)
                .concat(selection);
            // TODO handle multi select for auto labeling
            // let selectionsToClear: { [x: number]: boolean } = selectionsOfSelectedLabels.reduce(
            //     (clear, selection: AnnotatorCellLabelSelection) => ({...clear, [selection.id]: true}),
            //     {});
            labelsInSelections
                .filter((el) => selection === el.annotatorCellLabelSelection)
                .forEach((el) => {
                    if (el.label === label.id) {
                        deleteLabelInSelection(el.id);
                        deleted = true;
                    }
                });
            if (!deleted) {
                addLabelToSelection(label.id, selection.id);
                // TODO Fix auto labeling for multi select
                // autoAddLabelStatesSelect(labelId, selection.id, true);
            }
        },
        [labelsInSelections],
    );

    const toggleSuggestion = (suggestion: any, labelGroups: LabelGroup[]) => {
        const group = labelGroups.find((e) => e.id === suggestion.labelGroup);
        if (typeof group !== 'undefined') {
            const selection = cellLabelSelections.find((e) => e.labelGroup === group?.id);
            if (group.multiSelect) {
                toggleCheckbox(null, selection, suggestion.id);
            } else if (!isSuggestionChecked(suggestion, labelsInSelections)) {
                toggleDeselectableRadio(null, selection!, suggestion);
            }
        }
    };

    const labelsByGroupsSortedBySortRules = useMemo(
        () => sortLabelsByLabelGroupsAndSortRules(labels, INNER_LABELGROUP_LABEL_SORTING_RULES),
        [labels],
    );

    const visibleLabelGroups = labelGroups.filter((g: LabelGroup) => hiddenLabelGroups?.indexOf(g.id) === -1);

    //retrieveLabelGroups(user, cell);
    return (
        <Card small>
            <CardHeader className="border-bottom single-cell-header">
                <h6 className="m-0">
                    {title}
                    {showPrevious && (
                        <Button theme="secondary" onClick={onPrevious} disabled={disabled}>
                            Previous
                        </Button>
                    )}
                    {showNext && (
                        <Button theme="success" onClick={onNext} disabled={disabled}>
                            Next
                        </Button>
                    )}
                    {/* <span style={{ marginLeft: '2em' }}>{annotationCountText}</span> */}
                    <JumpToContainer>
                        <JumpToInput
                            placeholder="Jump to"
                            className="mb-2"
                            onChange={(e: any) => setJumpTo(e.target.value)}
                        ></JumpToInput>
                        <GoJumpTo theme="secondary" onClick={() => onJumpTo(jumpTo)} disabled={disabled}>
                            Go
                        </GoJumpTo>
                    </JumpToContainer>
                </h6>
                <div className="block-handle" />
            </CardHeader>

            <CardBody className="border-top">
                {cell && !disabled && (
                    <div className="cell-meta-container">
                        <div className="cell-image-container">
                            <img className="cell-image" alt={`cell ${cell.id}`} src={`${cell.ressourceUrl}`} />
                        </div>
                        <div className="single-cell-container">
                            <span>Eccentricity:</span>
                            <span>{cell.eccentricity.toFixed(2)}</span>
                            <span>EquiDiameter:</span>
                            <span>{(cell.equivalentDiameter * pixelDiameterInMicrometer).toFixed(2)}&#181;m</span>
                            <span>Perimeter:</span>
                            <span>{(cell.perimeter * pixelDiameterInMicrometer).toFixed(2)}&#181;m</span>
                            <span>Area:</span>
                            <span>{(cell.convexArea * Math.pow(pixelDiameterInMicrometer, 2)).toFixed(2)}&#181;m</span>
                            <span>Mean intensity:</span>
                            <span>{cell.meanIntensity.toFixed(2)}</span>
                        </div>
                        <div className="selection-container">
                            {/* <span style={{color: "red"}}>ACTIVE DEVELOPMENT, PLEASE COME BACK LATER<br/></span> */}
                            <div>{labelSentenceBuilder(selectedLabelsByGroups, !isAtypical(atypical))}</div>
                            <div>
                                {freetextsInSelections.map((fts: FreetextInSelection) => fts.freetext).join('. ')}
                            </div>
                            {/* <div className="suggestions-grid">
                                {suggestions.map((suggestion: any) => (
                                    <Button
                                        key={suggestion.id}
                                        theme={isSuggestionChecked(suggestion, labelsInSelections) ? 'success' : 'secondary'}
                                        onClick={(e: any) =>
                                            toggleSuggestion(suggestion, labelGroups)
                                        }
                                    >
                                        {suggestion.name}
                                    </Button>
                                ))}
                            </div> */}
                        </div>
                        <div className="suggestions-container">
                            <Button
                                className={`btn-atypical ${!isAtypical(atypical) ? 'btn-secondary ' : 'btn-success '}`}
                                style={{ fontSize: '1.2rem' }}
                                onClick={toggleTypical}
                            >
                                {'atypical'}
                            </Button>
                            {/* Suggestions: <br /> <br /> */}
                            {/* <div className="suggestions-grid">
                                {suggestions.map((suggestion) => (
                                    <Button
                                        key={suggestion.id}
                                        theme={isSuggestionChecked(suggestion) ? 'success' : 'secondary'}
                                        onClick={(e) =>
                                            toggleSuggestion(suggestion, labelGroups, cellLabelSelections)
                                        }
                                    >
                                        {suggestion.name}
                                    </Button>
                                ))}
                            </div> */}
                        </div>
                    </div>
                )}
            </CardBody>
            <CardBody style={{ padding: '0 1rem' }}>
                <Accordion
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                    preExpanded={visibleLabelGroups.map((g) => g.id)}
                >
                    {user &&
                        cell &&
                        labels &&
                        !disabled &&
                        visibleLabelGroups.length &&
                        visibleLabelGroups.map((group: LabelGroup, i: number) => (
                            <AccordionItem key={group.id} uuid={group.id} dangerouslySetExpanded={true}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {group.name}
                                        <span className={'fa accordion-caret'}></span>
                                        <span style={{ marginLeft: '2em' }}>
                                            {buildSelectedLabelGroupLabels(
                                                group,
                                                labels,
                                                labelsInSelections,
                                                cellLabelSelections,
                                            )
                                                .map((l: Label) => l.name)
                                                .join(', ')}
                                        </span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {cellLabelSelections.length === labelGroups.length &&
                                        labelsByGroupsSortedBySortRules.has(group.id) &&
                                        // labels
                                        // .filter((label: Label) => labelControls.get(label.id)?.currentState === LabelState.ENABLED)
                                        // .filter((label: Label) => !disableRules.has(label.id))
                                        // .filter((label: Label) => label.labelGroup === group.id)
                                        // .sort((a: Label, b: Label) => a.name.localeCompare(b.name))
                                        labelsByGroupsSortedBySortRules
                                            .get(group.id)!
                                            .map((subGroup: InnerGroupOfLabels, idx) => (
                                                <span key={idx} className="labelgroup-subgroup">
                                                    {subGroup.map((label: Label, _) => (
                                                        <LabelInput
                                                            key={label.id}
                                                            user={user}
                                                            cell={cell}
                                                            group={group}
                                                            label={label}
                                                            cellLabelSelections={cellLabelSelections}
                                                            labelsInSelections={labelsInSelections}
                                                            toggleCheckbox={toggleCheckbox}
                                                            toggleDeselectableRadio={toggleDeselectableRadio}
                                                            disabled={
                                                                labelControls.get(label.id)?.currentState ===
                                                                LabelState.DISABLED
                                                            }
                                                        />
                                                    ))}
                                                </span>
                                            ))}
                                    {allowFreetext && DEFAULT_FREETEXT_GROUPS.has(group.id) && (
                                        <>
                                            {freetextsByLabelGroup.get(group.id)?.map((fts: FreetextInSelection) => (
                                                <Button key={fts.id} className="btn-freetext btn btn-success">
                                                    {fts.freetext}
                                                    <span
                                                        className="freetext-delete"
                                                        onClick={() => deleteFreetextInSelection(fts.id)}
                                                    >
                                                        <i className="ml-4 fas fa-times"></i>
                                                    </span>
                                                </Button>
                                            ))}
                                            <FormInput
                                                placeholder="Freetext"
                                                className="mb-2 freetext"
                                                // value={newFreetext}
                                                // onChange={(e: any) => setNewFreetext(e.target.value)}
                                                onKeyUp={(e: any) => addFreetext(e, group)}
                                            ></FormInput>
                                        </>
                                    )}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                </Accordion>
            </CardBody>
            <CardFooter></CardFooter>
        </Card>
    );
};
/* 
onClick={toggle.bind(this, e.id)}
open={collapsed[e.id]} */

// SingleCellDisplay.propTypes = {
//     user: PropTypes.object,
//     pixelDiameterInMicrometer: PropTypes.number,
//     showPrevious: PropTypes.bool,
//     showNext: PropTypes.bool,
//     onUpdateSelection: PropTypes.func,
//     onPrevious: PropTypes.func,
//     onNext: PropTypes.func,
//     onJumpTo: PropTypes.func,
//     suggestions: PropTypes.array,
//     annotationCountText: PropTypes.string,
//     title: PropTypes.string,
//     disabled: PropTypes.bool,
//     /**
//      * The component's title.
//      */
//     cell: PropTypes.object,
//     loaded: PropTypes.bool,
//     labelSet: PropTypes.object,
//     allowFreetext: PropTypes.bool,
//     hiddenLabelGroups: PropTypes.array,
// };

// SingleCellDisplay.defaultProps = {
//     title: 'Img',
//     loaded: false,
//     labelSet: {
//         id: 1,
//         name: 'aml/npm1/mds',
//     },
//     suggestions: [],
//     annotationCountText: 'You annotated a total of 0 cells!',
//     hiddenLabelGroups: [],
// };

export default SingleCellDisplay;
