/**
 * @module components/openlayers/src/ol/geom/Polygon
 */
// import GeometryLayout from './GeometryLayout.js';
// import LinearRing from './LinearRing.js';
// import Point from './Point.js';
import SimpleGeometry from 'components/openlayers/src/ol/geom/SimpleGeometry.js';
// import {arrayMaxSquaredDelta, assignClosestArrayPoint} from './flat/closest.js';
// import {closestSquaredDistanceXY, getCenter} from '../extent.js';
// import {deflateCoordinatesArray} from './flat/deflate.js';
// import {extend} from '../array.js';
// import {getInteriorPointOfArray} from './flat/interiorpoint.js';
// import {inflateCoordinatesArray} from './flat/inflate.js';
// import {intersectsLinearRingArray} from './flat/intersectsextent.js';
// import {linearRingsAreOriented, orientLinearRings} from './flat/orient.js';
// import {linearRings as linearRingsArea} from './flat/area.js';
// import {linearRingsContainsXY} from './flat/contains.js';
// import {modulo} from '../math.js';
// import {quantizeArray} from './flat/simplify.js';
// import {offset as sphereOffset} from '../sphere.js';

/**
 * @classdesc
 * Polygon geometry.
 *
 * @api
 */
class Paint extends SimpleGeometry {
    /**
     * @param {!Array<Array<import("../coordinate.js").Coordinate>>|!Array<number>} coordinates
     *     Array of linear rings that define the polygon. The first linear ring of the
     *     array defines the outer-boundary or surface of the polygon. Each subsequent
     *     linear ring defines a hole in the surface of the polygon. A linear ring is
     *     an array of vertices' coordinates where the first coordinate and the last are
     *     equivalent. (For internal use, flat coordinates in combination with
     *     `opt_layout` and `opt_ends` are also accepted.)
     * @param {import("./GeometryLayout.js").default} [opt_layout] Layout.
     * @param {Array<number>} [opt_ends] Ends (for internal use with flat coordinates).
     */
    constructor(coordinates, opt_layout, opt_ends) {
        super();

        /**
         * @type {Array<number>}
         * @private
         */
        this.ends_ = [];

        /**
         * @private
         * @type {number}
         */
        this.flatInteriorPointRevision_ = -1;

        /**
         * @private
         * @type {import("../coordinate.js").Coordinate}
         */
        this.flatInteriorPoint_ = null;

        /**
         * @private
         * @type {number}
         */
        this.maxDelta_ = -1;

        /**
         * @private
         * @type {number}
         */
        this.maxDeltaRevision_ = -1;

        /**
         * @private
         * @type {number}
         */
        this.orientedRevision_ = -1;

        /**
         * @private
         * @type {Array<number>}
         */
        this.orientedFlatCoordinates_ = null;

        // if (opt_layout !== undefined && opt_ends) {
        //   this.setFlatCoordinates(
        //     opt_layout,
        //     /** @type {Array<number>} */ (coordinates)
        //   );
        //   this.ends_ = opt_ends;
        // } else {
        //   this.setCoordinates(
        //     /** @type {Array<Array<import("../coordinate.js").Coordinate>>} */ (
        //       coordinates
        //     ),
        //     opt_layout
        //   );
        // }
    }

    /**
     * Append the passed linear ring to this polygon.
     * @param {LinearRing} linearRing Linear ring.
     * @api
     */
    // appendLinearRing(linearRing) {
    //   if (!this.flatCoordinates) {
    //     this.flatCoordinates = linearRing.getFlatCoordinates().slice();
    //   } else {
    //     extend(this.flatCoordinates, linearRing.getFlatCoordinates());
    //   }
    //   this.ends_.push(this.flatCoordinates.length);
    //   this.changed();
    // }

    /**
     * Make a complete copy of the geometry.
     * @return {!Polygon} Clone.
     * @api
     */
    clone() {
        return this;
        // const polygon = new Polygon(
        //   this.flatCoordinates.slice(),
        //   this.layout,
        //   this.ends_.slice()
        // );
        // polygon.applyProperties(this);
        // return polygon;
    }

    computeExtent(extend) {
        return [0, 0, 0, 0];
    }
    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @param {import("../coordinate.js").Coordinate} closestPoint Closest point.
     * @param {number} minSquaredDistance Minimum squared distance.
     * @return {number} Minimum squared distance.
     */
    closestPointXY(x, y, closestPoint, minSquaredDistance) {
        return 0;
        // if (minSquaredDistance < closestSquaredDistanceXY(this.getExtent(), x, y)) {
        //   return minSquaredDistance;
        // }
        // if (this.maxDeltaRevision_ != this.getRevision()) {
        //   this.maxDelta_ = Math.sqrt(
        //     arrayMaxSquaredDelta(
        //       this.flatCoordinates,
        //       0,
        //       this.ends_,
        //       this.stride,
        //       0
        //     )
        //   );
        //   this.maxDeltaRevision_ = this.getRevision();
        // }
        // return assignClosestArrayPoint(
        //   this.flatCoordinates,
        //   0,
        //   this.ends_,
        //   this.stride,
        //   this.maxDelta_,
        //   true,
        //   x,
        //   y,
        //   closestPoint,
        //   minSquaredDistance
        // );
    }

    /**
     * @param {number} x X.
     * @param {number} y Y.
     * @return {boolean} Contains (x, y).
     */
    containsXY(x, y) {
        return false;
        // linearRingsContainsXY(
        //   this.getOrientedFlatCoordinates(),
        //   0,
        //   this.ends_,
        //   this.stride,
        //   x,
        //   y
        // );
    }

    /**
     * Return the area of the polygon on projected plane.
     * @return {number} Area (on projected plane).
     * @api
     */
    getArea() {
        return 0;
        // linearRingsArea(
        //   this.getOrientedFlatCoordinates(),
        //   0,
        //   this.ends_,
        //   this.stride
        // );
    }

    /**
     * Get the coordinate array for this geometry.  This array has the structure
     * of a GeoJSON coordinate array for polygons.
     *
     * @param {boolean} [opt_right] Orient coordinates according to the right-hand
     *     rule (counter-clockwise for exterior and clockwise for interior rings).
     *     If `false`, coordinates will be oriented according to the left-hand rule
     *     (clockwise for exterior and counter-clockwise for interior rings).
     *     By default, coordinate orientation will depend on how the geometry was
     *     constructed.
     * @return {Array<Array<import("../coordinate.js").Coordinate>>} Coordinates.
     * @api
     */
    getCoordinates(opt_right) {
        return [[0, 0]];
        // let flatCoordinates;
        // if (opt_right !== undefined) {
        //   flatCoordinates = this.getOrientedFlatCoordinates().slice();
        //   orientLinearRings(flatCoordinates, 0, this.ends_, this.stride, opt_right);
        // } else {
        //   flatCoordinates = this.flatCoordinates;
        // }

        // return inflateCoordinatesArray(flatCoordinates, 0, this.ends_, this.stride);
    }

    /**
     * @return {Array<number>} Ends.
     */
    getEnds() {
        return this.ends_;
    }

    /**
     * @return {Array<number>} Interior point.
     */
    getFlatInteriorPoint() {
        return [0];
        // if (this.flatInteriorPointRevision_ != this.getRevision()) {
        //   const flatCenter = getCenter(this.getExtent());
        //   this.flatInteriorPoint_ = getInteriorPointOfArray(
        //     this.getOrientedFlatCoordinates(),
        //     0,
        //     this.ends_,
        //     this.stride,
        //     flatCenter,
        //     0
        //   );
        //   this.flatInteriorPointRevision_ = this.getRevision();
        // }
        // return this.flatInteriorPoint_;
    }

    /**
     * Return an interior point of the polygon.
     * @return {Point} Interior point as XYM coordinate, where M is the
     * length of the horizontal intersection that the point belongs to.
     * @api
     */
    // getInteriorPoint() {
    //   return new Point(this.getFlatInteriorPoint(), GeometryLayout.XYM);
    // }

    /**
     * Return the number of rings of the polygon,  this includes the exterior
     * ring and any interior rings.
     *
     * @return {number} Number of rings.
     * @api
     */
    // getLinearRingCount() {
    //   return this.ends_.length;
    // }

    /**
     * Return the Nth linear ring of the polygon geometry. Return `null` if the
     * given index is out of range.
     * The exterior linear ring is available at index `0` and the interior rings
     * at index `1` and beyond.
     *
     * @param {number} index Index.
     * @return {LinearRing|null} Linear ring.
     * @api
     */
    // getLinearRing(index) {
    //   if (index < 0 || this.ends_.length <= index) {
    //     return null;
    //   }
    //   return new LinearRing(
    //     this.flatCoordinates.slice(
    //       index === 0 ? 0 : this.ends_[index - 1],
    //       this.ends_[index]
    //     ),
    //     this.layout
    //   );
    // }

    /**
     * Return the linear rings of the polygon.
     * @return {Array<LinearRing>} Linear rings.
     * @api
     */
    // getLinearRings() {
    //   const layout = this.layout;
    //   const flatCoordinates = this.flatCoordinates;
    //   const ends = this.ends_;
    //   const linearRings = [];
    //   let offset = 0;
    //   for (let i = 0, ii = ends.length; i < ii; ++i) {
    //     const end = ends[i];
    //     const linearRing = new LinearRing(
    //       flatCoordinates.slice(offset, end),
    //       layout
    //     );
    //     linearRings.push(linearRing);
    //     offset = end;
    //   }
    //   return linearRings;
    // }

    /**
     * @return {Array<number>} Oriented flat coordinates.
     */
    // getOrientedFlatCoordinates() {
    //   if (this.orientedRevision_ != this.getRevision()) {
    //     const flatCoordinates = this.flatCoordinates;
    //     if (linearRingsAreOriented(flatCoordinates, 0, this.ends_, this.stride)) {
    //       this.orientedFlatCoordinates_ = flatCoordinates;
    //     } else {
    //       this.orientedFlatCoordinates_ = flatCoordinates.slice();
    //       this.orientedFlatCoordinates_.length = orientLinearRings(
    //         this.orientedFlatCoordinates_,
    //         0,
    //         this.ends_,
    //         this.stride
    //       );
    //     }
    //     this.orientedRevision_ = this.getRevision();
    //   }
    //   return this.orientedFlatCoordinates_;
    // }

    /**
     * @param {number} squaredTolerance Squared tolerance.
     * @return {Polygon} Simplified Polygon.
     * @protected
     */
    // getSimplifiedGeometryInternal(squaredTolerance) {
    //   const simplifiedFlatCoordinates = [];
    //   const simplifiedEnds = [];
    //   simplifiedFlatCoordinates.length = quantizeArray(
    //     this.flatCoordinates,
    //     0,
    //     this.ends_,
    //     this.stride,
    //     Math.sqrt(squaredTolerance),
    //     simplifiedFlatCoordinates,
    //     0,
    //     simplifiedEnds
    //   );
    //   return new Polygon(
    //     simplifiedFlatCoordinates,
    //     GeometryLayout.XY,
    //     simplifiedEnds
    //   );
    // }

    /**
     * Get the type of this geometry.
     * @return {import("./Geometry.js").Type} Geometry type.
     * @api
     */
    getType() {
        return 'SimpleGeometry';
    }

    /**
     * Test if the geometry and the passed extent intersect.
     * @param {import("../extent.js").Extent} extent Extent.
     * @return {boolean} `true` if the geometry and the extent intersect.
     * @api
     */
    // intersectsExtent(extent) {
    //   return intersectsLinearRingArray(
    //     this.getOrientedFlatCoordinates(),
    //     0,
    //     this.ends_,
    //     this.stride,
    //     extent
    //   );
    // }

    /**
     * Set the coordinates of the polygon.
     * @param {!Array<Array<import("../coordinate.js").Coordinate>>} coordinates Coordinates.
     * @param {import("./GeometryLayout.js").default} [opt_layout] Layout.
     * @api
     */
    setCoordinates(coordinates, opt_layout) {
        // this.setLayout(opt_layout, coordinates, 2);
        // if (!this.flatCoordinates) {
        //   this.flatCoordinates = [];
        // }
        // const ends = deflateCoordinatesArray(
        //   this.flatCoordinates,
        //   0,
        //   coordinates,
        //   this.stride,
        //   this.ends_
        // );
        // this.flatCoordinates.length = ends.length === 0 ? 0 : ends[ends.length - 1];
        // this.changed();
    }
}

export default Paint;
