export type UserId = number;
export type DatasetId = number;
export type CellId = number;
export type WsiId = number;
export type LabelId = number;
export type ExportGroupId = number;
export type LabelSetId = number;

export class User {
    public id: UserId = 0;
    public name = '';
    public expertise = 0;
    public forgetAfterSeconds = 0;
    public defaultConfidence = 0;
}

export class Dataset {
    public id: DatasetId = 0;
    public name = '';
}

export class Video {
    public id = 0;
    public name = '';
    public webUrl = '';
    public frameWebUrls = [];
}

export class LabelSelection {
    public id = 0;
    public annotator = new User();
    public labelGroup = new LabelGroup();
    public labels: Label[] = [];
    public dateModified = 0;
    public confidence = 3;
}

export class FlattenedLabelSelection {
    public id = 0;
    public annotatorId = 0;
    public labelGroupId = 0;
    public labels: Label[] = [];
    public dateModified = 0;
    public confidence = 3;
}

export class Cell {
    public id = 0;
    public dataset = 0;
    public wsi = 0;
    public segmentationSetId = 0;
    public ressourceUrl = '';
    public lastAnnotationDate = new Date();
    public firstAnnotationDate = new Date();
    public eccentricity = 0;
    public equivalentDiameter = 0;
    public perimeter = 0;
    public convexArea = 0;
    public meanIntensity = 0;
    // public labelSelections: LabelSelection[] = [];
}

export class MinifiedLabeledCell extends Cell {
    public labelSelections: FlattenedLabelSelection[] = [];
}

export class Wsi {
    public id = 0;
    public imgName = '';
    public pixelDiameterInMicrometer = 0;
    public datasetId = 0;
    public ressourceUrl = '';
    public webUrl = '';
}

export class ViaAnnotation {
    public wsi = 0;
    public annotator = 0;
    public annotatorName = '';
    public viaProject = '';
}

export class ExportGroup {
    public id = 0;
    public name = 'New ExportGroup';
}

export class LabelGroup {
    public id = 0;
    public multiSelect = false;
    public name = '';
    public sortOrder = 0;
}

export class LabelSet {
    public id = 0;
    public name = '';
    public labels: Label[] = [];
}

export class Label {
    public id = 0;
    public name = '';
    public labelGroup = 0;
}

export class Consensus {
    public cell = 0;
    public label = 0;
    public labelName = '';
}
