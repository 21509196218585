import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';

const LandingPageLayout = ({ children }) => (
    <Container fluid>
        <Row>
            <Col
                className="main-content p-0"
                lg={{ size: 10, offset: 5 }}
                md={{ size: 8, offset: 1 }}
                sm="12"
                tag="main"
            >
                {children}
            </Col>
        </Row>
    </Container>
);

LandingPageLayout.propTypes = {
    children: PropTypes.node,
};

LandingPageLayout.defaultProps = {};

export default LandingPageLayout;
