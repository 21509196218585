interface matchCb<T> {
    (obj: T, other: T): boolean;
}

export function filterDistinct<T>(iterable: Iterable<T>, matchCb?: matchCb<T>): Set<T> {
    if (typeof matchCb === 'undefined') {
        return new Set(iterable);
    }
    const distinct = [];
    // const map = new Map();
    for (const item of iterable) {
        // let id = idKeyChain.reduce((object: any, property) => object[property], item)
        // if (!map.has(id)) {
        //     map.set(id, true);
        if (distinct.findIndex((obj: T) => matchCb(obj, item)) === -1) {
            distinct.push(item);
        }
    }
    return new Set(distinct);
}

export function intersectUnique(a: number[], b: number[]) {
    let t;
    if (b.length > a.length) {
        t = b;
        b = a;
        a = t;
    }
    // indexOf to loop over shorter
    return unique(a.filter((e) => b.indexOf(e) > -1));
    // .filter((e, i, c) => c.indexOf(e) === i);
}

export function unique(a: number[]) {
    return a.filter((e, i, c) => c.indexOf(e) === i); // extra step to remove duplicates
}
