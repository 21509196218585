import React, { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import { Button, Card, CardHeader, CardBody, Col, Container, Row } from 'shards-react';
import styled from 'styled-components';
import PageTitle from '../components/common/PageTitle';
import { Cell, Dataset, User, Wsi } from './../models/BackendModels';
import DatasetSelect from '../components/wsi/DatasetSelect';
import config from './../config';
import { auth_fetch, checkSuccess } from '../utils/ajax';
import WsiSelect from '../components/wsi/WsiSelect';
import WsiDisplay from '../components/wsi/WsiDisplay';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
    ClassificationModelInference,
    LoadingIndicator,
    ModelInference,
    ObjectDetectionInference,
    retrieveClassificationModels,
    retrieveModels,
} from './SegmentationAnnotation';
import './AnalysisDemo01.css';
import { ChromaStatic } from 'chroma-js';
import distinctColors from 'distinct-colors';
import PageMap from 'react-pagemap';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { UserContext } from 'context/UserContext';

const PIXEL_DIAMETER_IN_MIKROMETER_DEFAULT = 0.0680272108843537;

const retrieveAllWsi = (): Promise<Wsi[]> => auth_fetch(`${config.backend}/api/wsi/`).then(checkSuccess);
const retrieveDatasetWsi = (): Promise<Wsi[]> =>
    auth_fetch(`${config.backend}/api/dataset_wsi/999/`).then(checkSuccess);

const retrieveCellsFromSlices = (wsi: Wsi, cellSlices: number[][]) =>
    auth_fetch(`${config.backend}/api/cells_from_slices/`, {
        method: 'POST',
        body: JSON.stringify({
            cellSlices: cellSlices,
            wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not create cell images: ${error}`));

const detectionInference = (wsi: Wsi, detectionModelName: string) =>
    auth_fetch(`${config.backend}/api/model_inference/`, {
        method: 'POST',
        body: JSON.stringify({
            modelName: detectionModelName,
            targetShape: 'rect', //TODO unused => remove
            wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not detect cells: ${error}`));

const classificationInference = (wsi: Wsi, cellSlices: number[][], classificationModelName: string) =>
    auth_fetch(`${config.backend}/api/classification_inference/`, {
        method: 'POST',
        body: JSON.stringify({
            modelName: classificationModelName,
            cellSlices: cellSlices,
            wsi: typeof wsi !== 'undefined' ? wsi.id : undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not do classification: ${error}`));

const saveCellSlices = (user: User, wsi: Wsi, cellSlices: CellSlice[]) =>
    auth_fetch(`${config.backend}/api/cell_slices/${wsi.id}/${user.id}/`, {
        method: 'PUT',
        body: JSON.stringify(cellSlices),
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .catch((error) => console.log(`Could not create cell images: ${error}`));

const loadCellSlices = (user: User, wsi: Wsi) =>
    auth_fetch(`${config.backend}/api/cell_slices/${wsi.id}/${user.id}/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
        .then(checkSuccess)
        .then((cellSlices) =>
            cellSlices.map((s: any) => {
                s.bbox = JSON.parse(s.bbox);
                return s;
            }),
        )
        .catch((error) => console.log(`Could not create cell images: ${error}`));

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
} as React.CSSProperties;

const Success = styled.span`
    color: green;
`;

const Failed = styled.span`
    color: red;
`;

const Pending = styled.span`
    color: yellow;
`;

const DropzoneArea = styled.p`
    padding: 3rem;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 8px;
`;

type PreviewFile = File & { preview: string };
type UploadResponseStatus = { success: boolean; message: string };

interface FullSizedImageSvgOverlayProps {
    src: string;
    containerRef: RefObject<HTMLDivElement>;
    onLoad?: () => void;
    cellSlices: CellSlice[];
    visibleIndices: Map<number, boolean>;
}

const FullSizedImageSvgOverlay: React.FC<FullSizedImageSvgOverlayProps> = ({
    src,
    containerRef,
    onLoad,
    cellSlices,
    visibleIndices,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const innerRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (typeof innerRef !== 'undefined' && innerRef.current !== null && loaded) {
            setHeight(innerRef.current.clientHeight);
            setWidth(innerRef.current.clientWidth);
        }
    }, [innerRef, loaded]);

    const ellipsesSlices = useMemo(
        () =>
            cellSlices.reduce((ellipses: Record<number, number[]>, slice) => {
                ellipses[slice.idx] = bbox2ellipse(slice.bbox);
                return ellipses;
            }, {}),
        [cellSlices],
    );

    return (
        <div className="full-size-img-container" ref={containerRef}>
            <img
                ref={innerRef}
                className="full-size-img"
                src={src}
                onLoad={() => {
                    setLoaded(true);
                    if (typeof onLoad !== 'undefined') {
                        onLoad();
                    }
                }}
            ></img>
            <svg width={width} height={height}>
                {cellSlices.map((slice) => {
                    const e = ellipsesSlices[slice.idx];
                    const cellColor = typeof slice.label !== 'undefined' ? labels[slice.label].color : '#000';
                    const strokeOpacity = visibleIndices.has(slice.idx) ? 1 : 0;
                    return (
                        <ellipse
                            key={slice.idx}
                            className="cell-slice"
                            strokeWidth={3}
                            cx={e[0]}
                            cy={e[1]}
                            rx={e[2]}
                            ry={e[3]}
                            stroke={cellColor}
                            strokeOpacity={strokeOpacity}
                            fillOpacity={0}
                            data-tip
                            data-for={slice.idx}
                        ></ellipse>
                    );
                })}
            </svg>
            <div className="cell-tooltips">
                {cellSlices.map((slice) => {
                    const e = ellipsesSlices[slice.idx];
                    return (
                        <ReactTooltip
                            key={slice.idx}
                            id={String(slice.idx)}
                            aria-haspopup="true"
                            disable={!visibleIndices.has(slice.idx)}
                        >
                            <div className="cell-tooltip__head">Cell {slice.idx + 1}</div>
                            <div className="cell-tooltip__data">
                                <span>Label:</span>
                                <span>
                                    {typeof slice.label !== 'undefined' ? labels[slice?.label].name : 'unlabeled'}
                                </span>
                                <span>Diameter A:</span>
                                <span>{(2 * e[2]).toFixed(2)}&#181;m</span>
                                <span>Diameter B:</span>
                                <span>{(2 * e[3]).toFixed(2)}&#181;m</span>
                                <span>Perimeter:</span>
                                <span>
                                    {(
                                        Math.PI *
                                        (3 * (e[2] + e[3]) - Math.sqrt((3 * e[2] + e[3]) * (e[2] + 3 * e[3])))
                                    ).toFixed(2)}
                                    &#181;m
                                </span>
                                <span>Area:</span>
                                <span>
                                    {(e[2] * e[3] * Math.PI * PIXEL_DIAMETER_IN_MIKROMETER_DEFAULT).toFixed(2)}&#181;m²
                                </span>
                            </div>
                        </ReactTooltip>
                    );
                })}
            </div>
        </div>
    );
};

export const bbox2rect = (bbox: number[]) => [bbox[0], bbox[1], bbox[2] - bbox[0], bbox[3] - bbox[1]];

export const bbox2ellipse = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const x_radius = (bbox[3] - bbox[1]) / 2;
    const y_radius = (bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, y_radius, x_radius];
};

export const bbox2maxcircle = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const radius = Math.max(bbox[3] - bbox[1], bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, radius];
};

export const bbox2mincircle = (bbox: number[]) => {
    const x_center = (bbox[1] + bbox[3]) / 2;
    const y_center = (bbox[0] + bbox[2]) / 2;
    const radius = Math.min(bbox[3] - bbox[1], bbox[2] - bbox[0]) / 2;
    return [y_center, x_center, radius];
};

export type CellSlice = {
    idx: number;
    bbox: number[];
    label?: number;
    imageName?: string;
};

// type CellSlices = {
//     slices: CellSlices[],
//     shape: string
// };
enum CellCorrectionArea {
    LEFT = 'cell-drop-area-left',
    RIGHT = 'cell-drop-area-right',
}

const labelNames = [
    'Band n. gran.',
    'Basophil',
    'Blast',
    'Eosinophil',
    'Erythroblast',
    'Immature lymph.',
    'Lymphocyte',
    'Metamyelocyte',
    'Monocyte',
    'Myelocyte',
    'Plasma cell',
    'Promyelocyte',
    'Segmented n. gran.',
];

const colors = distinctColors({ count: labelNames.length });
const labels = labelNames.map((l, idx) => {
    return { name: l, color: colors[idx].hex() };
});

const cellLabelDropdownOptions = labelNames.map((l, idx) => {
    return { label: l, value: String(idx) };
});

const emptyCellSlices = [[]];
for (let i = 0; i < labelNames.length; i++) {
    emptyCellSlices[i] = [];
}

enum DraggingState {
    NOT_STARTED,
    STARTED,
    ENTERED_TARGET, //, DROPPED
}

type CellLabelArea = {
    ref: React.RefObject<HTMLDivElement>;
    label: number;
};

type CellCorrectionDraggingState = {
    draggedCellIdx?: number;
    srcCellArea?: CellLabelArea;
    targetCellArea?: CellLabelArea;
    draggingState: DraggingState;
};

const DEFAULT_DRAGGING_STATE = { draggingState: DraggingState.NOT_STARTED };

const AnalysisDemo01: React.FC = () => {
    const { user } = useContext(UserContext);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [analyzed, setAnalyzed] = useState<boolean>(false);
    const [files, setFiles] = useState<Array<PreviewFile>>([]);
    const [currentWsi, setCurrentWsi] = useState<Wsi>();
    const [availableWsi, setAvailableWsi] = useState<Wsi[]>([]);
    const [cells, setCells] = useState<Cell[]>([]);
    const [cellImages, setCellImages] = useState<string[]>([]);
    const [displayShape, setDisplayShape] = useState<string>('ellipse');
    const [cellSlices, setCellSlices] = useState<CellSlice[]>([]);
    const [userCellSlices, setUserCellSlices] = useState<CellSlice[]>([]);
    const [availableClassificationInferenceOptions, setAvailableClassificationInferenceOptions] = useState([]);
    const [availableModelInferenceOptions, setAvailableModelInferenceOptions] = useState([]);
    const [uploadStatus, setUploadStatus] = useState<Record<string, UploadResponseStatus>>({});
    const [cellImagesToShow, setCellImagesToShow] = useState<number>(999);
    const [selectedCellLabel, setSelectedCellLabel] = useState<number>(-1);
    const [selectedCellLabelLeftSide, setSelectedCellLabelLeftSide] = useState<number>(-1);
    const [selectedCellLabelRightSide, setSelectedCellLabelRightSide] = useState<number>(-1);
    const [loadingIndicatorActive, setLoadingIndicatorActive] = useState<boolean>(false);
    const [slideImageLoaded, setSlideImageLoaded] = useState<boolean>(false);
    const [cellCorrectionDraggingState, setCellCorrectionDraggingState] =
        useState<CellCorrectionDraggingState>(DEFAULT_DRAGGING_STATE);
    const dropEnterExitCount = useRef<number>(0);
    const cellDropAreaLeft = useRef<HTMLDivElement>(null);
    const cellDropAreaRight = useRef<HTMLDivElement>(null);

    const cellLabelAreaLeft = useMemo(() => {
        return { ref: cellDropAreaLeft, label: selectedCellLabelLeftSide };
    }, [cellDropAreaLeft, selectedCellLabelLeftSide]);
    const cellLabelAreaRight = useMemo(() => {
        return { ref: cellDropAreaRight, label: selectedCellLabelRightSide };
    }, [cellDropAreaRight, selectedCellLabelRightSide]);

    const slideImageRef = useRef<HTMLImageElement>(null);
    const cellTableRef = useRef<HTMLDivElement>(null);

    const labelCounts = useMemo(
        () =>
            cellSlices.reduce((counts: Record<number, number>, slice) => {
                if (typeof slice.label !== 'undefined') {
                    if (typeof counts[slice.label] === 'undefined') {
                        counts[slice.label] = 0;
                    }
                    counts[slice.label]++;
                }
                return counts;
            }, {}),
        [cellSlices],
    );

    const cellSlicesIndicesOfSelectedLabel = useMemo(
        () =>
            cellSlices.reduce((indices: Map<number, boolean>, slice) => {
                if (selectedCellLabel === -1 || slice.label === selectedCellLabel) {
                    indices.set(slice.idx, true);
                }
                return indices;
            }, new Map<number, boolean>()),
        [cellSlices, selectedCellLabel],
    );

    // const cellSlicesOfSelectedLabel = useMemo(
    //     () => cellSlices.filter((slice, idx) => cellSlicesIndicesOfSelectedLabel.has(slice.idx)),
    //     [cellSlices, selectedCellLabel],
    // );

    const cellSlicesOfLeftSide = useMemo(
        () =>
            cellSlices.filter((slice) => selectedCellLabelLeftSide === -1 || slice.label === selectedCellLabelLeftSide),
        [cellSlices, selectedCellLabelLeftSide],
    );

    // Hide right side until cell type is selected
    const cellSlicesOfRightSide = useMemo(
        () => cellSlices.filter((slice) => slice.label === selectedCellLabelRightSide),
        [cellSlices, selectedCellLabelRightSide],
    );

    // const paginatedCellSlices = useMemo(
    //     () => cellSlicesOfSelectedLabel.slice(0, cellImagesToShow).slice(0, cellImagesToShow),
    //     [cellSlicesOfSelectedLabel],
    // );

    const DEMO_DATASET = 999;

    const onDrop = (acceptedFiles: Array<File>) => {
        setFiles(
            acceptedFiles.map((file: File) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        );

        const promises: Promise<Record<string, any>>[] = [];
        acceptedFiles.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', new Blob([file]));
            promises.push(
                auth_fetch(`${config.backend}/upload/demo/${DEMO_DATASET}/${file.name}`, {
                    method: 'PUT',
                    // headers: { 'Content-Type': 'application/json' },
                    body: formData,
                })
                    .then((response: Response) => response.json())
                    .then((json) => {
                        return { [file.name]: json };
                    }),
            );
        });
        Promise.all(promises).then((values) => {
            const uploadStatus: Record<string, UploadResponseStatus> = {};
            values.forEach((perFileJson) => {
                Object.keys(perFileJson).forEach((filename) => {
                    const json = perFileJson[filename];
                    if (typeof json.error !== 'undefined') {
                        uploadStatus[filename] = { success: false, message: json.error };
                    } else {
                        uploadStatus[filename] = { success: true, message: json.success };
                    }
                });
            });
            setUploadStatus(uploadStatus);
        });

        // POST to a test endpoint for demo purposes
        // const req = request.post('https://httpbin.org/post');
        // files.forEach(file => {
        //   req.attach(file.name, file);
        // });
        // req.end();
    };
    const uploadStatusMessage = (status?: UploadResponseStatus) => {
        if (typeof status !== 'undefined') {
            if (status.success) {
                return <Success>Success</Success>;
            } else if (typeof status.message !== 'undefined') {
                return <Failed>Failed ({status.message})</Failed>;
            }
        }
        return <Pending>Pending</Pending>;
    };

    const thumbs = files.map((file: PreviewFile) => (
        <li key={file.name}>
            Upload of {file.name}&nbsp;
            {uploadStatusMessage(uploadStatus[file.name])}
        </li>
    ));

    useEffect(() => {
        // setLoaded(false);
        retrieveAllWsi().then(async (wsi: Wsi[]) => {
            setAvailableWsi(wsi);
            if (wsi.length > 0) {
                setCurrentWsi(wsi[wsi.length - 1]);
            }
        });
    }, [uploadStatus]);

    useEffect(() => {
        if (typeof currentWsi !== 'undefined') {
            setLoaded(true);
            loadCellSlices(user, currentWsi).then(setUserCellSlices);
        }
    }, [currentWsi]);

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file: PreviewFile) => URL.revokeObjectURL(file.preview));
        },
        [files],
    );
    useEffect(() => {
        retrieveModels()
            .then(checkSuccess)
            .then((models) => {
                const available = models.map((element: string /*idx: number*/) => {
                    return { value: element, label: element };
                });
                setAvailableModelInferenceOptions(available);
            })
            .catch((/*error*/) => console.log('No existing models, yet'));
    }, []);

    useEffect(() => {
        retrieveClassificationModels()
            .then(checkSuccess)
            .then((models) => {
                const available = models.map((element: string /*idx: number*/) => {
                    return { value: element, label: element };
                });
                setAvailableClassificationInferenceOptions(available);
            })
            .catch((/*error*/) => console.log('No existing models, yet'));
    }, []);

    // useEffect(() => {
    //     if(cellSlices.length > 0) {
    //         addShapes(displayShape, cellSlices);
    //     }

    // }, [cellSlices, displayShape])

    // useEffect(() => {
    //     console.log(cellImages);
    // }, [cellImages]);

    const addCircles = (cellSlices: CellSlice[]) => {
        const svgContainer = document.querySelector('#wsi-svg-container');
    };
    const addRectangles = (cellSlices: CellSlice[]) => {
        const svgns = 'http://www.w3.org/2000/svg';
        // TODO pass svg container
        const svgContainer = document.querySelector('.full-size-img-container svg');
        // make a simple rectangle
        for (const slice of cellSlices) {
            const e = bbox2rect(slice.bbox);
            const newRect = document.createElementNS(svgns, 'rect');
            newRect.setAttribute('x', String(e[0]));
            newRect.setAttribute('y', String(e[1]));
            newRect.setAttribute('width', String(e[2]));
            newRect.setAttribute('height', String(e[3]));
            const cellColor = typeof slice.label !== 'undefined' ? labels[slice.label].color : '#000';
            newRect.setAttribute('stroke', cellColor);
            newRect.setAttribute('fill-opacity', '0');
            svgContainer?.appendChild(newRect);
        }
    };

    const addEllipses = (cellSlices: CellSlice[]) => {
        const svgns = 'http://www.w3.org/2000/svg';
        // TODO pass svg container
        const svgContainer = document.querySelector('.full-size-img-container svg');
        // make a simple rectangle
        for (const slice of cellSlices) {
            const e = bbox2ellipse(slice.bbox);
            const newRect = document.createElementNS(svgns, 'ellipse');
            newRect.setAttribute('cx', String(e[0]));
            newRect.setAttribute('cy', String(e[1]));
            newRect.setAttribute('rx', String(e[2]));
            newRect.setAttribute('ry', String(e[3]));
            const cellColor = typeof slice.label !== 'undefined' ? labels[slice.label].color : '#000';
            newRect.setAttribute('stroke', cellColor);
            newRect.setAttribute('fill-opacity', '0');
            svgContainer?.appendChild(newRect);
        }
    };

    // const handleSegmentationResult = (elements: Array<Array<number>>) => {
    //     // setDisplayShape(shape);
    //     setCellSlices(
    //         elements.map((bbox, idx) => {
    //             return { idx: idx, bbox };
    //         }),
    //     );
    // };

    const addShapes = (shape: string, cellSlices: CellSlice[]) => {
        // TODO Add SVG to JSX instead of manipulating DOM
        if (shape === 'min_circle' || shape === 'max_circle') {
            addCircles(cellSlices);
        } else if (shape === 'ellipse') {
            addEllipses(cellSlices);
        } else if (shape === 'rect') {
            addRectangles(cellSlices);
        }
    };

    // const classificationHandler = (labels: number[]) => {
    //     const labeledSlices = cellSlices.map((slice, idx) => {
    //         slice.label = labels[idx];
    //         return slice;
    //     });
    //     setCellSlices(labeledSlices);

    //     // console.log(data);
    // };

    // const handleCellImages = (wsi: Wsi, cellSlices: CellSlice[]) => {
    //     retrieveCellsFromSlices(wsi, cellSlices).then(
    //         (imageNames: string[]) => imageNames.map(n => `${config.backend}/static/wsi_backend/cache/cells/${n}`)
    //         ).then(setCellImages);
    // }

    const fullSlideAnalysis = async (wsi: Wsi) => {
        const detectionModelName = 'segmentation_model_2020_11_28.pth';
        const classificationModelName = 'cell_classification_model_2023_05_02.pth';
        setLoadingIndicatorActive(true);
        const cellBoundingBoxes = await trackPromise(detectionInference(wsi, detectionModelName));
        const cellImageUrls = await trackPromise(retrieveCellsFromSlices(wsi, cellBoundingBoxes));
        const labeledCellSlices = await trackPromise(
            classificationInference(wsi, cellBoundingBoxes, classificationModelName).then((labels: number[]) => {
                return cellBoundingBoxes.map((bbox: number[], idx: number) => {
                    const cellSlice: CellSlice = { idx: idx, bbox, label: labels[idx], imageName: cellImageUrls[idx] };
                    return cellSlice;
                });
            }),
        );
        saveCellSlices(user, wsi, labeledCellSlices);
        setCellSlices(labeledCellSlices);
        setLoadingIndicatorActive(false);
    };

    const loadSlideAnalysis = () => {
        if (typeof userCellSlices !== 'undefined' && userCellSlices.length > 0) {
            setCellSlices(userCellSlices);
        }
    };

    const switchWsi = (wsi: Wsi) => {
        setSlideImageLoaded(false);
        setCurrentWsi(wsi);
        setCellSlices([]);
        const svgContainer = document.querySelector('.full-size-img-container svg');
        if (svgContainer !== null) {
            svgContainer.innerHTML = '';
        }
    };

    const setSelectedCellLabels = (idx: number) => {
        const label = idx === selectedCellLabel ? -1 : idx;
        setSelectedCellLabel(label);
        if (selectedCellLabelLeftSide === -1) {
            setSelectedCellLabelLeftSide(label);
        }
    };

    const handleDragEnter = (e: any) => {
        dropEnterExitCount.current++;
        if (cellCorrectionDraggingState.targetCellArea?.ref.current === e.target) {
            setCellCorrectionDraggingState({
                ...cellCorrectionDraggingState,
                draggingState: DraggingState.ENTERED_TARGET,
            });
        }
    };

    const handleDragLeave = (e: any) => {
        dropEnterExitCount.current--;
        if (cellCorrectionDraggingState.targetCellArea?.ref.current === e.target && dropEnterExitCount.current === 0) {
            setCellCorrectionDraggingState({ ...cellCorrectionDraggingState, draggingState: DraggingState.STARTED });
        }
    };

    const getDropPosition = (e: any) => {
        // Works, but currently order of cell slices isn't implemented
        let dropPosition = -1;
        if (e.target.children.length === 0) {
            dropPosition = 0;
        }
        if (e.target.children.length > 0) {
            // let rows = [{fromY: 0, toY: Infinity}];
            let targetRow = { fromY: 0, toY: Infinity };
            let childrenInRow = [{ idx: 0, node: e.target.children[0] }];
            // let itemsPerRow = 1;
            // let position = -1;
            let rowFound = false;
            // Let's find the index position of the drop. e.target is cellDropArea
            let i = 1;
            while (!rowFound && i < e.target.children.length) {
                const childRect = e.target.children[i].getBoundingClientRect();
                const previousRect = e.target.children[i - 1].getBoundingClientRect();

                if (childRect.y > previousRect.y) {
                    // We are in a new row
                    // Set bottom of previous row and top of current row to the middle between both rows,
                    // because boundingClientRect does not account for margin
                    targetRow.toY = previousRect.bottom + (childRect.y - previousRect.bottom) / 2;
                    if (e.clientY > targetRow.fromY && e.clientY < targetRow.toY) {
                        rowFound = true;
                    } else {
                        // Clear children in row for children in new row
                        childrenInRow = [];
                        targetRow = { fromY: targetRow.toY, toY: Infinity };
                    }
                }
                if (!rowFound) {
                    childrenInRow.push({ idx: i, node: e.target.children[i] });
                }
                i++;
                // if(rows.length === 1) {
                //     itemsPerRow++;
                // }
                // previousRect = childRect;
            }
            for (const child of childrenInRow) {
                const childRect = child.node.getBoundingClientRect();
                if (e.clientX < childRect.x) {
                    dropPosition = child.idx;
                    break;
                }
            }
            if (dropPosition === -1) {
                // If image wasn't dropped before any element in the row, it's the last element in this row
                dropPosition = childrenInRow[childrenInRow.length - 1].idx + 1;
            }
        }
        console.log(`Dropped at index ${dropPosition}`);
        return dropPosition;
    };

    const handleDrop = (e: any) => {
        if (e.target === cellCorrectionDraggingState.targetCellArea?.ref.current && typeof currentWsi !== 'undefined') {
            // let dropPosition = getDropPosition(e);
            const updatedSlices = cellSlices.map((c) => {
                if (c.idx === cellCorrectionDraggingState.draggedCellIdx) {
                    c.label = cellCorrectionDraggingState.targetCellArea?.label;
                }
                return c;
            });
            saveCellSlices(user, currentWsi, updatedSlices);
            setCellSlices(updatedSlices);
        }
        setCellCorrectionDraggingState(DEFAULT_DRAGGING_STATE);
    };

    const handleDragStartFromLeft = (e: any, draggedCellIdx: number) => {
        setCellCorrectionDraggingState({
            srcCellArea: cellLabelAreaLeft,
            targetCellArea: cellLabelAreaRight,
            draggedCellIdx: draggedCellIdx,
            draggingState: DraggingState.STARTED,
        });
    };

    const handleDragStartFromRight = (e: any, draggedCellIdx: number) => {
        setCellCorrectionDraggingState({
            srcCellArea: cellLabelAreaRight,
            targetCellArea: cellLabelAreaLeft,
            draggedCellIdx: draggedCellIdx,
            draggingState: DraggingState.STARTED,
        });
    };

    const handleDragEnd = (e: any) => {
        setCellCorrectionDraggingState({ draggingState: DraggingState.NOT_STARTED });
    };
    // const dropdownChangeLeftSide = (idx: number) => {
    //     const cellLabelLeftSide = idx;
    //     setSelectedCellLabelLeftSide(cellLabelLeftSide);
    //     if(cellSlicesOfLeftSide.length === 0) {
    //         let t = cellSlicesOfLeftSide;
    //         t[idx] =
    //         setCellSlicesOfLeftSide(cellSlices.filter((slice) => cellLabelLeftSide === -1 || slice.label === cellLabelLeftSide))
    //     }
    // }

    // const dropdownChangeRightSide = (idx: number) => {
    //     const cellLabelRightSide = idx;
    //     setSelectedCellLabelRightSide(cellLabelRightSide);
    //     if(cellSlicesOfRightSide.length === 0) {
    //         setCellSlicesOfRightSide(cellSlices.filter((slice) => cellLabelRightSide === -1 || slice.label === cellLabelRightSide))
    //     }
    // }

    // const cellSlicesOfLeftSide = useMemo(
    //     () => cellSlices.filter((slice) => selectedCellLabelLeftSide === -1 || slice.label === selectedCellLabelLeftSide),
    //     [cellSlices, selectedCellLabelLeftSide],
    // );

    // const cellSlicesOfRightSide = useMemo(
    //     () => cellSlices.filter((slice) => selectedCellLabelRightSide === -1 || slice.label === selectedCellLabelRightSide),
    //     [cellSlices, selectedCellLabelRightSide],
    // );
    const cellTableWidth = cellTableRef.current?.clientWidth;
    const getCellDropAreaBackgroundColor = (self: React.RefObject<HTMLDivElement>) => {
        if (
            cellCorrectionDraggingState.draggingState === DraggingState.NOT_STARTED ||
            // cellCorrectionDraggingState.draggingState === DraggingState.DROPPED ||
            self !== cellCorrectionDraggingState.targetCellArea?.ref
        ) {
            return 'white';
        } else if (cellCorrectionDraggingState.draggingState === DraggingState.STARTED) {
            return 'yellow';
        } else if (cellCorrectionDraggingState.draggingState === DraggingState.ENTERED_TARGET) {
            return 'green';
        }
        throw new Error('Invalid cellCorrectionDraggingState');
    };
    return (
        <Container fluid className="main-content-container px-4 demo-analysis">
            <Row noGutters className="page-header py-2">
                {/* <PageTitle title="Slide upload" subtitle="Slide images" md="12" className="ml-sm-auto mr-sm-auto" /> */}
            </Row>
            <Row>
                <Col lg="12" md="12" className="mb-4">
                    <Card>
                        <CardHeader style={{ borderBottom: '1px solid #ddd' }}>Slide upload</CardHeader>
                        <CardBody>
                            <ReactDropzone onDrop={onDrop}>
                                {({ getRootProps, getInputProps }: any) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <DropzoneArea>
                                                Drag and drop some files here, or click to select files
                                            </DropzoneArea>
                                        </div>
                                    </section>
                                )}
                            </ReactDropzone>
                            <aside style={thumbsContainer}>
                                <ul>{thumbs}</ul>
                            </aside>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="4" xl="3">
                    <Card style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                        <CardHeader style={{ borderBottom: '1px solid #ddd', width: '100%' }}>
                            {typeof currentWsi !== 'undefined' && currentWsi.imgName}
                        </CardHeader>
                        <CardBody>
                            {typeof currentWsi !== 'undefined' &&
                                slideImageLoaded &&
                                typeof cellTableWidth !== 'undefined' && (
                                    <PageMap
                                        viewport={slideImageRef.current}
                                        styles={{
                                            container: {
                                                position: 'relative',
                                                // top: '8px',
                                                // right: '8px',
                                                width: `${cellTableWidth}px`,
                                                height: `${cellTableWidth * 4}px`, // set height >>> width,
                                                // because internally pagemap resizes canvas based on minimum dimension
                                                // to keep aspect ratio
                                                backgroundImage: `url("${currentWsi.webUrl}")`,
                                                backgroundSize: 'contain',
                                                // zIndex: '100'
                                            },
                                        }}
                                        view="rgba(255,0,0,0.4)"
                                        viewStroke="rgba(255,0,0,0.6)"
                                        dragStroke="rgba(255,0,0,0.9)"
                                    />
                                )}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader style={{ borderBottom: '1px solid #ddd' }}>
                            {typeof currentWsi !== 'undefined' && (
                                <div ref={cellTableRef} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: '700' }}>Zelltyp</div>
                                    <div style={{ fontWeight: '700' }}>Anzahl</div>
                                </div>
                            )}
                        </CardHeader>
                        <CardBody>
                            {typeof currentWsi !== 'undefined' && (
                                <div style={{ height: '85vh', display: 'inline-block', width: '100%' }}>
                                    <div style={{ display: 'flex', flexFlow: 'column' }}>
                                        {labels.length > 0 &&
                                            labels.map((l, idx) => (
                                                <div
                                                    key={idx}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        cursor: 'pointer',
                                                        border: selectedCellLabel === idx ? '1px solid #000' : 'none',
                                                    }}
                                                    onClick={() => setSelectedCellLabels(idx)}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: l.color,
                                                                width: '1.2rem',
                                                                height: '0.5rem',
                                                                borderRadius: '2px',
                                                                marginRight: '0.5rem',
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </div>
                                                        <div>{l.name}</div>
                                                    </div>
                                                    <div>
                                                        {typeof labelCounts[idx] !== 'undefined' ? labelCounts[idx] : 0}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="8" md="8" xl="9" className="mb-4">
                    <Card>
                        <CardHeader style={{ borderBottom: '1px solid #ddd' }}>Slide view</CardHeader>
                        <CardBody>
                            <WsiSelect
                                availableWsi={availableWsi}
                                selectedWsi={currentWsi}
                                onChange={switchWsi}
                                useNameAsLabel={true}
                            />
                            {typeof currentWsi !== 'undefined' && (
                                <>
                                    <div style={{ display: 'inline-block', width: '100%' }}>
                                        <FullSizedImageSvgOverlay
                                            containerRef={slideImageRef}
                                            cellSlices={cellSlices}
                                            visibleIndices={cellSlicesIndicesOfSelectedLabel}
                                            src={currentWsi.webUrl}
                                            onLoad={() => setSlideImageLoaded(true)}
                                        />
                                    </div>
                                </>
                            )}
                        </CardBody>
                        {/* <div style={{justifyContent: "center", display: "flex"}}>
                                        <ObjectDetectionInference
                                            availableModelOptions={availableModelInferenceOptions}
                                            wsi={currentWsi}
                                            handler={handleSegmentationResult}
                                            ></ObjectDetectionInference>
                                    </div>
                                    {cellSlices.length > 0 && (
                                    <div style={{justifyContent: "center", display: "flex"}}>
                                            <ClassificationModelInference
                                                availableModelOptions={availableClassificationInferenceOptions}
                                                wsi={currentWsi}
                                                cellSlices={cellSlices}
                                                handler={classificationHandler}
                                                ></ClassificationModelInference>
                                    </div>
                                    )} */}
                    </Card>
                    {typeof currentWsi !== 'undefined' && cellSlicesIndicesOfSelectedLabel.size === 0 && (
                        <Row
                            style={{
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '2rem',
                            }}
                        >
                            <Button
                                style={{ fontSize: '1.25rem', width: '15%' }}
                                theme="primary"
                                onClick={() => fullSlideAnalysis(currentWsi)}
                            >
                                Start analysis
                            </Button>
                            <Button
                                style={{ fontSize: '1.25rem', width: '15%' }}
                                theme="primary"
                                disabled={!(typeof userCellSlices !== 'undefined' && userCellSlices.length > 0)}
                                onClick={loadSlideAnalysis}
                            >
                                Load previous analysis
                            </Button>
                            <LoadingIndicator active={loadingIndicatorActive} />
                        </Row>
                    )}
                    {typeof currentWsi !== 'undefined' && cellSlicesIndicesOfSelectedLabel.size > 0 && (
                        <Row>
                            <Card style={{ marginTop: '2rem' }}>
                                <CardHeader>
                                    {selectedCellLabel !== -1
                                        ? labels[selectedCellLabel].name + ' cells'
                                        : 'Cell analysis'}
                                </CardHeader>
                                <CardBody style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '40%' }}>
                                        <Dropdown
                                            options={cellLabelDropdownOptions.filter(
                                                (o) => parseInt(o.value) !== selectedCellLabelRightSide,
                                            )}
                                            onChange={(o) => setSelectedCellLabelLeftSide(parseInt(o.value))}
                                            placeholder="Showing all cells - Select a cell type"
                                            value={cellLabelDropdownOptions[selectedCellLabelLeftSide]}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between',
                                                minHeight: '10vh',
                                                backgroundColor: getCellDropAreaBackgroundColor(cellDropAreaLeft),
                                            }}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}
                                            className="cell-drop-area cell-drop-area-left"
                                            ref={cellDropAreaLeft}
                                        >
                                            {cellSlicesOfLeftSide.map((slice) => (
                                                <img
                                                    draggable="true"
                                                    onDragStart={(e) => handleDragStartFromLeft(e, slice.idx)}
                                                    onDragEnd={handleDragEnd}
                                                    style={{
                                                        width: '15%',
                                                        margin: '1rem',
                                                        opacity:
                                                            slice.idx === cellCorrectionDraggingState.draggedCellIdx
                                                                ? 0
                                                                : 1,
                                                    }}
                                                    src={`${config.backend}/static/wsi_backend/cache/cells/${slice.imageName}`}
                                                    key={slice.idx}
                                                ></img>
                                            ))}
                                        </div>
                                    </div>
                                    <div style={{ width: '40%' }}>
                                        <Dropdown
                                            disabled={selectedCellLabelLeftSide === -1}
                                            options={cellLabelDropdownOptions.filter(
                                                (o) => parseInt(o.value) !== selectedCellLabelLeftSide,
                                            )}
                                            onChange={(o) => setSelectedCellLabelRightSide(parseInt(o.value))}
                                            placeholder="Select left size first then select cell type here"
                                            value={cellLabelDropdownOptions[selectedCellLabelRightSide]}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between',
                                                minHeight: '10vh',
                                                backgroundColor: getCellDropAreaBackgroundColor(cellDropAreaRight),
                                            }}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}
                                            className="cell-drop-area cell-drop-area-right"
                                            ref={cellDropAreaRight}
                                        >
                                            {cellSlicesOfRightSide.map((slice) => (
                                                <img
                                                    draggable="true"
                                                    onDragStart={(e) => handleDragStartFromRight(e, slice.idx)}
                                                    onDragEnd={handleDragEnd}
                                                    style={{
                                                        width: '15%',
                                                        margin: '1rem',
                                                        opacity:
                                                            slice.idx === cellCorrectionDraggingState.draggedCellIdx
                                                                ? 0
                                                                : 1,
                                                    }}
                                                    src={`${config.backend}/static/wsi_backend/cache/cells/${slice.imageName}`}
                                                    key={slice.idx}
                                                ></img>
                                            ))}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Col
                                lg="12"
                                md="12"
                                xl="12"
                                style={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '2rem',
                                }}
                            >
                                <Button
                                    style={{ fontSize: '1.25rem', width: '15%' }}
                                    theme="primary"
                                    onClick={() => fullSlideAnalysis(currentWsi)}
                                >
                                    Reset analysis
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default AnalysisDemo01;
